import axios from "axios";

/**
 *
 * @name getFees
 * @description Gets the fees
 * @returns {object} The fees
 *
 */
export const getFees = async () => {
	try {
		const response = await axios.get("/fees");
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
