import { Box, Button, Flex, HStack, Heading, Image, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import SignupModal from "../Modals/SignUp/SignUp";
import { useSelector } from "react-redux";

export const GalleySliderImage = ({ type, isHidden = false }) => {
	const { user } = useSelector((state) => state.auth);
	const isConnected = user !== undefined && user !== null;
	const [isLargerThanSm] = useMediaQuery("(max-width: 480px)");

	isLargerThanSm && (type = "mv" + type);
	let slides = [];
	if (type !== "dashboard") {
		slides = [
			{
				img: `/images/gallerySlider/${type}_slide1.png`,
			},
			{
				img: `/images/gallerySlider/${type}_slide2.png`,
			},
			{
				img: `/images/gallerySlider/${type}_slide3.png`,
			},
		];
	} else {
		slides = [
			{
				img: `/images/gallerySlider/${type}_slide1.png`,
			},
		];
	}

	if (isLargerThanSm) {
	}

	const [currentSlide, setCurrentSlide] = useState(0);
	const slidesCount = slides?.length;
	const carouselStyle = {
		transition: "all .5s",
		ml: `-${currentSlide * 100}%`,
	};
	const SLIDES_INTERVAL_TIME = 10000;
	const ANIMATION_DIRECTION = "right";

	const { isOpen: isOpenSignup, onOpen: onOpenSignup, onClose: onCloseSignup } = useDisclosure();

	useEffect(() => {
		const prevSlide = () => {
			setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
		};

		const nextSlide = () => {
			setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
		};

		const automatedSlide = setInterval(() => {
			ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
		}, SLIDES_INTERVAL_TIME);
		return () => clearInterval(automatedSlide);
	}, [slidesCount]);

	const setSlide = (slide) => {
		setCurrentSlide(slide);
	};

	return (
		<>
			<Flex w="full" m={"auto"} bg="transparent" alignItems="center" justifyContent="center">
				<Flex w="100%" overflow="hidden" pos="relative" borderRadius={"10px"}>
					<Flex h="400px" w="full" {...carouselStyle}>
						{slides.map((slide, index) => (
							<Fragment key={`slide-${index}`}>
								{type === "dashboard" && (
									<Box
										display={"flex"}
										flexDirection={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										pos={"absolute"}
										top={"150px"}
										left={"20%"}
									>
										<Heading color={"white"}>GAME...SET...BET!</Heading>
										<Text mb={5} fontSize={"xl"}>
											Enter the social gaming community!
										</Text>
										{!isConnected && (
											<Button
												color={"secundary"}
												_hover={{ transform: "scale(1.2)" }}
												onClick={onOpenSignup}
											>
												REGISTER NOW!
											</Button>
										)}
									</Box>
								)}
								<Box key={`slide-${slide}`} boxSize="full" shadow="md" flex="none">
									<Image src={slide.img} alt="carousel image" boxSize="full" backgroundSize="cover" />
								</Box>
							</Fragment>
						))}
					</Flex>
				</Flex>
			</Flex>
			{!isHidden ? (
				<Flex>
					<HStack justify="center" w="full" mb={10}>
						{Array.from({
							length: slidesCount,
						}).map((_, slide) => (
							<Box
								key={`dots-${slide}`}
								cursor="pointer"
								boxSize={["7px", null, "15px"]}
								m="10px 2px 0 2px"
								bg={currentSlide === slide ? "#FF7A00" : "#878787"}
								rounded="25%"
								display="inline-block"
								transition="background-color 0.6s ease"
								_hover={{
									bg: "#FF7A00",
								}}
								onClick={() => setSlide(slide)}
							></Box>
						))}
					</HStack>
				</Flex>
			) : null}
			<SignupModal isOpen={isOpenSignup} onClose={onCloseSignup} />
		</>
	);
};
