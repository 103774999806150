import { Select, SimpleGrid } from "@chakra-ui/react";
import CurrencyOption from "./CurrencyOption";

/**
 *
 * @name CurrencyPicker
 * @description The currency picker component
 * @param {Object} props The props for the component
 * @param {String} props.selectedCurrency The selected currency string
 * @param {Function} props.setSelectedCurrency The function to set the selected currency
 * @param {Object} props.balances The balances object
 * @returns {JSX.Element} The JSX Code for the CurrencyPicker component
 * @version 1.0.0
 *
 */
const CurrencyPicker = ({ selectedCurrency, setSelectedCurrency, balances = [] }) => {
	return selectedCurrency ? (
		<CurrencyWithSelect
			selectedCurrency={selectedCurrency}
			setSelectedCurrency={setSelectedCurrency}
			balances={balances}
		/>
	) : (
		<CurrencyWithBox setSelectedCurrency={setSelectedCurrency} balances={balances} />
	);
};

/**
 *
 * @name CurrencyWithBox
 * @description The currency with box component
 * @param {Object} props The props for the component
 * @param {Function} props.setSelectedCurrency The function to set the selected currency
 * @param {Object} props.balances The balances object
 * @returns {JSX.Element} The JSX Code for the CurrencyWithBox component
 * @version 1.0.0
 *
 */
const CurrencyWithBox = ({ setSelectedCurrency, balances }) => {
	return (
		<SimpleGrid columns={2} spacing={8} my={4}>
			<CurrencyOption
				currencyValue="btc"
				currencyName="Bitcoin"
				currencyImage="/images/currencies/btc.png"
				setSelectedCurrency={setSelectedCurrency}
			/>

			<CurrencyOption
				currencyValue="eth"
				currencyName="Ethereum"
				currencyImage="/images/currencies/eth.png"
				setSelectedCurrency={setSelectedCurrency}
			/>

			<CurrencyOption
				currencyValue="bnb"
				currencyName="BNB"
				currencyImage="/images/currencies/bnb.png"
				setSelectedCurrency={setSelectedCurrency}
			/>

			<CurrencyOption
				currencyValue="usdt"
				currencyName="USDT BEP20 (BSC)"
				currencyImage="/images/currencies/usdt.png"
				setSelectedCurrency={setSelectedCurrency}
			/>
		</SimpleGrid>
	);
};

/**
 *
 * @name CurrencyWithSelect
 * @description The currency with select component
 * @param {Object} props The props for the component
 * @param {String} props.selectedCurrency The selected currency string
 * @param {Function} props.setSelectedCurrency The function to set the selected currency
 * @param {Object} props.balances The balances object
 * @returns {JSX.Element} The JSX Code for the CurrencyWithSelect component
 * @version 1.0.0
 *
 */
const CurrencyWithSelect = ({ selectedCurrency, setSelectedCurrency, balances }) => {
	return (
		<Select
			variant={"bitbet"}
			value={selectedCurrency}
			onChange={(e) => setSelectedCurrency(e.target.value)}
			my={4}
		>
			<option value="" style={{ background: "#413D53", color: "white", fontFamily: "Poppins, sans-serif" }}>
				Select currency
			</option>

			<CurrencyOption currencyValue="btc" currencyName="Bitcoin" currencyImage="/images/currencies/btc.png" />
			<CurrencyOption currencyValue="eth" currencyName="Ethereum" currencyImage="/images/currencies/eth.png" />
			<CurrencyOption currencyValue="bnb" currencyName="BNB" currencyImage="/images/currencies/bnb.png" />
			<CurrencyOption
				currencyValue="usdt"
				currencyName="USDT BEP20 (BSC)"
				currencyImage="/images/currencies/usdt.png"
			/>
		</Select>
	);
};

export default CurrencyPicker;
