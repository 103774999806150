import { useToast } from "@chakra-ui/react";
import { GameCardCasino } from "../../components/CardGames/GameCardCasino";
import { useEffect, useState } from "react";
import { errorToast } from "../../utils/toast";
import { GalleySliderImage } from "../../components/Dashboard/GalleySliderImage";
import { getGameMocks } from "../../services/blueOceanGame/getGamesMocks";
import { FiltersOptions } from "../../components/Dashboard/FiltersOptions";

export const CasinoGames = ({ filter, setFilter }) => {
	const toast = useToast();
	const [gameList, setGameList] = useState([]);

	useEffect(() => {
		getGameMocks()
			.then((response) => {
				setGameList(response);
			})
			.catch((error) => {
				errorToast(toast, "Connection error", error.message);
			});
	}, [toast]);

	return (
		<>
			<GalleySliderImage type={"casino"} />
			<FiltersOptions setFilter={setFilter} />
			<GameCardCasino gameList={gameList} filter={filter} />
		</>
	);
};
