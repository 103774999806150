import { Box, Button, Divider, HStack, Heading, Select, Stack } from "@chakra-ui/react";

export const HeaderAllPage = ({ title, onOpen }) => {
	return (
		<Stack spacing={5} mb={5}>
			<HStack justify={"space-between"} alignItems={"end"}>
				<Box>
					<Heading color={"white"}>{title}</Heading>
				</Box>
				<HStack>
					{title === "Challenges" && (
						<>
							<Select w={"135px"} h={"48px"} backgroundColor={"white"} color="black">
								<option value="">Order by:</option>
								<option value="name">Name</option>
								<option value="date">Date</option>
								<option value="experience">Experience</option>
								<option value="status">Status</option>
							</Select>

							<Button
								size={"lg"}
								color={"white"}
								bgGradient={"linear(to-br, #FF7A00, #FF5B27)"}
								_hover={{ bgGradient: "linear(to-br, #CC6709, #CC6709)" }}
								onClick={onOpen}
							>
								+ Add
							</Button>
						</>
					)}
				</HStack>
			</HStack>
			<Divider />
		</Stack>
	);
};
