export const videoGame = [
	{
		gameId: 1,
		name: "Chess",
		image: "/images/games/videoGames/chess.png",
	},
	{
		gameId: 2,
		name: "Apex Legends",
		image: "/images/games/videoGames/apexLegends.png",
	},
	{
		gameId: 3,
		name: "Counter Strike",
		image: "/images/games/videoGames/counter.png",
	},
	{
		gameId: 4,
		name: "Diablo 4",
		image: "/images/games/videoGames/diablo4.png",
	},
	{
		gameId: 5,
		name: "Fifa 2024",
		image: "/images/games/videoGames/fifa.png",
	},
	{
		gameId: 6,
		name: "Fornite",
		image: "/images/games/videoGames/fornite.png",
	},
	{
		gameId: 7,
		name: "Heartstone",
		image: "/images/games/videoGames/hearthstone.png",
	},
	{
		gameId: 8,
		name: "League of Legends",
		image: "/images/games/videoGames/lol.png",
	},
];

export const sportGame = [
	{
		gameId: 1,
		name: "Tennis",
		image: "/images/games/sportGames/tennis.png",
		url: "/sport-games?bt-path=%2F%3FliveSport%3Dtennis-5",
	},
	{
		gameId: 2,
		name: "Soccer",
		image: "/images/games/sportGames/soccer.png",
		url: "/sport-games?bt-path=%2Fsoccer-1",
	},
	{
		gameId: 3,
		name: "Basketball",
		image: "/images/games/sportGames/basket.png",
		url: "/sport-games?bt-path=%2Fbasketball-2",
	},
	{
		gameId: 4,
		name: "Hockey",
		image: "/images/games/sportGames/hockey.png",
		url: "/sport-games?bt-path=%2Fice-hockey-4",
	},
	{
		gameId: 5,
		name: "Volleyball",
		image: "/images/games/sportGames/volley.png",
		url: "/sport-games?bt-path=%2Fvolleyball-23",
	},
	{
		gameId: 6,
		name: "Golf",
		image: "/images/games/sportGames/golf.png",
		url: "/sport-games?bt-path=%2Fgolf-9",
	},
	{
		gameId: 7,
		name: "Baseball",
		image: "/images/games/sportGames/baseball.png",
		url: "/sport-games?bt-path=%2Fbaseball-3",
	},
	{
		gameId: 8,
		name: "Football",
		image: "/images/games/sportGames/football.png",
		url: "/sport-games?bt-path=%2Famerican-football-16",
	},
];
