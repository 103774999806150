import axios from "axios";

/**
 *
 * @name getUrlOneGame
 * @description Requests the url of the different sets depending on the data received by params.
 * @returns Url game.
 *
 */
export const getUrlOneGame = async (gameId, type, user, demoActive) => {

	try {
		const response = await axios.post(demoActive === "1" ? `/game/${type}/${gameId}/1` : `/game/${type}/${gameId}/0`, user);
		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};