import { Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

/**
 *
 * @name OptionSidebarExtend
 * @description The option sidebar extend component
 * @param {Object} props The props for the Sidebar component
 * @param {Function} props.onClose The onClose function
 * @param {Boolean} props.isConnected The isConnected boolean
 * @returns {JSX.Element} The JSX Code for the option sidebar extend component
 * @version 1.0.0
 *
 */
export const OptionSidebarExtend = ({
	title,
	icono,
	onOpen,
	url,
	setUrlSportbook,
	setUrlUserXp,
	isDisabled = false,
	setFilter = null,
}) => {
	const navigate = useNavigate();

	const onClickOptions = () => {
		if (url) {
			if (url === "/sport-games") {
				setUrlSportbook(true);
			} else if (url === "/earn-xp") {
				setUrlUserXp(true);
			} else {
				setUrlSportbook(false);
				setUrlUserXp(false);
				if (url === "/casino-games") {
					setFilter("all");
				}
			}
			navigate(url);
		} else {
			onOpen();
		}
	};

	return (
		<Stack
			display={"flex"}
			flexDirection={"row"}
			alignItems={"center"}
			cursor={!isDisabled ? "pointer" : "not-allowed"}
			transition={"0.5s"}
			_hover={{
				color: "secundary",
			}}
			onClick={isDisabled ? null : onClickOptions}
		>
			{icono}
			<Text>{title}</Text>
		</Stack>
	);
};
