import { Avatar, Box, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";

export const CardUsersAdminPanel = ({ user }) => {
	const { username, public_name, email, verified, usd_balance } = user;
	return (
		<HStack
			bgColor={"darkGray"}
			borderRadius={5}
			w={"full"}
			p={5}
			gap={5}
			justify={"space-between"}
			alignItems={"center"}
		>
			<HStack alignItems={"center"} gap={5} w={"20%"}>
				<Avatar name="BitBet" src="/images/profile_image.png" size={"lg"} />
				<Heading color={"white"} fontSize={"xl"} fontWeight={500} fontFamily={"body"}>
					{username}
				</Heading>
			</HStack>
			<HStack gap={5} w={"55%"} justify={"space-between"}>
				<VStack gap={0}>
					<Text fontWeight={"bold"} color={"secundary"}>
						Public name
					</Text>
					<Text>{public_name}</Text>
				</VStack>
				<VStack gap={0}>
					<Text fontWeight={"bold"} color={"secundary"}>
						Email
					</Text>
					<Text>{email}</Text>
				</VStack>

				<VStack>
					<Text fontWeight={"bold"} color={"secundary"}>
						Status
					</Text>
					<Box p={2} rounded={"full"} bgColor={verified ? "green" : "red"}></Box>
				</VStack>
			</HStack>
			<HStack gap={5} justify={"end"}>
				<VStack>
					<Text fontWeight={"bold"} color={"secundary"}>
						Balance
					</Text>
					<Text>$ {usd_balance}</Text>
				</VStack>
			</HStack>
		</HStack>
	);
};
