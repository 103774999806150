import axios from "axios";

/**
 *
 * @name createChallenge
 * @description Creates a new Chess.com challenge
 * @param {object} data The challenge data
 * @returns {object} The challenge data
 *
 */
export const createChallenge = async (data) => {
	try {
		const response = await axios.post(`/chess/challenge/create`, data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name acceptChallenge
 * @description Accepts a challenge
 * @param {object} data The challenge data
 * @returns {object} The challenge data
 *
 */
export const acceptChallenge = async (data) => {
	try {
		const response = await axios.post(`/chess/challenge/accept`, data);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name getAllPublicChallenges
 * @description Gets all the public challenges
 * @param {number} gameId The game id
 * @returns {object} The challenges
 *
 */
export const getAllPublicChallenges = async (gameId) => {
	try {
		const response = await axios.get(`/chess/challenges/public/${gameId}`);
		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name getChallengeById
 * @description Gets a challenge by id
 * @param {number} challengeId The challenge id
 * @returns {object} The challenge
 *
 */
export const getChallengeById = async (challengeId) => {
	try {
		const response = await axios.get(`/chess/challenge/${challengeId}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name cancelChallenge
 * @description Cancels a challenge
 * @param {number} challengeId The challenge id
 * @returns {object} The challenge
 *
 */
export const cancelChallenge = async (challengeId) => {
	try {
		const response = await axios.post(`/chess/challenge/cancel`, {
			id: challengeId,
		});

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name getALLMyChallenges
 * @description Gets all the challenges
 * @returns {object} The challenges
 *
 */
export const getALLMyChallenges = async () => {
	try {
		const response = await axios.get(`/chess/my/challenges/all`);
		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
