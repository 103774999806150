import { useEffect } from "react";
import { SportBook } from "../../components/Betby/SportBook";
import { useDispatch } from "react-redux";
import { getMe } from "../../redux/reducers/AuthReducer";

export const SportGames = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getMe());
		}, 7000); // Fetch data every 7 seconds

		return () => clearInterval(interval); // Cleanup
	}, [dispatch]);

	return (
		<>
			<SportBook />
		</>
	);
};
