import { Stack, Text } from "@chakra-ui/react"
import { useSelector } from "react-redux"

/**
 * 
 * @name TextHeaderMobile
 * @description Header user information mobile user profile
 * @returns {JSX.Element} The JSX Code for the component
 * @version 1.0.0
 * 
 */
export const TextHeaderMobile = () => {
    const { user } = useSelector((state) => state.auth)
  return (
    <Stack display={'flex'} flexDirection={'column'} gap={0}>
        <Text fontSize={'2xl'} color={'primary'} mt={5}>
            {`${user?.username}`}
        </Text>
        <Text fontSize={'sm'} color={'primary'}>
            {`@${user?.public_name}`}
        </Text>
    </Stack>
  )
}
