import { Box, Stack, useDisclosure, useMediaQuery } from "@chakra-ui/react";

import { useSelector } from "react-redux";

import LoginModal from "../../Modals/Login/Login";
import WalletModal from "../../Modals/WalletModal/WalletModal";
import { UserProfileMobile } from "../../Profile/UserProfileMobile";
import { Sidebar } from "./Sidebar";
import { SidebarExtend } from "./SidebarExtend";
import { TopNav } from "./TopNav";
import UserProfileModal from "../../Modals/UserProfileEdit/UserProfileEdit";
import { Footer } from "../../Footer/Footer";
import { useState } from "react";
import { SidebarAdminPanel } from "./SidebarAdminPanel";
import { SidebarExtendAdminPanel } from "./SidebarExtendAdminPanel";

/**
 *
 * @name SidebarWithHeader
 * @description The sidebar with header component
 * @param {Object} props The props for the SidebarWithHeader component
 * @param {Object} props.children The children object
 * @returns {JSX.Element} The JSX Code for the SidebarWithHeader component
 * @version 1.0.0
 *
 */
const SidebarWithHeader = ({ setFilter, urlSportbook, setUrlSportbook, urlUserXp, setUrlUserXp, children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { user } = useSelector((state) => state.auth);
	const isConnected = user !== undefined && user !== null;
	const { isOpen: isOpenWallet, onOpen: onOpenWallet, onClose: onCloseWallet } = useDisclosure();
	const { isOpen: isOpenProfileModal, onOpen: onOpenProfileModal, onClose: onCloseProfileModal } = useDisclosure();
	const [onSidebarExtend, setOnSidebarExtend] = useState(false);
	const [isLargerThanMd] = useMediaQuery("(max-width: 767px)");
	const [ulrNavigate, setUrlNavigate] = useState(false);

	return (
		<Box minH="100vh">
			{!onSidebarExtend ? (
				!ulrNavigate ? (
					<Sidebar
						onOpenWallet={onOpenWallet}
						onOpenProfileModal={onOpenProfileModal}
						setOnSidebarExtend={setOnSidebarExtend}
						onClose={() => onClose}
						display={{ base: "none", md: "block" }}
						isConnected={isConnected}
						setUrlSportbook={setUrlSportbook}
						urlSportbook={urlSportbook}
						urlUserXp={urlUserXp}
						setUrlUserXp={setUrlUserXp}
						setFilter={setFilter}
					/>
				) : (
					<SidebarAdminPanel isConnected={isConnected} setOnSidebarExtend={setOnSidebarExtend} />
				)
			) : onSidebarExtend && !ulrNavigate ? (
				<SidebarExtend
					onOpenWallet={onOpenWallet}
					onOpenProfileModal={onOpenProfileModal}
					setOnSidebarExtend={setOnSidebarExtend}
					onClose={() => onClose}
					display={{ base: "none", md: "block" }}
					isConnected={isConnected}
					setUrlSportbook={setUrlSportbook}
					setFilter={setFilter}
					urlSportbook={urlSportbook}
					urlUserXp={urlUserXp}
					setUrlUserXp={setUrlUserXp}
				/>
			) : (
				<SidebarExtendAdminPanel
					isConnected={isConnected}
					setOnSidebarExtend={setOnSidebarExtend}
					onClose={() => onClose}
				/>
			)}

			{isConnected ? (
				<UserProfileMobile isOpen={isOpen} onClose={onClose} />
			) : (
				<LoginModal isOpen={isOpen} onClose={onClose} />
			)}

			<TopNav
				onOpenWallet={onOpenWallet}
				onOpen={onOpen}
				isConnected={isConnected}
				setUrlSportbook={setUrlSportbook}
				setUrlNavigate={setUrlNavigate}
			/>
			<Box ml={{ base: 0, md: 10 }} p={{ base: 0, md: 8 }}>
				<Stack
					width={
						onSidebarExtend
							? !isLargerThanMd
								? urlSportbook
									? "calc(100vw - 210px)"
									: "calc(70vw)"
								: "100vw"
							: !isLargerThanMd
							? urlSportbook
								? "calc(100vw - 89px)"
								: "calc(70vw)"
							: "100vw"
					}
					pos={"absolute"}
					top={"80px"}
					left={onSidebarExtend ? (urlSportbook ? "200px" : "15vw") : urlSportbook ? "76px" : "15vw"}
					right={urlSportbook ? "0px" : "15vw"}
				>
					<Box mb={!urlSportbook ? 10 : 5} minH={"calc(100vh - 420px)"}>
						{children}
					</Box>
					{!urlSportbook && <Footer />}
				</Stack>
			</Box>
			<WalletModal isOpen={isOpenWallet} onClose={onCloseWallet} />
			<UserProfileModal isOpen={isOpenProfileModal} onClose={onCloseProfileModal} />
		</Box>
	);
};

export default SidebarWithHeader;
