import { AbsoluteCenter, Box, Button, ButtonGroup, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { AvatarProfile } from "../../components/Profile/AvatarProfile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const UserProfile = () => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	return (
		<Box bgColor={"darkOscure"} borderRadius={{ base: "0px", md: "10px" }} p={5}>
			<Flex flexDirection={{ base: "column", md: "row" }} gap={5}>
				<Center
					p={5}
					bgColor={"#3A3B3B"}
					borderRadius={{ base: "0px", md: "10px" }}
					w={{ base: "100%", md: "20%" }}
					flexDirection={"column"}
				>
					<AvatarProfile />
				</Center>
				<Box bgColor={"#3A3B3B"} borderRadius={{ base: "0px", md: "10px" }} w={{ base: "100%", md: "38.5%" }}>
					<Text p={5} color={"secundary"} fontSize={"xl"}>
						Balance
					</Text>
				</Box>
				<Box
					position={"relative"}
					bgColor={"secundary"}
					borderRadius={{ base: "0px", md: "10px" }}
					w={{ base: "100%", md: "40%" }}
				>
					<AbsoluteCenter w={"100%"} textAlign={"center"}>
						<Heading color={"white"} fontSize={"4xl"}>
							{user?.exp} XP
						</Heading>
						<Text>The more you play the more you earn!</Text>
						<Button mt={5} variant={"experience"} onClick={() => navigate("/earn-xp")}>
							EARN POINTS
						</Button>
					</AbsoluteCenter>
				</Box>
			</Flex>
			<Flex flexDirection={{ base: "column", md: "row" }} gap={5} mt={5}>
				<Box
					bgColor={"#3A3B3B"}
					borderRadius={{ base: "0px", md: "10px" }}
					w={{ base: "100%", md: "60%" }}
					flexDirection={"column"}
					align={"start"}
				>
					<Flex
						flexDirection={{ base: "column", md: "row" }}
						p={5}
						alignItems={{ base: "left", md: "center" }}
					>
						<Text me={5} color={"secundary"} fontSize={"xl"}>
							Stats
						</Text>
						<ButtonGroup size={"xs"}>
							<Button variant={"tags"}>Casino</Button>
							<Button variant={"tags"}>Sports</Button>
							<Button variant={"tags"}>Games</Button>
						</ButtonGroup>
					</Flex>
				</Box>

				<Flex flexDirection={"column"} gap={5} w={"40%"}>
					<Box
						bgColor={"#3A3B3B"}
						borderRadius={{ base: "0px", md: "10px" }}
						flexDirection={"column"}
						align={"start"}
					>
						<Text p={5} color={"secundary"} fontSize={"xl"}>
							Game History
						</Text>
					</Box>
					<Box s bgColor={"#3A3B3B"} borderRadius={"10px"} flexDirection={"column"} align={"start"}>
						<Text p={5} color={"secundary"} fontSize={"xl"}>
							Transations
						</Text>
					</Box>
				</Flex>
			</Flex>
		</Box>
	);
};
