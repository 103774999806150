import { Button, Flex, FormControl, FormLabel, Heading, Input, Stack, useColorModeValue } from "@chakra-ui/react";

/**
 * 
 * @name ResetPassword
 * @description The reset password page component
 * @returns {JSX.Element} The JSX Code for the ResetPassword component
 * @version 1.0.0
 * 
 * TODO: add reset password functionality
 */
const ResetPassword = () => {
    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
            <Stack
                spacing={4}
                w={"full"}
                maxW={"md"}
                bg={useColorModeValue("white", "gray.700")}
                rounded={"xl"}
                boxShadow={"lg"}
                p={6}
                my={12}>
                <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
                    Enter new password
                </Heading>
                <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input placeholder="your-email@example.com" _placeholder={{ color: "gray.500" }} type="email" />
                </FormControl>
                <FormControl id="password" isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" />
                </FormControl>
                <Stack spacing={6}>
                    <Button
                        bg={"blue.400"}
                        color={"white"}
                        _hover={{
                            bg: "blue.500",
                        }}>
                        Submit
                    </Button>
                </Stack>
            </Stack>
        </Flex>
    );
};

export default ResetPassword;
