import { useEffect, useRef, useState } from "react";

import {
	Box,
	Input,
	Stack,
	Button,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Spacer,
	Flex,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";

import SignupModal from "../SignUp/SignUp";
import { ForgotPasswordModal } from "../ForgotPassword/ForgotPassword";

import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import { loginUser, reset } from "../../../redux/reducers/AuthReducer";
import { errorToast } from "../../../utils/toast";

/**
 *
 * @name LoginModal
 * @description The login modal component
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @returns {JSX.Element} The JSX Code for the LoginModal component
 * @version 1.0.0
 *
 */

const LoginModal = ({ isOpen = false, onClose }) => {
	const [needReload, setNeedReload] = useState(false);
	const buttonRef = useRef(null);
	const [inputUser, setInputUser] = useState("");
	const [inputPassword, setInputPassword] = useState("");

	const { isOpen: isOpenSignup, onOpen: onOpenSignup, onClose: onCloseSignup } = useDisclosure();
	const { isOpen: isOpenForgot, onOpen: onOpenForgot, onClose: onCloseForgot } = useDisclosure();

	const dispatch = useDispatch();
	const toast = useToast();

	const { user, isError, message } = useSelector((state) => state.auth);
	const noSesion = isError && (user === null || user === undefined);

	const handleLogin = () => {
		try {
			dispatch(loginUser({ username: inputUser, password: inputPassword }));
			setNeedReload(false);
		} catch (error) {
			errorToast(toast, "Incorrect user or password, please try again", error.message);
		}
	};

	useEffect(() => {
		if (noSesion && isError && !needReload) {
			setNeedReload(true);
			if (message !== "Need to login.") {
				errorToast(toast, "Incorrect user or password, please try again");
				dispatch(reset());
			}
		}
	}, [isError, message, toast, noSesion, dispatch, needReload]);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === "Enter" && buttonRef.current) {
				buttonRef.current.click();
			}
		};

		document.addEventListener("keydown", handleKeyPress);

		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, []);

	const handleOpenSignup = () => {
		onOpenSignup();
	};

	const handleOpenForgot = () => {
		onOpenForgot();
	};

	const handleClose = () => {
		setInputUser("");
		setInputPassword("");
		onClose();
	};

	return (
		<>
			<Modal isOpen={isOpen} size={"lg"} onClose={handleClose} isCentered>
				<ModalOverlay />
				<ModalContent bgColor={"#2F2D2D"}>
					<Heading mt={4} textAlign="center" color="white">
						LOGIN
					</Heading>
					<ModalCloseButton color="white" />
					<Stack spacing={8} mx={"auto"} maxW={"lg"}>
						<Box rounded={"lg"} boxShadow={"lg"} minW={"lg"} p={8}>
							<Stack spacing={2}>
								<Input
									placeholder="Username or Email"
									variant={"bitbet"}
									color={"white"}
									type="username"
									value={inputUser}
									onChange={(e) => setInputUser(e.target.value)}
								/>
								<Input
									placeholder="Password"
									variant={"bitbet"}
									color={"white"}
									type="password"
									value={inputPassword}
									onChange={(e) => setInputPassword(e.target.value)}
								/>
								<Stack spacing={10}>
									<Stack
										direction={{ base: "column", sm: "row" }}
										align={"start"}
										justify={"space-between"}
									>
										<Spacer />
										<Flex gap={1}>
											<Box color="white">Forgot your</Box>{" "}
											<Box cursor={"pointer"} color="#FF7A00" onClick={handleOpenForgot}>
												password?
											</Box>
										</Flex>
									</Stack>
									<Stack spacing={2}>
										<Button
											size="lg"
											variant={"ghost"}
											border="1px"
											borderColor="#FF7A00"
											color={"white"}
											_hover={{
												color: "white",
											}}
											onClick={handleLogin}
											ref={buttonRef}
										>
											Sign in
										</Button>
										<Button size="lg" bgColor={"#FF7A00"} onClick={handleOpenSignup}>
											Sign up
										</Button>
									</Stack>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</ModalContent>
			</Modal>
			<SignupModal isOpen={isOpenSignup} onClose={onCloseSignup} />
			<ForgotPasswordModal isOpen={isOpenForgot} onCloseForgot={onCloseForgot} />
		</>
	);
};

export default LoginModal;
