import { Avatar, Box, Divider, Icon, Spacer, Stack, Text } from "@chakra-ui/react";
import { CiLogout } from "react-icons/ci";
import { FiFile, FiGrid, FiUsers } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/reducers/AuthReducer";
import { BiBarChart } from "react-icons/bi";
import { SiLevelsdotfyi } from "react-icons/si";
import { OptionSidebarExtendAdminPanel } from "./Subcomponents/OptionSidebarExtendAdminPanel";

/**
 *
 * @name SidebarExtendAdminPanel
 * @description The sidebar component
 * @param {Object} props The props for the Sidebar component
 * @param {Function} props.onClose The onClose function
 * @param {Boolean} props.isConnected The isConnected boolean
 * @returns {JSX.Element} The JSX Code for the Sidebar component
 * @version 1.0.0
 *
 */
export const SidebarExtendAdminPanel = ({ setOnSidebarExtend, onClose, setUrlSportbook, ...rest }) => {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(logout()).then(() => {
			onClose();
			window.location.reload();
		});
	};

	return (
		<Box color={"#969998"} pos="relative" h="full" {...rest}>
			<Stack
				height={"calc(100dvh - 100px)"}
				width={"200px"}
				spacing={0}
				top={"80px"}
				pos="fixed"
				bgColor={"#302D2B"}
				borderRadius={"10px"}
			>
				<Stack display={"flex"} mt={2} padding={"10px"}>
					<OptionSidebarExtendAdminPanel
						title={"Hide"}
						icono={<CiLogout />}
						onOpen={() => setOnSidebarExtend(false)}
					/>
					<Divider my={2} mx={"auto"} width={"100%"} />
					<OptionSidebarExtendAdminPanel
						title={"Dashboard"}
						icono={<FiGrid />}
						url={"/admin-panel"}
						setUrlSportbook={setUrlSportbook}
					/>
					<OptionSidebarExtendAdminPanel
						title={"Challenges"}
						icono={<BiBarChart />}
						url={"/admin-panel/challenges"}
						setUrlSportbook={setUrlSportbook}
					/>
					<OptionSidebarExtendAdminPanel
						title={"Levels"}
						icono={<SiLevelsdotfyi />}
						url={"/admin-panel/levels"}
						setUrlSportbook={setUrlSportbook}
					/>
					<OptionSidebarExtendAdminPanel
						title={"Users"}
						icono={<FiUsers />}
						url={"/admin-panel/users"}
						setUrlSportbook={setUrlSportbook}
					/>

					<OptionSidebarExtendAdminPanel
						title={"Analytics"}
						icono={<FiFile />}
						url={"/admin-panel/analytics"}
						setUrlSportbook={setUrlSportbook}
					/>
				</Stack>

				<Spacer></Spacer>

				<Stack>
					<>
						<Divider my={2} mx={"auto"} width={"80%"} />

						<Stack display={"flex"} flexDirection={"row"} alignItems={"center"} padding={"10px"}>
							<Avatar name="BitBet" src="/images/profile_image.png" />
							<Stack mx={2} fontSize={"xs"} spacing={0}>
								<Text>{user.username}</Text>
								<Text>{`@${user.public_name}`}</Text>
							</Stack>
							<Icon
								as={FaPowerOff}
								cursor={"pointer"}
								transition={"1s"}
								_hover={{
									color: "secundary",
								}}
								onClick={handleLogout}
							/>
						</Stack>
					</>
				</Stack>
			</Stack>
		</Box>
	);
};
