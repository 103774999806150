import { Box, Button, Center, Grid, GridItem, HStack, Icon, Text, useToast } from "@chakra-ui/react";
import { errorToast, okToast } from "../../utils/toast";
import { claimingChallengeExperience } from "../../services/users";
import { useDispatch } from "react-redux";
import { getMe } from "../../redux/reducers/AuthReducer";
import { iconMap } from "../../utils/utils";

export const CardChallenges = ({ challenge, allChallengesClaimToUser }) => {
	const IconComponent = iconMap[challenge?.icon];
	const dispatch = useDispatch();
	const toast = useToast();
	const isClaimed = allChallengesClaimToUser.includes(challenge?.challengeExperienceId);

	const claimingTheChallenge = async () => {
		try {
			await claimingChallengeExperience(challenge?.challengeExperienceId);
			dispatch(getMe());
			okToast(toast, "Challenge claimed successfully");
		} catch (error) {
			errorToast(toast, "Error when claiming challenge experience", error);
		}
	};

	return (
		<HStack w={"100%"} p={3} border={"2px solid #302D2B"} borderRadius={10}>
			<Grid w={"100%"} templateColumns="repeat(4, 1fr)" gap={5}>
				<GridItem w="100%" colSpan={2}>
					<HStack>
						<Icon as={IconComponent} w={"40px"} h={"40px"} />
						<Text>{challenge?.name}</Text>
					</HStack>
				</GridItem>
				<Center>
					<GridItem w="100%">
						<Box textAlign={"center"} px={2} border={"2px solid orange"} borderRadius={10}>
							<Text fontWeight={"bold"}>{challenge?.exp} XP</Text>
						</Box>
					</GridItem>
				</Center>
				<Center>
					<GridItem w="100%">
						<Button
							w={"100%"}
							isDisabled={isClaimed}
							variant={"solidOrange"}
							_hover={{ bg: isClaimed && "secundary" }}
							onClick={() => claimingTheChallenge()}
						>
							{isClaimed ? "Claimed" : "Claim"}
						</Button>
					</GridItem>
				</Center>
			</Grid>
		</HStack>
	);
};
