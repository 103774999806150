/* global BTRenderer */

import { useEffect, useState } from "react";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import LoginModal from "../Modals/Login/Login";
import SignupModal from "../Modals/SignUp/SignUp";
import { getTokenUser } from "../../services/betby/getTokenUser";
import WalletModal from "../Modals/WalletModal/WalletModal";

export const SportBook = () => {
	const { isOpen: isOpenLoginModal, onOpen: onOpenLoginModal, onClose: onCloseLoginModal } = useDisclosure();
	const { isOpen: isOpenSignup, onOpen: onOpenSignup, onClose: onCloseSignup } = useDisclosure();
	const { isOpen: isOpenWallet, onOpen: onOpenWallet, onClose: onCloseWallet } = useDisclosure();
	const { user } = useSelector((state) => state.auth);
	const isConnected = user !== undefined && user !== null;
	const [isLargerThanSm] = useMediaQuery("(max-width: 480px)");
	const [needReload, setNeedReload] = useState(true);
	const [betbyInstance, setBetbyInstance] = useState(null);

	useEffect(() => {
		if (needReload) {
			setNeedReload(false);
			// Crear el elemento script
			const scriptElement = document.createElement("script");
			// Configurar los atributos del script (por ejemplo, src, type, etc.)
			scriptElement.src = "https://ui.invisiblesport.com/bt-renderer.min.js";
			scriptElement.type = "text/javascript";
			// Añadir el script al head del documento
			document.head.appendChild(scriptElement);
			// Función para esperar a que el script se cargue completamente
			const waitForScriptToLoad = () => {
				return new Promise((resolve, reject) => {
					scriptElement.onload = resolve;
					scriptElement.onerror = reject;
				});
			};

			waitForScriptToLoad().then(() => {
				const initializeBetby = async (token) => {
					const config = {
						brand_id: "2359361206319452160",
						token: token,
						themeName: "bit-bet",
						isDemo: false,
						lang: "en",
						target: document.getElementById("betby"),
						betSlipOffsetTop: 80,
						betSlipOffsetBottom: 0,
						betSlipOffsetRight: isLargerThanSm ? 0 : 32,
						betslipZIndex: 100,
						stickyTop: 80,
						onTokenExpired: await getTokenUser,
						onRouteChange: function (e) {
							console.log("🚀 onRouteChange", e);
						},
						onLogin: onOpenLoginModal,
						onRegister: onOpenSignup,
						onRecharge: onOpenWallet,
						onSessionRefresh: function (e) {
							console.log("🚀 onSessionRefresh", e);
						},
					};

					return new BTRenderer().initialize(config);
				};

				if (isConnected) {
					getTokenUser().then((res) => {
						initializeBetby(res).then((instance) => {
							setBetbyInstance(instance);
						});
					});
				} else {
					initializeBetby(null).then((instance) => {
						setBetbyInstance(instance);
					});
				}
			});
		}

		// Limpiar el script cuando el componente se desmonta
		return () => {
			if (betbyInstance !== null) {
				betbyInstance.kill();
				setBetbyInstance(null);
				setNeedReload(true);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [betbyInstance]);

	return (
		<>
			<div id="betby"></div>

			<LoginModal isOpen={isOpenLoginModal} onClose={onCloseLoginModal} />
			<SignupModal isOpen={isOpenSignup} onClose={onCloseSignup} />
			<WalletModal isOpen={isOpenWallet} onClose={onCloseWallet} />
		</>
	);
};
