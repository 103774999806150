import { memo, useEffect, useState } from "react";
import { Route } from "react-router-dom";
// Pages
// import Signup from "../pages/SignUp/SignUp";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

// import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import CreateChallenge from "../components/Modals/Games/Challenges/CreateChallenge";
// import PublicChallenges from "../pages/Chess/PublicChallenges";
import Chess from "../pages/Chess/Chess";
import VerifyAccountChess from "../components/Modals/Games/VerifyGameAccount/VerifyAccountChess";
import Challenge from "../pages/Chess/Challenge";
import MyChallenges from "../pages/Chess/MyChallenges";
import { RoutesNotFound } from "./RoutesNotFound";
import { PlayOneGame } from "../pages/BlueOceanOneGame/PlayOneGame";
import { CasinoGames } from "../pages/Dashboard/CasinoGames";
import { VideoGames } from "../pages/Dashboard/VideoGames";
import { UserProfile } from "../pages/Profile/UserProfile";
import { SportGames } from "../pages/Dashboard/SportGames";
import Layout from "../components/Layout/Layout";
import { DashboardAdminPanel } from "../pages/AdminPanel/DashboardAdminPanel";
import { ChallengesAdminPanel } from "../pages/AdminPanel/ChallengesAdminPanel";
import { UsersAdminPanel } from "../pages/AdminPanel/UsersAdminPanel";
import { PartnersAdminPanel } from "../pages/AdminPanel/PartnersAdminPanel";
import { AnalyticsAdminPanel } from "../pages/AdminPanel/AnalyticsAdminPanel";
import { LevelsAdminPanel } from "../pages/AdminPanel/LevelsAdminPanel";
import { UserEarnXp } from "../pages/Profile/UserEarnXp";
import { AuthGuard } from "./authGuard/auth.guards";
import { AdminGuard } from "./authGuard/admin.guards";

/**
 *
 * @name AppRoutes
 * @description The app routes component
 * @returns {JSX.Element} The JSX Code for the AppRoutes component
 * @version 1.0.0
 *
 */
const AppRoutes = memo(() => {
	const [filter, setFilter] = useState("all");
	const [urlSportbook, setUrlSportbook] = useState(false);
	const [urlUserXp, setUrlUserXp] = useState(false);

	useEffect(() => {
		let userIP = null;

		const getIP = async () => {
			return await fetch("https://api.ipify.org?format=json").then((response) => response.json());
		};

		const getInfo = async (ip) => {
			return await fetch("https://ipwhois.app/json/" + ip + "?lang=es").then((response) => response.json());
		};

		getIP().then((response) => {
			userIP = response.ip;
			getInfo(userIP).then((location) => {
				if (location) {
					// console.log(location.country_code);
				}
			});
		});
	}, []);

	return (
		<Layout
			setFilter={setFilter}
			urlSportbook={urlSportbook}
			setUrlSportbook={setUrlSportbook}
			urlUserXp={urlUserXp}
			setUrlUserXp={setUrlUserXp}
		>
			<RoutesNotFound>
				<Route path="/verify-email" element={<VerifyEmail />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/" element={<Dashboard setUrlSportbook={setUrlSportbook} />} />
				<Route path="/casino-games" element={<CasinoGames filter={filter} setFilter={setFilter} />} />
				<Route path="/sport-games" element={<SportGames />} />
				<Route path="/video-games" element={<VideoGames />} />
				<Route path="/chess" element={<Chess />} />
				<Route path="/chess/verify" element={<VerifyAccountChess />} />
				<Route path="/chess/challenge/create" element={<CreateChallenge />} />
				<Route path="/game/:type/:gameName/:gameId" element={<PlayOneGame />} />
				<Route path="/challenge/:id" element={<Challenge />} />
				<Route element={<AuthGuard />}>
					<Route path="/my-challenges" element={<MyChallenges />} />
					<Route path="/profile" element={<UserProfile />} />
					<Route path="/earn-xp" element={<UserEarnXp />} />
					<Route element={<AdminGuard />}>
						<Route path="/admin-panel/" element={<DashboardAdminPanel />} />
						<Route path="/admin-panel/challenges" element={<ChallengesAdminPanel />} />
						<Route path="/admin-panel/users" element={<UsersAdminPanel />} />
						<Route path="/admin-panel/partners" element={<PartnersAdminPanel />} />
						<Route path="/admin-panel/analytics" element={<AnalyticsAdminPanel />} />
						<Route path="/admin-panel/levels" element={<LevelsAdminPanel />} />
					</Route>
				</Route>
			</RoutesNotFound>
		</Layout>
	);
});

export default AppRoutes;
