import { Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

/**
 *
 * @name OptionSidebarExtend
 * @description The option sidebar extend component
 * @param {Object} props The props for the Sidebar component
 * @param {Function} props.onClose The onClose function
 * @param {Boolean} props.isConnected The isConnected boolean
 * @returns {JSX.Element} The JSX Code for the option sidebar extend component
 * @version 1.0.0
 *
 */
export const OptionSidebarExtendAdminPanel = ({ title, icono, onOpen, url }) => {
	const navigate = useNavigate();

	const onClickOptions = () => {
		if (url) {
			navigate(url);
		} else {
			onOpen();
		}
	};
	return (
		<Stack
			display={"flex"}
			flexDirection={"row"}
			alignItems={"center"}
			cursor={"pointer"}
			transition={"0.5s"}
			_hover={{
				color: "secundary",
			}}
			onClick={onClickOptions}
		>
			{icono}
			<Text>{title}</Text>
		</Stack>
	);
};
