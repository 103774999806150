import { memo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../../redux/reducers/AuthReducer";

// Components
import { useDisclosure } from "@chakra-ui/react";
import SidebarWithHeader from "./LateralMenu/SidebarWithHeader";
import TwoStepVerification from "../Modals/TwoStepVerification/TwoStepVerification";

/**
 * @name Layout
 * @description The layout component
 * @param {Object} props The props for the component
 * @param {JSX.Element} props.children The children of the component
 * @returns {JSX.Element} The JSX Code for the Layout component
 * @version 1.0.0
 */

const Layout = memo(({ setFilter, urlSportbook, setUrlSportbook, urlUserXp, setUrlUserXp, children }) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { isOpen: isOpenVerification, onOpen: onOpenVerification, onClose: onCloseVerification } = useDisclosure();

	useEffect(() => {
		dispatch(getMe());
	}, [dispatch]);

	useEffect(() => {
		if (user && !user.verified) {
			onOpenVerification();
		}
	}, [user, onOpenVerification]);

	return (
		<>
			<SidebarWithHeader
				setFilter={setFilter}
				urlSportbook={urlSportbook}
				setUrlSportbook={setUrlSportbook}
				urlUserXp={urlUserXp}
				setUrlUserXp={setUrlUserXp}
			>
				{children}
			</SidebarWithHeader>
			<TwoStepVerification isOpen={isOpenVerification} email={user?.email} onClose={onCloseVerification} />
		</>
	);
});

export default Layout;
