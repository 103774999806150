import React from "react";
import { HeaderAllPage } from "../../components/AdminPanel/HeaderAllPage";

export const PartnersAdminPanel = () => {
	return (
		<>
			<HeaderAllPage title={"Partners"} />
		</>
	);
};
