import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  checkUserCodeTime,
  sendUserCode,
  verifyCode,
} from "../../../services/users";
import { errorToast, okToast } from "../../../utils/toast";
import { ModalCloseButton } from "@chakra-ui/react";

/**
 *
 * @name TwoStepVerification
 * @description Modal component that performs two-step verification.
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @param {Boolean} props.onCloseForgot The state of the modal Forgot
 * @param {String} props.email The user email.
 * @returns {JSX.Element} The JSX Code for the TwoStepVerification component
 * @version 1.0.0
 *
 */

const TwoStepVerification = ({ isOpen = false, onClose, email, onCloseForgot}) => {
  const toast = useToast();
  const [inputPin, setInputPin] = useState("");
  const [codeType, setCodeType] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [needReload, setNeedReload] = useState(true);

  // Count buttom NEW SEND CODE.
  useEffect(() => {
   
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        
      }, 1000);

      return () => clearInterval(intervalId);
    
  }, [seconds]);

  //Check time user code
  useEffect(() => {
    const checkTime = async () => {
      try {
        const response = await checkUserCodeTime({
          email,
        });
        if (!response.error) {
          setSeconds(60);
          setCodeType(response.type);
        }
      } catch (error) {
        errorToast(toast, "Error checking code time", error.message);
      } finally {
        setNeedReload(false);
      }
    };
    isOpen && needReload && checkTime();
  }, [isOpen, needReload, toast, email]);
 
  // Perform the code input check.
  const handleCheckCode = async (code) => {
	verifyCode({ code: code }).then(() => {
		okToast(toast, "Code verified", "Login succesfull");
		handleClose();
	}).catch ((error) => {
		errorToast(toast, `Wrong code, request code after ${seconds} seconds`, error.message);
	  });
  };

  // Save the values entered in the inputs.
  const onChangeInput = (_inputPin) => {
	setInputPin(_inputPin);
	if (_inputPin.length === 6) {
		handleCheckCode(_inputPin);
	}
  }

  // The process to resend a new code.
  const handleResendCode = async () => {
    try {
      const response = await sendUserCode({
        type: codeType || "verify",
        email: email,
      });
      if (!response.error) {
        setSeconds(60);
        okToast(toast, "Code sent", "Check your email");
        setNeedReload(true);
      } else {
        errorToast(toast, "Error sending code", response.message);
      }
    } catch (error) {
      errorToast(toast, "Error sending code", error.message);
    }
  };

  // Action upon closing the verification modal.
  const handleClose = () => {
	setInputPin("");
  setSeconds(0)
	onClose();
  onCloseForgot && onCloseForgot()
  
  setNeedReload(true);
  };
  
  return (
    <Modal isOpen={isOpen} size={"md"} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent bgColor={"#2F2D2D"}>
        <Heading my={4} textAlign="center" color="white">
          VERIFY ACCOUNT
        </Heading>
        <ModalCloseButton color="white" />
        <Center>
          <Box bgColor="#413D53" maxW={"md"} p={8} rounded="md">
            <Text color="white">We send you a code to your email.</Text>
            <Text color="white" fontSize={"sm"}>
              Please check your e-mail and insert the code.
            </Text>

            <HStack justifyContent="center" spacing={4} my={4}>
              <PinInput
                mt={4}
                size="lg"
                color="white"
                value={inputPin}
                onChange={(value) => onChangeInput(value)}
              >
                <PinInputField color="white" fontWeight="bold" />
                <PinInputField color="white" fontWeight="bold" />
                <PinInputField color="white" fontWeight="bold" />
                <PinInputField color="white" fontWeight="bold" />
                <PinInputField color="white" fontWeight="bold" />
                <PinInputField color="white" fontWeight="bold" />
              </PinInput>
            </HStack>
          </Box>
        </Center>

        {seconds === 0 && (
          <Center mb={4} p={2}>
            <Button
              size={"lg"}
              variant={"outline"}
              _hover={{
                bgColor: "#FF7A00",
              }}
              color="white"
              onClick={handleResendCode}
              w="100%"
              fontSize={"sm"}
            >
              RESEND CODE
            </Button>
          </Center>
        )}

        {seconds > 0 && (
          <Center mb={4} p={2}>
            <Text color="white" fontSize={"sm"}>
              Wait {seconds} seconds to resend the code
            </Text>
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};

export default TwoStepVerification;
