import { useEffect, useState } from "react";
import { getChallengeById } from "../../services/challenges/challenges";
import { useNavigate, useParams } from "react-router-dom";
import { FiChevronsLeft } from "react-icons/fi";
import { Badge, Box, Center, Flex, IconButton, SimpleGrid, Stack, Text, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ChessBoard from "../../components/Games/Chess/ChessBoard";
import { InfoItem } from "../../components/Challenges/InfoItem";

/**
 *
 * @name Challenge
 * @description The challenge page component
 * @returns {JSX.Element} The JSX Code for the Challenge component
 * @version 1.0.0
 *
 */
const Challenge = () => {
	const [challengeData, setChallengeData] = useState(null);
	const [moves, setMoves] = useState(null);
	const navigate = useNavigate();
	const { id } = useParams();
	const { user } = useSelector((state) => state.auth);

	const imPlayer =
		user && (user.username === challengeData?.creatorName || user.username === challengeData?.opponentName);

	useEffect(() => {
		const fetchChallengeData = async () => {
			try {
				const response = await getChallengeById(id);

				setChallengeData(response.data.challenge);
				setMoves(response.data.moves);
			} catch (error) {}
		};

		fetchChallengeData();
	}, [id]);

	if (!challengeData) return null;

	return (
		<Box textAlign="left" px={4}>
			<Flex direction="column" alignItems="flex-start" mb={8}>
				<Flex gap={1}>
					<Badge>{`${challengeData?.GameMode?.name}`}</Badge>
					<Badge>Chess</Badge>
				</Flex>
				<Box display={"flex"} alignItems={"center"}>
					<Tooltip label="Go back" placement="bottom" bgColor="orange" color="black">
						<IconButton
							icon={<FiChevronsLeft style={{ width: "50px", height: "50px" }} />}
							onClick={() => {
								navigate("/chess");
							}}
							bgColor={"transparent"}
							color={"secundary"}
							transition={"0.5s"}
							_hover={{
								transform: "scale(1.2)",
							}}
						/>
					</Tooltip>
					<Text fontSize="5xl" fontWeight="black" color="white">
						CHESS.COM
					</Text>
				</Box>

				<Flex alignItems="center" color="white" gap={2}>
					<Text
						color={() => {
							switch (challengeData.status) {
								case "open":
									return "blue";
								case "waiting":
									return "yellow";
								case "live":
									return "green";
								case "ended":
									return "green";
								case "cancelled":
									return "red";
								default:
									return "white";
							}
						}}
						fontSize="3xl"
					>
						●
					</Text>
					<Text fontSize="xl">
						{challengeData?.status === "open" && "WAITING FOR OPPONENT"}
						{challengeData?.status === "waiting" && "WAITING FOR START TIME"}
						{challengeData?.status === "cancelled" && "CHALLENGE CANCELLED"}
						{challengeData?.status === "live" &&
							`LIVE ${challengeData.winnerName} VS ${challengeData?.opponentName}`}
						{challengeData?.status === "ended" && `ENDED`}
						{challengeData.winnerName !== null &&
							(() => {
								switch (challengeData.winnerName) {
									case "agreed" || "stalemate" || "insufficient":
										return ` | ${challengeData.winnerName.toUpperCase()}`;
									default:
										return ` | WINNER: ${challengeData.winnerName.toUpperCase()}`;
								}
							})()}
					</Text>
				</Flex>
			</Flex>
			<SimpleGrid columns={moves ? 2 : 1} spacing={10}>
				<Box>
					<Stack gap={8} mb={8} direction={{ base: "column" }}>
						<Center w="100%" p={2} rounded="lg" border="1px solid #FF7A00" gap={2}>
							<InfoItem
								label="Creator"
								value={challengeData?.creatorName}
								chessUser={imPlayer && challengeData?.creatorNickname}
							/>
						</Center>
						{challengeData?.opponentName && (
							<Center w="100%" p={2} rounded="lg" border="1px solid #FF7A00">
								<InfoItem
									label="Opponent"
									value={challengeData?.opponentName}
									chessUser={imPlayer && challengeData?.opponentNickname}
								/>
							</Center>
						)}
					</Stack>

					<Stack direction={"column"} gap={10} alignItems={"start"}>
						<SimpleGrid columns={2} spacing={5} width={"100%"}>
							<Center w="100%" p={2} rounded="lg" border="1px solid #FF7A00">
								<InfoItem label="Start" value={new Date(challengeData?.startTime).toLocaleString()} />
							</Center>
							<Center w="100%" p={2} rounded="lg" border="1px solid #FF7A00">
								<InfoItem label="End" value={new Date(challengeData?.endTime).toLocaleString()} />
							</Center>
						</SimpleGrid>
						<SimpleGrid columns={2} spacing={5} width={"100%"}>
							<Center w="100%" p={2} rounded="lg" border="1px solid #FF7A00">
								<InfoItem fontSize={"sm"} label="Bet" value={`$ ${parseFloat(challengeData?.bet)}`} />
							</Center>
							<Center w="100%" p={2} rounded="lg" border="1px solid #FF7A00">
								<InfoItem
									label="Winner"
									value={challengeData.winnerName ? challengeData.winnerName : "..."}
								/>
							</Center>
						</SimpleGrid>
					</Stack>
				</Box>
				<Box>
					<Stack>
						{challengeData?.status === "ended" && (
							<Text fontSize="xl" fontWeight="bold" color="white">
								<ChessBoard PNGMoves={moves?.moves} />
							</Text>
						)}
					</Stack>
				</Box>
			</SimpleGrid>
		</Box>
	);
};

export default Challenge;
