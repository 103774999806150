import { useNavigate } from "react-router-dom";
import { GameCardVideoGame } from "../../components/CardGames/GameCardVideoGame";
import { GalleySliderImage } from "../../components/Dashboard/GalleySliderImage";

export const VideoGames = () => {
	const navigate = useNavigate();

	const navigateToGame = (gameName) => {
		navigate(`/${gameName}`);
	};

	return (
		<>
			<GalleySliderImage type={"game"} />
			<GameCardVideoGame onClick={() => navigateToGame("chess")} />
		</>
	);
};
