import axios from 'axios'

/**
 * 
 * @name checkServer
 * @description The function makes a request to the back, to find out if he is available or down.
 * @returns {JSX.Element} The JSX Code for the function component
 * @version 1.0.0
 * 
 */

export const checkServer = async (data) => {
    try {
        const response = await axios.get(`/services/ping`, data)
        return response.data;
    } catch (error) {
        throw error.response.data.message;
    }
}

