import { Box, Button, Flex, HStack, Heading, Icon, Image, Spacer, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineCasino } from "react-icons/md";
import { MdOutlineSportsVolleyball } from "react-icons/md";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { sportGame, videoGame } from "../../data/dataGameImages";

export const CardDashboard = ({ gameList, setUrlSportbook }) => {
	const [isLargerThanMd] = useMediaQuery("(max-width: 767px)");
	const [startingPositionCasinoGame, setStartingPositionCasinoGame] = useState(0);
	const [startingPositionGamingGame, setStartingPositionGamingGame] = useState(0);
	const [startingPositionSportbookGame, setStartingPositionSportbookGame] = useState(0);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [numberCard, setNumberCard] = useState(parseInt((screenWidth * 70) / 100 / 165));
	const navigate = useNavigate();
	const navigateToPlayOneGame = (gameName, gameId, type) => {
		navigate(`/game/${type}/${gameName}/${gameId}`);
	};

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			setNumberCard(parseInt((window.innerWidth * 70) / 100) / 165);
		};

		window.addEventListener("resize", handleResize);

		// Limpieza del event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [screenWidth]);

	return (
		<>
			{/* Juegos gaming */}
			<Stack flexDirection={"column"} gap={5} mb={10}>
				<Stack flexDirection={"row"} justifyContent={isLargerThanMd ? "center" : "left"} align={"center"}>
					<IoGameControllerOutline size={"25px"} color="white" />
					<Heading size={"md"} color="white" textAlign={"left"}>
						Gaming
					</Heading>
					<Spacer />
					<HStack gap={5}>
						<Text cursor={"pointer"} onClick={() => navigate("video-games")}>
							View all
						</Text>
						<HStack>
							<Button
								size={"20px"}
								variant={startingPositionGamingGame === 0 ? "finishLength" : "moreLength"}
								isDisabled={startingPositionGamingGame === 0}
								onClick={() =>
									setStartingPositionGamingGame(startingPositionGamingGame - (numberCard - 1))
								}
							>
								<Icon as={FiChevronLeft} color={"white"} boxSize={"20px"} />
							</Button>
							<Button
								size={"20px"}
								variant={
									startingPositionGamingGame + numberCard >= videoGame?.length
										? "finishLength"
										: "moreLength"
								}
								onClick={() =>
									setStartingPositionGamingGame(startingPositionGamingGame + (numberCard - 1))
								}
								isDisabled={startingPositionGamingGame + numberCard >= videoGame?.length}
							>
								<Icon as={FiChevronRight} boxSize={"20px"} />
							</Button>
						</HStack>
					</HStack>
				</Stack>
				<Flex flexWrap={"wrap"} justifyContent={isLargerThanMd ? "center" : "left"} gap={5}>
					{videoGame
						.slice(startingPositionGamingGame, startingPositionGamingGame + numberCard)
						.map((game) => (
							<Box key={game.gameId} rounded="lg" minW={"150px"} h="210px">
								<Image
									src={game.image}
									borderRadius="10px"
									w={"150px"}
									h={"210px"}
									color="white"
									borderColor={"transparent"}
									textShadow="0px 0px 10px black"
									cursor="pointer"
									onClick={game.name === "Chess" ? () => navigate(`/chess`) : null}
									_hover={{
										border: "2px",
										borderRadius: "10px",
										borderColor: "#FF7A00",
									}}
								/>
							</Box>
						))}
				</Flex>
			</Stack>
			{/* Juegos casino */}
			<Stack flexDirection={"column"} gap={5} mb={10}>
				<Stack flexDirection={"row"} justifyContent={isLargerThanMd ? "center" : "left"} align={"center"}>
					<MdOutlineCasino size={"25px"} color="white" />
					<Heading size={"md"} color="white" textAlign={"left"}>
						Casino
					</Heading>
					<Spacer />
					<HStack gap={5}>
						<Text cursor={"pointer"} onClick={() => navigate("casino-games")}>
							View all
						</Text>
						<HStack>
							<Button
								size={"20px"}
								variant={startingPositionGamingGame === 0 ? "finishLength" : "moreLength"}
								isDisabled={startingPositionCasinoGame === 0}
								onClick={() =>
									setStartingPositionCasinoGame(startingPositionCasinoGame - (numberCard - 1))
								}
							>
								<Icon as={FiChevronLeft} boxSize={"20px"} />
							</Button>
							<Button
								size={"20px"}
								variant={
									startingPositionGamingGame + numberCard >= gameList?.length
										? "finishLength"
										: "moreLength"
								}
								onClick={() =>
									setStartingPositionCasinoGame(startingPositionCasinoGame + (numberCard - 1))
								}
								isDisabled={startingPositionCasinoGame + numberCard >= gameList?.length}
							>
								<Icon as={FiChevronRight} boxSize={"20px"} />
							</Button>
						</HStack>
					</HStack>
				</Stack>
				<Stack flexDirection={"row"} justifyContent={isLargerThanMd ? "center" : "left"} gap={5}>
					{gameList
						?.slice(startingPositionCasinoGame, startingPositionCasinoGame + numberCard)
						.map((game) => (
							<Box
								key={game.gameId}
								rounded="lg"
								minW={"150px"}
								h="210px"
								display={"flex"}
								justifyContent={"center"}
							>
								<Image
									src={`/images/games/casinoGames/${game.gameImage}`}
									borderRadius="10px"
									w={"150px"}
									h={"210px"}
									color="white"
									borderColor={"transparent"}
									textShadow="0px 0px 10px black"
									cursor="pointer"
									onClick={() =>
										navigateToPlayOneGame(`${game.gameName}`, `${game.gameId}`, `${game.category}`)
									}
									_hover={{
										border: "2px",
										borderRadius: "10px",
										borderColor: "#FF7A00",
									}}
								/>
							</Box>
						))}
				</Stack>
			</Stack>
			{/* Juegos sportbook */}
			<Stack flexDirection={"column"} gap={5} mb={10}>
				<Stack flexDirection={"row"} justifyContent={isLargerThanMd ? "center" : "left"} align={"center"}>
					<MdOutlineSportsVolleyball size={"25px"} color="white" />
					<Heading size={"md"} color="white" textAlign={"left"}>
						Sport
					</Heading>
					<Spacer />
					<HStack gap={5}>
						<Text
							cursor={"pointer"}
							onClick={() => {
								setUrlSportbook(true);
								navigate("/sport-games");
							}}
						>
							View all
						</Text>
						<HStack>
							<Button
								size={"20px"}
								variant={startingPositionGamingGame === 0 ? "finishLength" : "moreLength"}
								isDisabled={startingPositionSportbookGame === 0}
								onClick={() =>
									setStartingPositionSportbookGame(startingPositionSportbookGame - (numberCard - 1))
								}
							>
								<Icon as={FiChevronLeft} boxSize={"20px"} />
							</Button>
							<Button
								size={"20px"}
								variant={
									startingPositionGamingGame + numberCard >= videoGame?.length
										? "finishLength"
										: "moreLength"
								}
								onClick={() =>
									setStartingPositionSportbookGame(startingPositionSportbookGame + (numberCard - 1))
								}
								isDisabled={startingPositionSportbookGame + numberCard >= sportGame?.length}
							>
								<Icon as={FiChevronRight} boxSize={"20px"} />
							</Button>
						</HStack>
					</HStack>
				</Stack>
				<Flex flexWrap={"wrap"} justifyContent={isLargerThanMd ? "center" : "left"} gap={5}>
					{sportGame
						.slice(startingPositionSportbookGame, startingPositionSportbookGame + numberCard)
						.map((game) => (
							<Box
								key={game.gameId}
								rounded="lg"
								minW={"150px"}
								h="210px"
								display={"flex"}
								justifyContent={"center"}
							>
								<Image
									src={game.image}
									borderRadius="10px"
									w={"150px"}
									h={"210px"}
									color="white"
									borderColor={"transparent"}
									textShadow="0px 0px 10px black"
									cursor="pointer"
									onClick={() => {
										setUrlSportbook(true);
										navigate(`${game.url}`);
									}}
									_hover={{
										border: "2px",
										borderRadius: "10px",
										borderColor: "#FF7A00",
									}}
								/>
							</Box>
						))}
				</Flex>
			</Stack>
		</>
	);
};
