import {
    Box,
    Button,
    Heading,
    Spacer,
    Stack,
    useDisclosure,
    useToast,
    Tooltip,
    IconButton,
} from '@chakra-ui/react'
import { FiChevronsLeft } from 'react-icons/fi'
import NewChallengeModal from '../../components/Modals/Games/Challenges/NewChallengeModal'
import { useEffect, useState } from 'react'
import { getAllPublicChallenges } from '../../services/challenges/challenges'
import ChallengeTable from '../../components/Challenges/ChallengeTable'
import { errorToast } from '../../utils/toast'
import { useSelector } from 'react-redux'
import VerifyAccountChess from '../../components/Modals/Games/VerifyGameAccount/VerifyAccountChess'
import { useNavigate } from 'react-router-dom'

/**
 * 
 * @name Chess
 * @description The chess page component
 * @returns {JSX.Element} The JSX Code for the Chess component
 * @version 1.0.0
 *
 */
const Chess = () => {

    const toast = useToast()
    const { isOpen:isOpenNewChallege, onClose:onCloseNewChallenge, onOpen:onOpenNewChallenge } = useDisclosure()
    const { isOpen:isOpenVerification, onClose:onCloseVerification, onOpen:onOpenVerification } = useDisclosure()
    const [challenges, setChallenges] = useState([])
    const [needReload, setNeedReload] = useState(true)

    const navigate = useNavigate()

    const { user } = useSelector((state) => state.auth)

    const handleOnOpen = () => {
        if(user?.games?.chesscom) {
            onOpenNewChallenge()
        } else {
            onOpenVerification()
            setNeedReload(true)
        }
    }

    const handleNavigateToPage = (page) => {
        navigate(page)
    }

    useEffect(() => {
        const handleGetChallenges = async () => {
            try {
                const response = await getAllPublicChallenges(1)
                setChallenges(response)
            } catch (error) {
                errorToast(toast, 'Error getting challenges', error.message)
            }  finally {
                setNeedReload(false)
            }
        }

        needReload && handleGetChallenges()
    }, [needReload, toast])

    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                width="100%"
                rounded={"xl"}
                background={'#302D2B'}>
                <Stack
                    direction="column"
                    spacing={4}
                    width="100%"
                    maxWidth="1200px"
                    padding="24px">
                    <Stack
                        direction="row"
                        width="100%"
                        padding={4}
                        spacing={4}
                        justifyContent="space-between">
                        <Box display={'flex'} alignItems={'center'}>

                            <Tooltip label='Go back' placement="bottom" bgColor="orange" color="black">
                                
                                <IconButton
                                    icon={<FiChevronsLeft style={{width:'50px', height:'50px'}}/>}
                                    onClick={() => {navigate('/')}}
                                    bgColor={'transparent'}
                                    color={'secundary'}
                                    transition={'0.5s'}
                                    _hover={{
                                        transform: 'scale(1.2)',
                                    }}
                                />
                              
                            </Tooltip>
                            <Heading textColor={'white'} textAlign={'left'}>
                                Challenges
                            </Heading>
                        </Box>
                        <Spacer />
                        <Stack direction="row" spacing={4}>
                            {user && (
                                    <Button 
                                    variant="outlineOrange"
                                    onClick={() => handleNavigateToPage('/my-challenges')}
                                    >
                                        MY CHALLENGES
                                    </Button>
                            )}
                            <Button variant="outlineOrange">FILTERS</Button>
                            <Button variant="outlineOrange">SORT BY</Button>
                            {user &&  (
                                <Button variant="solidOrange" onClick={handleOnOpen}>
                                    {user.games.chesscom !== null ? "+ NEW CHALLENGE" : "VERIFY"}
                                </Button>
                            )}
                            
                        </Stack>
                    </Stack>

                    <Spacer />

                    <ChallengeTable
                        challenges={challenges}
                        setNeedReload={setNeedReload}
                    />
                </Stack>
            </Box>
            <NewChallengeModal
                isOpen={isOpenNewChallege}
                onClose={onCloseNewChallenge}
                setNeedReload={setNeedReload}
            />
            <VerifyAccountChess
                isOpen={isOpenVerification}
                onClose={onCloseVerification}
            />
        </>
    )
}

export default Chess
