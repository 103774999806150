import { useEffect, useState } from "react";

import {
	Box,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Button,
	Heading,
	Text,
	Collapse,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	Checkbox,
	ModalCloseButton,
} from "@chakra-ui/react";

import { GrFormView, GrFormViewHide } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import { createUser } from "../../../services/users";
import { errorToast, okToast } from "../../../utils/toast";
import { validatePassword } from "../../../utils/passwords";

/**
 *
 * @name SignupModal
 * @description The signup modal component
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @returns {JSX.Element} The JSX Code for the SignupModal component
 * @version 1.0.0
 *
 */
const SignupModal = ({ isOpen = false, onClose }) => {
	const toast = useToast();
	const [inputPublicName, setInputPublicName] = useState("");
	const [inputPublicProfile, setInputPublicProfile] = useState(false);
	const [inputUser, setInputUser] = useState("");
	const [inputEmail, setInputEmail] = useState("");
	const [inputConfirmEmail, setInputConfirmEmail] = useState("");
	const [inputPassword, setInputPassword] = useState("");
	const [inputConfirmPassword, setInputConfirmPassword] = useState("");
	const [inputReferredTo, setInputReferredTo] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const referredTo = queryParams.get("referredTo");
		setInputReferredTo(referredTo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [checkMoreThan18, setCheckMoreThan18] = useState(false);

	const checkEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const isValidPublicName = inputPublicName.length >= 4 && inputPublicName.length <= 16;
	// const isValidBirthdate = inputBirthdate !== "";
	// const isValidCountry = inputCountry !== "";
	// const bithdateMoreThan18 = new Date(inputBirthdate) < new Date(new Date().setFullYear(new Date().getFullYear() - 18));

	const isValidUser = inputUser.length >= 4 && inputUser.length <= 16;
	const isValidPassword = inputPassword.length >= 8 && validatePassword(inputPassword);

	const sameEmail = inputEmail === inputConfirmEmail;
	const samePassword = inputPassword === inputConfirmPassword;

	const isValid = isValidUser && isValidPassword && sameEmail && samePassword && isValidPublicName && checkMoreThan18;

	const handleRegister = async () => {
		try {
			const register = await createUser({
				username: inputUser,
				email: inputEmail,
				password: inputPassword,
				confirm_password: inputConfirmPassword,
				public_name: inputPublicName,
				show_public: inputPublicProfile,
				referralCode: inputUser,
				referredTo: inputReferredTo,
			});
			if (register) {
				okToast(toast, register.message);
				onClose();
			}
		} catch (error) {
			errorToast(toast, "Email already exists", error.message);
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} size={"lg"} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent bgColor={"#2F2D2D"}>
					<Heading textAlign="center" pt={5} color="white">
						SIGN UP
					</Heading>
					<ModalCloseButton color="white" />
					<Stack spacing={4} mx={"auto"} maxW={"lg"} py={6} px={6}>
						<Box minW={"md"} color="white">
							<Stack direction={{ base: "column" }} spacing={2}>
								<Input
									placeholder="Username"
									variant="bitbet"
									type="text"
									onChange={(e) => setInputUser(e.target.value)}
								/>

								<Input
									placeholder="Public name"
									variant="bitbet"
									type="text"
									onChange={(e) => setInputPublicName(e.target.value)}
								/>

								<Checkbox
									colorScheme="orange"
									value={inputPublicProfile}
									onChange={(e) => setInputPublicProfile(e.target.checked)}
								>
									<Text fontSize={"xs"} fontWeight={"bold"}>
										Show my public name (disabled = anonymous)
									</Text>
								</Checkbox>

								<Stack spacing={2}>
									<Input
										placeholder="Email address"
										variant="bitbet"
										type="email"
										onChange={(e) => setInputEmail(e.target.value)}
									/>

									<Collapse in={inputEmail.length > 0 && checkEmail(inputEmail)}>
										<Input
											placeholder="Confirm email address"
											variant="bitbet"
											type="email"
											onChange={(e) => setInputConfirmEmail(e.target.value)}
										/>
									</Collapse>

									<Collapse
										in={
											inputEmail.length > 0 &&
											inputConfirmEmail.length > 0 &&
											inputEmail !== inputConfirmEmail
										}
									>
										<Text color="red.500" fontWeight={"bold"}>
											Emails don't match
										</Text>
									</Collapse>

									<InputGroup>
										<Input
											placeholder="Password"
											variant="bitbet"
											type={showPassword ? "text" : "password"}
											onChange={(e) => setInputPassword(e.target.value)}
										/>
										<InputRightElement h={"full"}>
											<Button
												variant={"ghost"}
												w="100%"
												h="100%"
												onClick={() => setShowPassword((showPassword) => !showPassword)}
											>
												{showPassword ? <GrFormView size={70} /> : <GrFormViewHide size={70} />}
											</Button>
										</InputRightElement>
									</InputGroup>

									<Collapse in={inputPassword.length > 0}>
										<InputGroup>
											<Input
												type={showPassword ? "text" : "password"}
												variant="bitbet"
												onChange={(e) => setInputConfirmPassword(e.target.value)}
											/>
											<InputRightElement h={"full"}>
												<Button
													variant={"ghost"}
													w="100%"
													h="100%"
													onClick={() => setShowPassword((showPassword) => !showPassword)}
												>
													{showPassword ? (
														<GrFormView size={70} />
													) : (
														<GrFormViewHide size={70} />
													)}
												</Button>
											</InputRightElement>
										</InputGroup>
									</Collapse>

									<Collapse in={!isValidUser && inputUser.length > 0}>
										<Text color="red.500" fontWeight={"bold"}>
											Username must be at least 4 - 16 characters.
										</Text>
									</Collapse>

									<Collapse in={!isValidPublicName && inputPublicName.length > 0}>
										<Text color="red.500" fontWeight={"bold"}>
											Public name must be at least 4 - 16 characters.
										</Text>
									</Collapse>

									<Collapse in={inputPassword.length > 0 && inputPassword.length < 8}>
										<Text color="red.500" fontWeight={"bold"}>
											Password must be at least 8 characters.
										</Text>
									</Collapse>

									<Collapse
										in={
											!samePassword &&
											inputPassword.length >= 8 &&
											inputConfirmPassword.length >= 8
										}
									>
										<Text color="red.500" fontWeight={"bold"}>
											Passwords don't match.
										</Text>
									</Collapse>

									<Collapse in={!validatePassword(inputPassword) && inputPassword.length >= 8}>
										<Text color="red.500" fontWeight={"bold"}>
											Password need one uppercase, lowercase and number.
										</Text>
									</Collapse>

									<Checkbox
										my={3}
										colorScheme="orange"
										value={checkMoreThan18}
										onChange={(e) => setCheckMoreThan18(e.target.checked)}
									>
										<Text fontSize={"xs"} fontWeight={"bold"}>
											I AM OVER 18 AND DO NOT ALREADY HAVE AN ACCOUNT. <br />I AGREE TO THE TERMS
											OF USE PRIVACY & COOKIES POLICY.
										</Text>
									</Checkbox>
									<Stack flexDirection={"column"}>
										<Text>Referred to by (Optional):</Text>
										<Input
											placeholder="Referral code"
											variant="bitbet"
											type="text"
											value={inputReferredTo}
											onChange={(e) => setInputReferredTo(e.target.value)}
										/>
									</Stack>

									<Stack spacing={2} pt={2}>
										<Button
											loadingText="Submitting"
											_hover={{
												color: "white",
											}}
											size="lg"
											bg={"#FF7A00"}
											color={"white"}
											isDisabled={!isValid}
											onClick={handleRegister}
										>
											Sign up
										</Button>

										<Button
											size="lg"
											variant={"ghost"}
											border="1px"
											borderColor="#FF7A00"
											color={"white"}
											_hover={{
												color: "#FF7A00",
											}}
											onClick={onClose}
										>
											Login
										</Button>
									</Stack>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</ModalContent>
			</Modal>
		</>
	);
};

export default SignupModal;
