import {
	Box,
	Center,
	Grid,
	GridItem,
	HStack,
	Heading,
	Icon,
	Spacer,
	Stack,
	Text,
	VStack,
	useToast,
} from "@chakra-ui/react";
import { MdDeleteOutline } from "react-icons/md";
import {
	deleteChallengeExperience,
	updateStatusChallengeExperience,
} from "../../services/adminPanel/AdminPanelServices";
import { errorToast, okToast } from "../../utils/toast";
import { iconMap } from "../../utils/utils";

export const CardChallengesAdminPanel = ({
	challengeExperienceId,
	icon,
	name,
	action,
	amount,
	game,
	experience,
	timeStarted,
	timeFinish,
	status,
	setNeedReload,
}) => {
	const toast = useToast();

	const IconComponent = iconMap[icon];

	timeStarted = `${new Date(timeStarted).toLocaleDateString()} ${new Date(timeStarted).toLocaleTimeString()}`;
	timeFinish = timeFinish
		? `${new Date(timeFinish).toLocaleDateString()} ${new Date(timeFinish).toLocaleTimeString()}`
		: "Not finished";

	const onDelete = () => {
		const deleteChallenge = async () => {
			try {
				await deleteChallengeExperience(challengeExperienceId);
				setNeedReload(false);

				return okToast(toast, "Challenge deleted successfully");
			} catch (error) {
				errorToast(toast, "Error when deleting challenge experience", error);
			}
		};

		deleteChallenge();
	};

	const onUptdateStatus = () => {
		const updateStatus = async () => {
			try {
				await updateStatusChallengeExperience(challengeExperienceId, { isActive: !status });
				setNeedReload(false);

				return okToast(toast, "Status challenge status updated successfully");
			} catch (error) {
				errorToast(toast, "Error when updating status challenge experience", error);
			}
		};

		updateStatus();
	};

	return (
		<HStack
			bgColor={"darkGray"}
			borderRadius={5}
			w={"full"}
			p={5}
			gap={10}
			mb={5}
			justify={"space-between"}
			alignItems={"center"}
		>
			<Grid templateColumns="repeat(9, 1fr)" gap={1} w={"100%"}>
				<GridItem w="100%" colSpan={2}>
					<HStack>
						<Icon as={IconComponent} w={"40px"} h={"40px"} />
						<Heading color={"white"} fontSize={"md"} fontWeight={500} fontFamily={"body"}>
							{name}
						</Heading>
					</HStack>
				</GridItem>
				<GridItem w="100%">
					<VStack w={"100px"} gap={0}>
						<Text>{action}</Text>
						<Text fontWeight={"bold"} color={"secundary"}>
							Action
						</Text>
					</VStack>
				</GridItem>
				<GridItem w="100%">
					<VStack gap={0}>
						<Text>{amount}</Text>
						<Text fontWeight={"bold"} color={"secundary"}>
							Amount
						</Text>
					</VStack>
				</GridItem>
				<GridItem w="100%">
					<VStack gap={0}>
						<Text>{amount}</Text>
						<Text fontWeight={"bold"} color={"secundary"}>
							Amount
						</Text>
					</VStack>
				</GridItem>
				<GridItem w="100%">
					<VStack gap={0}>
						<Text>{game}</Text>
						<Spacer />
						<Text fontWeight={"bold"} color={"secundary"}>
							{game && "Game"}
						</Text>
					</VStack>
				</GridItem>
				{/* <GridItem w="100%" colSpan={2}>
					<VStack gap={0}>
						<Text>{timeStarted}</Text>
						<Text fontWeight={"bold"} color={"secundary"}>
							Date start
						</Text>
					</VStack>
				</GridItem>
				<GridItem w="100%" colSpan={2}>
					<VStack gap={0}>
						<Text>{timeFinish}</Text>
						<Text fontWeight={"bold"} color={"secundary"}>
							Date finish
						</Text>
					</VStack>
				</GridItem> */}
				<GridItem w="100%">
					<VStack>
						<Box
							p={2}
							rounded={"full"}
							bgColor={status ? "green" : "red"}
							cursor={"pointer"}
							onClick={onUptdateStatus}
						></Box>
						<Text fontWeight={"bold"} color={"secundary"}>
							Status
						</Text>
					</VStack>
				</GridItem>
				<Center>
					<GridItem w="100%">
						<Box px={2} border={"2px solid orange"} borderRadius={10}>
							<Text fontWeight={"bold"} w={"100%"}>
								{experience} XP
							</Text>
						</Box>
					</GridItem>
				</Center>
				<Center>
					<GridItem w="100%">
						<Stack alignItems={"end"}>
							<Icon
								cursor={"pointer"}
								transition={"0.5s"}
								_hover={{ transform: "scale(120%) rotate(20deg)" }}
								boxSize={"7"}
								as={MdDeleteOutline}
								onClick={onDelete}
							/>
						</Stack>
					</GridItem>
				</Center>
			</Grid>
		</HStack>
	);
};
