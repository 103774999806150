import { HStack, Heading, Text } from "@chakra-ui/react";
import React from "react";

export const CardUserOrderByExp = ({ user, index }) => {
	return (
		<HStack w={"100%"} p={3} justify={"space-between"} border={"2px solid darkGray"} borderRadius={10}>
			<HStack>
				<Heading fontSize={"md"}>{index < 10 ? `0${index}` : index}</Heading>
				{/* <Avatar size="lg" borderRadius={"50%"} name={`image profile default`} src="/images/profile_image.png" /> */}
				<Text fontSize={"sm"}>{user?.public_name}</Text>
			</HStack>

			<Text fontSize={"sm"}>{user?.exp} XP</Text>
		</HStack>
	);
};
