import { useNavigate } from "react-router-dom";
import { Box, Flex, Heading, Image, Stack, useMediaQuery } from "@chakra-ui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import { useEffect, useState } from "react";
import { MdOutlineCasino } from "react-icons/md";

/**
 *
 * @name GameCardCasino
 * @description Component that renders the different Casino games cards.
 * @returns {JSX.Element} The JSX Code for the CurrencyMenu component
 * @author saguado | All-in-one Blockchain Company
 * @version 1.0.0
 *
 */
export const GameCardCasino = ({ gameList, filter = "all" }) => {
	const [title, setTitle] = useState("Casino");
	const navigate = useNavigate();
	const [isLargerThanMd] = useMediaQuery("(max-width: 767px)");

	useEffect(() => {
		switch (true) {
			case filter.includes("live-casino-table" || "livecasino"):
				setTitle("Live casino");
				break;
			case filter.includes("scratch-cards"):
				setTitle("Scratch cards");
				break;
			case filter.includes("video-slots"):
				setTitle("Video slots");
				break;
			case filter.includes("crash-games"):
				setTitle("Crash games");
				break;
			case filter.includes("table-games"):
				setTitle("Table games");
				break;
			case filter.includes("virtual-games"):
				setTitle("Virtual games");
				break;
			case filter.includes("games"):
				setTitle(null);
				break;
			default:
				setTitle("Casino");
		}
	}, [filter]);

	const navigateToPlayOneGame = (gameName, gameId, type) => {
		navigate(`/game/${type}/${gameName}/${gameId}`);
	};

	return (
		<>
			<Stack flexDirection={"row"} justifyContent={isLargerThanMd ? "center" : "left"} align={"center"} my={10}>
				<MdOutlineCasino size={"40px"} color="white" />
				<Heading color="white" textAlign={"left"}>
					{title}
				</Heading>
			</Stack>

			{gameList.length === 0 ? (
				<Box display={"flex"} justifyContent={"left"}>
					<LoadingSpinner />
				</Box>
			) : (
				<Flex flexWrap={"wrap"} justifyContent={isLargerThanMd ? "center" : "left"} gap={5}>
					{gameList.map(
						(game) =>
							(filter.includes(game.category) || filter === "all" || filter === "casino") && (
								<Box
									key={game.gameId}
									rounded="lg"
									minW={"150px"}
									h="210px"
									display={"flex"}
									justifyContent={"center"}
								>
									<Image
										src={`/images/games/casinoGames/${game.gameImage}`}
										borderRadius="10px"
										w={"150px"}
										h={"210px"}
										color="white"
										borderColor={"transparent"}
										textShadow="0px 0px 10px black"
										cursor="pointer"
										onClick={() =>
											navigateToPlayOneGame(
												`${game.gameName}`,
												`${game.gameId}`,
												`${game.category}`
											)
										}
										_hover={{
											border: "2px",
											borderRadius: "10px",
											borderColor: "#FF7A00",
										}}
									/>
								</Box>
							)
					)}
				</Flex>
			)}
		</>
	);
};
