import { Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { checkServer } from "../services/checkServer/checkServer";

/**
 *
 * @name RoutesNotFound
 * @description The routes not available component
 * @returns {JSX.Element} The JSX Code for the routes not available component
 * @version 1.0.0
 *
 */
export const RoutesNotFound = ({ children }) => {
	const [status, setStatus] = useState(true);

	checkServer()
		.then(() => {
			return setStatus(true);
		})
		.catch(() => {
			return setStatus(false);
		});

	return (
		<Routes>
			{!status ? (
				<Route
					path="*"
					element={
						<Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
							<Text fontSize={"4xl"} color={"white"}>
								Server doesn't response
							</Text>
						</Stack>
					}
				/>
			) : (
				<>
					{children}
					<Route
						path="*"
						element={
							<Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
								<Text fontSize={"4xl"} color={"white"}>
									Page not Found
								</Text>
							</Stack>
						}
					/>
				</>
			)}
		</Routes>
	);
};
