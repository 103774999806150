import { Box, Image, Progress, Step, StepIndicator, Stepper, Tooltip } from "@chakra-ui/react";

export const ProgressLvlNav = ({ steps, activeStep }) => {
	const max = steps?.length;
	const progressPercent = (activeStep / max) * 100;

	return (
		<Box position="relative" w={"100%"}>
			<Stepper colorScheme="orange" size="md" index={activeStep} gap="0">
				{steps?.map((level, index) => (
					<Step key={index} gap="0" zIndex={5}>
						<StepIndicator bg="lightGray" borderColor={"lightGray"}>
							<Tooltip
								hasArrow
								bg={"darkGray"}
								rounded={"md"}
								label={`level ${level?.level} (${level?.exp} XP)`}
								placement="bottom"
							>
								<Image src="/images/BitbetSiluet.png" />
							</Tooltip>
						</StepIndicator>
					</Step>
				))}
			</Stepper>

			<Progress
				value={progressPercent}
				colorScheme="orange"
				position="absolute"
				height="3px"
				width="100%"
				top="15px"
				zIndex={1}
				bg={"lightGray"}
			/>
		</Box>
	);
};
