import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App.js';

import { Provider } from 'react-redux';
import { store } from './redux/store/store';

// ----------------- Cookie login -----------------
import axios from "axios";
import { API_ROUTE } from "./data/CONSTANTS";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_ROUTE;
// ------------------------------------------------

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
