import { Avatar, AvatarBadge, IconButton, Text, WrapItem } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";

export const AvatarProfile = () => {
	const { user } = useSelector((state) => state.auth);

	return (
		<>
			<WrapItem>
				<Avatar
					mt={5}
					size="2xl"
					borderRadius={"50%"}
					name={`image profile default`}
					border={"3px solid #FF7A00"}
					src="/images/profile_image.png"
				>
					<AvatarBadge border={"0"}>
						<IconButton
							icon={<FiEdit />}
							size={"xs"}
							border={"2px solid #FF7A00"}
							bgColor={"#353538"}
							color={"white"}
							mb={2}
							me={3}
							transition={"0.5s"}
							_hover={{
								transform: "scale(1.2)",
							}}
						/>
					</AvatarBadge>
				</Avatar>
			</WrapItem>
			<Text color={"primary"} fontSize={"2xl"} mt={2}>
				{`${user?.username}`}
			</Text>
			<Text color={"secundary"} fontSize={"sm"}>
				{`@${user?.public_name}`}
			</Text>
		</>
	);
};
