import { Button, FormControl, FormLabel, HStack, Icon, Input, Select, Stack, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { iconMap } from "../../../../utils/utils";

const styleOption = {
	background: "#413D53",
	color: "white",
	fontFamily: "Poppins, sans-serif",
};

export const FormAddChallengeExperience = ({
	inicializeChallengeExperience,
	challengeExperienceData,
	setChallengeExperienceData,
}) => {
	const [iconModal, setIconModal] = useState({});
	const selectGame = ["win", "bet", "create", "play"];

	const nowDate = () => {
		const now = new Date();

		// Formatear la fecha y hora actual según el formato de datetime-local
		const year = now.getFullYear();
		const month = ("0" + (now.getMonth() + 1)).slice(-2);
		const day = ("0" + now.getDate()).slice(-2);
		const hour = ("0" + now.getHours()).slice(-2);
		const minute = ("0" + now.getMinutes()).slice(-2);
		const formattedDateTime = year + "-" + month + "-" + day + "T" + hour + ":" + minute;

		return formattedDateTime;
	};

	const handlerDataChallenge = (e) => {
		const { name, value } = e.target;

		if (name === "icon") {
			setIconModal({ [name]: iconMap[value] });
		}

		if (name === "timeIniciation") {
			const now = new Date();
			const dateSelect = new Date(value);

			if (dateSelect > now) {
				setChallengeExperienceData({ ...challengeExperienceData, isActive: false, [name]: value });
			} else {
				setChallengeExperienceData({ ...challengeExperienceData, isActive: true, [name]: value });
			}
		} else {
			setChallengeExperienceData({ ...challengeExperienceData, [name]: value });
		}
	};

	return (
		<Stack gap={5}>
			<HStack justifyContent={"space-between"}>
				<HStack gap={5}>
					<Icon as={iconModal.icon} w={"60px"} h={"60px"} />
					<Select
						variant={"bitbet"}
						name="icon"
						onChange={handlerDataChallenge}
						defaultValue={inicializeChallengeExperience?.icon}
					>
						<option style={styleOption} value={""}>
							Select icon
						</option>
						<option style={styleOption} value={"bet"}>
							Bet
						</option>
						<option style={styleOption} value={"invite"}>
							Invite
						</option>
						<option style={styleOption} value={"play"}>
							Play
						</option>
						<option style={styleOption} value={"follow"}>
							Follow
						</option>
						<option style={styleOption} value={"win"}>
							Win
						</option>
						<option style={styleOption} value={"created"}>
							Created
						</option>
						<option style={styleOption} value={"claim"}>
							Claim
						</option>
					</Select>
				</HStack>
				<HStack gap={5}>
					<Select
						w={"80%"}
						variant={"bitbet"}
						name="isActive"
						defaultValue={challengeExperienceData?.isActive}
						value={challengeExperienceData?.isActive}
						onChange={handlerDataChallenge}
					>
						<option style={styleOption} value={true}>
							🟢 Active
						</option>
						<option style={styleOption} value={false}>
							🔴 Inactive
						</option>
					</Select>
					<Select variant={"bitbet"} name="isFeatured" defaultValue={false} onChange={handlerDataChallenge}>
						<option style={styleOption} value={true}>
							⭐ Featured
						</option>
						<option style={styleOption} value={false}>
							🚀 Not featured
						</option>
					</Select>
				</HStack>
			</HStack>
			<HStack gap={5}>
				<FormControl>
					<FormLabel>Challenge Title</FormLabel>
					<Input
						placeholder="Write a title for the challenge"
						type="text"
						variant={"bitbet"}
						name="name"
						value={challengeExperienceData?.name}
						onChange={handlerDataChallenge}
					/>
				</FormControl>
			</HStack>
			<HStack gap={5}>
				<FormControl>
					<FormLabel>Action</FormLabel>
					<Select
						variant={"bitbet"}
						defaultValue={inicializeChallengeExperience?.action}
						name={"action"}
						onChange={handlerDataChallenge}
					>
						<option style={styleOption} value={""}>
							Select action
						</option>
						<option style={styleOption} value={"bet"}>
							Bet
						</option>
						<option style={styleOption} value={"invite"}>
							Invite
						</option>
						<option style={styleOption} value={"play"}>
							Play
						</option>
						{/* <option style={styleOption} value={"follow"}>
							Follow
						</option> */}
						<option style={styleOption} value={"win"}>
							Win
						</option>
						<option style={styleOption} value={"created"}>
							Created
						</option>
						<option style={styleOption} value={"claim"}>
							Claim
						</option>
					</Select>
				</FormControl>
				<FormControl>
					<FormLabel>Amount</FormLabel>
					<Input
						placeholder="Type amount of dolar"
						type="number"
						variant={"bitbet"}
						value={challengeExperienceData?.amount}
						name="amount"
						onChange={handlerDataChallenge}
					/>
				</FormControl>
				{selectGame.includes(challengeExperienceData?.action) && (
					<FormControl>
						<FormLabel>Game</FormLabel>
						<Select
							variant={"bitbet"}
							defaultValue={inicializeChallengeExperience?.game}
							name={"game"}
							onChange={handlerDataChallenge}
						>
							<option style={styleOption} value={""}>
								Select to game
							</option>
							<option style={styleOption} value={"chess"}>
								Chess
							</option>
							<option style={styleOption} value={"casino"}>
								Casino
							</option>
							<option style={styleOption} value={"sportbook"}>
								Sportbook
							</option>
						</Select>
					</FormControl>
				)}
				<FormControl>
					<FormLabel>Experience (XP)</FormLabel>
					<Input
						placeholder="Type amount of experience"
						type="number"
						variant={"bitbet"}
						name="exp"
						value={challengeExperienceData?.exp}
						onChange={handlerDataChallenge}
					/>
				</FormControl>
			</HStack>
			<HStack gap={5}>
				<FormControl w={"100%"}>
					<FormLabel>Date iniciate</FormLabel>
					<HStack gap={0}>
						<Input
							type="datetime-local"
							variant={"bitbet"}
							roundedRight={0}
							name="timeIniciation"
							min={new Date().toISOString().split("T")[0] + "T10:00:00"}
							value={challengeExperienceData?.timeIniciation}
							onChange={handlerDataChallenge}
						/>
						<Button
							roundedLeft={0}
							variant={"solidOrange"}
							onClick={() =>
								handlerDataChallenge({ target: { name: "timeIniciation", value: nowDate() } })
							}
						>
							Now
						</Button>
					</HStack>
				</FormControl>

				{/* <FormControl w={"50%"}>
					<FormLabel>Date finish</FormLabel>
					<Input
						type="datetime-local"
						variant={"bitbet"}
						name="timeFinish"
						value={challengeExperienceData?.timeFinish}
						isDisabled={challengeExperienceData.timeIniciation === "" ? true : false}
						min={challengeExperienceData?.timeIniciation}
						onChange={handlerDataChallenge}
					/>
				</FormControl> */}
			</HStack>
			<HStack gap={5}>
				<FormControl>
					<FormLabel>Description</FormLabel>
					<Textarea
						placeholder="Write the description of the challenge"
						variant={"bitbet"}
						name="description"
						value={challengeExperienceData?.description}
						onChange={handlerDataChallenge}
						h={"150px"}
					/>
				</FormControl>
			</HStack>
		</Stack>
	);
};
