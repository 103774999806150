export const okToast = (toast, title, message) => {
    toast({
        title: title,
        description: message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
    });
}

export const errorToast = (toast, title, message) => {
    toast({
        title: title,
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
    });
}