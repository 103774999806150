import { useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Select,
	Flex,
	NumberInput,
	NumberInputField,
	useToast,
} from "@chakra-ui/react";
import { Button, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { createChallenge } from "../../../../services/challenges/challenges";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, okToast } from "../../../../utils/toast";
import { getMe } from "../../../../redux/reducers/AuthReducer";

/**
 *
 * @name NewChallengeModal
 * @description The new challenge modal component
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @param {Function} props.setNeedReload The function to set the needReload state
 * @returns {JSX.Element} The JSX Code for the NewChallengeModal component
 * @version 1.0.0
 *
 */
const NewChallengeModal = ({ isOpen, onClose, setNeedReload }) => {
	const toast = useToast();
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const [isPrivate, setIsPrivate] = useState(false);
	const [challengeData, setChallengeData] = useState({
		gameModeId: "1",
		currency: "USD",
		bet: "0",
		fee: 5,
	});
	const [startTime, setStartTime] = useState();
	const limit = user?.balances ? user?.balances.balance : 0;

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === "startTime") {
			// Check startTime is not in the past
			const now = new Date();
			const startTime = new Date(value);
			if (startTime < now) {
				errorToast(toast, "Start time must be in the future", "Please select a future time");
				return;
			}
			setStartTime(value);
		} else {
			setChallengeData((prevData) => ({ ...prevData, [name]: value }));
		}
	};

	const handleCreateChallenge = async () => {
		try {
			// TODO: Send POST request with challengeData
			const response = await createChallenge({
				...challengeData,
				startTime: new Date(startTime).toISOString(),
				gameId: 1,
				private: isPrivate,
			});
			if (response) {
				okToast(toast, "Challenge created successfully!", "You can now see it in your challenges");
			} else {
				errorToast(toast, "Challenge creation failed", "Please try again");
			}
		} catch (error) {
			errorToast(toast, "Error creating challenge", error);
		} finally {
			dispatch(getMe());
			setNeedReload(true);
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md">
			<ModalOverlay bgColor={"blackAlpha.800"} />
			<ModalContent bgColor={"#2F2D2D"} p={4} color="white">
				<ModalHeader>+ NEW CHALLENGE</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack spacing={4}>
						<FormControl display="flex">
							<Flex alignItems={"center"} width={"100%"}>
								<Button
									size="md"
									variant="bitbet"
									roundedRight="none"
									width={"100%"}
									textAlign={"left"}
									isActive={isPrivate}
									onClick={() => setIsPrivate(true)}
								>
									Private
								</Button>

								<Button
									size="md"
									variant="bitbet"
									roundedLeft="none"
									width={"100%"}
									textAlign={"right"}
									isActive={!isPrivate}
									onClick={() => setIsPrivate(false)}
								>
									Public
								</Button>
							</Flex>
						</FormControl>

						<Stack direction="row" spacing={4}>
							<FormControl>
								<FormLabel>Game mode</FormLabel>
								<Select
									name="gameModeId"
									value={challengeData.gameModeId}
									onChange={handleInputChange}
									variant={"bitbet"}
								>
									<option
										value="1"
										style={{
											background: "#413D53",
											color: "white",
											fontFamily: "Poppins, sans-serif",
										}}
									>
										1v1
									</option>
								</Select>
							</FormControl>
						</Stack>

						<Stack direction="row" spacing={4}>
							<FormControl>
								<FormLabel>Bet USD</FormLabel>
								<NumberInput
									name="bet"
									variant={"bitbet"}
									value={challengeData.bet < limit ? challengeData.bet : limit}
									max={limit}
									min={0}
								>
									<Flex bgColor={"#413D53"} rounded="md" w="100%">
										<NumberInputField
											placeholder="Enter bet"
											value={challengeData.bet}
											onChange={handleInputChange}
										/>
										<Button
											bgColor={"#292731"}
											color={"white"}
											_hover={{ color: "gray" }}
											onClick={() => {
												setChallengeData((prevData) => ({
													...prevData,
													bet: `${limit}`,
												}));
											}}
										>
											MAX
										</Button>
									</Flex>
								</NumberInput>
							</FormControl>
						</Stack>

						<FormControl>
							<FormLabel>Start time</FormLabel>
							<Input
								variant={"bitbet"}
								type="datetime-local"
								name="startTime"
								value={startTime}
								onChange={handleInputChange}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>Fee</FormLabel>
							<Input name="fee" isDisabled value={"5 %"} variant={"bitbet"} />
						</FormControl>
					</Stack>
				</ModalBody>

				<ModalFooter>
					<Button
						textColor={"white"}
						variant={"solidOrange"}
						onClick={handleCreateChallenge}
						isDisabled={
							!challengeData.gameModeId || !challengeData.currency || challengeData.bet <= 0 || !startTime
						}
						width="100%"
					>
						CREATE {isPrivate ? "PRIVATE" : "PUBLIC"} CHALLENGE
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default NewChallengeModal;
