import { AbsoluteCenter, Box, Button, Flex, Text } from "@chakra-ui/react";
import { Chess } from "chess.js";
import { useEffect, useState } from "react";
import { Chessboard } from "react-chessboard";

const game = new Chess();
const initialPosition = game.fen();

const ChessBoard = ({ PNGMoves }) => {
	const [count, setCount] = useState(0);
	const [finish, setFinish] = useState(false);
	const [init, setInit] = useState(true);
	const [position, setPosition] = useState(initialPosition);

	useEffect(() => {
		count === PNGMoves.length - 1 ? setFinish(true) : setFinish(false);
		count !== 0 ? setInit(false) : setInit(true);
	}, [count, PNGMoves.length]);

	const onPieceDrop = (from, to) => {
		const move = game.move({ from, to });
		if (move === null) return;
		setPosition(game.fen());
		PNGMoves.push(move.san);
	};

	const goForward = () => {
		const move = PNGMoves;
		const countForwar = count + 1;

		if (!finish) {
			game.move(move[count].move);
			setPosition(game.fen());
			setCount(countForwar);
		}
	};

	const goBack = () => {
		const move = PNGMoves;
		const countBack = count - 1;

		countBack === 0 && setInit(true);

		if (count !== 0) {
			game.undo(move[countBack].move);
			setPosition(game.fen());
			setCount(countBack);
		}
	};

	return (
		<Box position="relative" w={"90%"}>
			{finish && (
				<AbsoluteCenter
					p={5}
					zIndex={1}
					textAlign={"center"}
					bgColor={"#FF7A00"}
					borderRadius={"10px"}
					boxShadow={"0 0 5px black"}
				>
					<Text fontSize={"1.5rem"}>{`Finish Game`}</Text>
				</AbsoluteCenter>
			)}
			<Chessboard
				position={position}
				onPieceDrop={(from, to) => {
					onPieceDrop(from, to);
				}}
			/>
			<Flex gap={5} mt={2} justifyContent={"center"}>
				<Button w={"100px"} h={"30px"} variant={"solidOrange"} onClick={goBack} isDisabled={init && true}>
					Back
				</Button>
				<Button w={"100px"} h={"30px"} variant={"solidOrange"} onClick={goForward} isDisabled={finish && true}>
					Forward
				</Button>
			</Flex>
		</Box>
	);
};

export default ChessBoard;
