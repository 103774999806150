import axios from "axios";

/**
 *
 * @name getAllChallengesExperience
 * @description Gets all challenges experience
 * @param {object} data The data object
 * @returns {Array} The challenges experience
 *
 */
export const getAllChallengesExperience = async (experiences) => {
	try {
		const response = await axios.get("/challengeExperience/all", experiences);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllUserOrderByExp
 * @description Gets all users ordered by experience
 * @param {string} username
 * @returns
 */
export const getAllReferredUsers = async () => {
	try {
		const response = await axios.get(`/users/referred`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name createChallengeExperience
 * @description Creates a new challenge experience
 * @param {object} data The data object
 * @returns {object} The response data
 *
 */
export const createChallengeExperience = async (data) => {
	try {
		const response = await axios.post("/challengeExperience", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

export const updateStatusChallengeExperience = async (challengeExperienceId, isActive) => {
	try {
		const response = await axios.patch(`/challengeExperience/${challengeExperienceId}`, isActive);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteChallengeExperience
 * @description Deletes a challenge experience
 * @param {string} id The challenge experience id
 * @returns {object} The response data
 *
 */
export const deleteChallengeExperience = async (id) => {
	try {
		const response = await axios.delete(`/challengeExperience/${id}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllLevels
 * @description Gets all levels
 * @returns
 */
export const getAllLevels = async () => {
	try {
		const response = await axios.get("/challengeExperience/levels");

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name createLevels
 * @description Creates levels
 * @param {number} quantity
 * @returns
 */
export const createLevels = async (data) => {
	try {
		await axios.post("/challengeExperience/levels", data);

		return true;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteLevels
 * @description Deletes levels
 * @param {number} quantity
 * @returns
 */
export const deleteLevels = async (data) => {
	try {
		await axios.delete("/challengeExperience/levels", {
			data: data,
		});

		return true;
	} catch (error) {
		throw error.response.data.message;
	}
};
