import { ChakraProvider } from "@chakra-ui/react";

import AppRoutes from "./routes/AppRoutes";

import { theme } from "./theme/bitbetTheme";
import "./theme/App.css";

function App() {
	return (
		<ChakraProvider theme={theme}>
			<AppRoutes />
		</ChakraProvider>
	);
}

export default App;
