import React, { useEffect, useState } from "react";
import { HeaderAllPage } from "../../components/AdminPanel/HeaderAllPage";
import { CardChallengesAdminPanel } from "../../components/AdminPanel/CardChallengesAdminPanel";
import { getAllChallengesExperience } from "../../services/adminPanel/AdminPanelServices";
import { AddChallengeExperienceModal } from "../../components/Modals/AdminPanel/AddChallengeExperienceModal";
import { useDisclosure } from "@chakra-ui/react";

export const ChallengesAdminPanel = () => {
	const {
		isOpen: isOpenAddChallengeExperience,
		onOpen: onOpenAddChallengeExperience,
		onClose: onCloseAddChallengeExperience,
	} = useDisclosure();
	const [challengesExperience, setChallengesExperience] = useState([]);
	const [needReload, setNeedReload] = useState(false);

	useEffect(() => {
		const allExperiences = async () => {
			const experiences = await getAllChallengesExperience();
			setChallengesExperience(experiences);
			setNeedReload(true);
		};

		!needReload && allExperiences();
	}, [challengesExperience, needReload]);

	return (
		<>
			<HeaderAllPage title={"Challenges"} onOpen={onOpenAddChallengeExperience} />
			{challengesExperience?.map((challenge) => (
				<React.Fragment key={challenge.challengeExperienceId}>
					<CardChallengesAdminPanel
						challengeExperienceId={challenge.challengeExperienceId}
						icon={challenge.icon}
						name={challenge.name}
						experience={challenge.exp}
						game={challenge.game}
						timeStarted={challenge.timeIniciation}
						timeFinish={challenge.timeFinish}
						status={challenge.isActive}
						action={challenge.action}
						amount={challenge.amount}
						setNeedReload={setNeedReload}
					/>
				</React.Fragment>
			))}
			<AddChallengeExperienceModal
				isOpen={isOpenAddChallengeExperience}
				onClose={onCloseAddChallengeExperience}
				setNeedReload={setNeedReload}
			/>
		</>
	);
};
