import { Button, Center } from "@chakra-ui/react";

/**
 *
 * @name OptionPicker
 * @description The option picker component
 * @param {Object} props The props for the component
 * @param {String} props.selectedOption The selected option string
 * @param {Function} props.setOption The function to set the selected option
 * @param {Function} props.setSelectedCurrency The function to set the selected currency
 * @returns {JSX.Element} The JSX Code for the OptionPicker component
 * @version 1.0.0
 *
 */
const OptionPicker = ({ selectedOption, setOption, setSelectedCurrency }) => {
	const handleChangeOption = (option) => {
		setSelectedCurrency("");
		setOption(option);
	};

	return (
		<Center size="sm" mt={6} bgColor={"#413D53"} rounded={"lg"}>
			<Button
				w="100%"
				variant={"bitbet"}
				m={1}
				rounded={"lg"}
				size="lg"
				isActive={selectedOption === "deposit"}
				onClick={() => handleChangeOption("deposit")}
			>
				Deposit
			</Button>

			<Button
				w="100%"
				variant={"bitbet"}
				m={1}
				size="lg"
				isActive={selectedOption === "withdraw"}
				onClick={() => handleChangeOption("withdraw")}
			>
				Withdraw
			</Button>

			{/*             <Button
                w="100%"
                variant={"bitbet"}
                m={1}
                rounded={"lg"}
                size="lg"
                isActive={selectedOption === "balance"}
                onClick={() => handleChangeOption("balance")}>
                Balances
            </Button> */}

			{/*<Button
                w="100%"
                variant={"bitbet"}
                m={1}
                rounded={"lg"}
                size="lg"
                isDisabled
                isActive={selectedOption === "buy"}
                onClick={() => handleChangeOption("buy")}>
                Buy crypto
            </Button>
            */}
		</Center>
	);
};

export default OptionPicker;
