import { Box, Flex, useToast } from "@chakra-ui/react";
import { LatestWinGames } from "../CardGames/LatestWinGames";
import { useEffect, useState } from "react";
import { getLatestWinGames } from "../../services/blueOceanGame/getLatestWinGames";
import { errorToast } from "../../utils/toast";

export const LiveBettingCard = (gameList) => {
	const toast = useToast();
	const [latestWinners, setLatestWinners] = useState([]);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [numberCard, setNumberCard] = useState(parseInt(screenWidth / 130));

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			setNumberCard(parseInt(window.innerWidth / 130));
		};

		window.addEventListener("resize", handleResize);

		// Limpieza del event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	gameList = gameList.gameList || [];

	useEffect(() => {
		getLatestWinGames(numberCard)
			.then((response) => {
				setLatestWinners(response);
			})
			.catch(() => {
				errorToast(toast, "Error, Live wins not received.");
			});
	}, [toast, numberCard]);

	return (
		<Flex>
			<Box position={"relative"} mb={10} bgColor={"#3A3B3B"} borderRadius={"10px"} w={"100%"} h={"150px"}>
				{/* <Box position={"absolute"} top={0} left={0} bgColor={"#3A3B3B"} p={"5px 15px"} borderRadius={"10px"}>
					<HStack gap={3}>
						<HStack gap={1}>
							<Box w={"10px"} h={"10px"} rounded={"full"} bgColor={"red"} />
							<Text>LIVE WINS</Text>
						</HStack>
						<Text>M</Text>
						<Text>W</Text>
						<Text>D</Text>
					</HStack>
				</Box> */}
				<LatestWinGames latestWinners={latestWinners} gameList={gameList} />
			</Box>
		</Flex>
	);
};
