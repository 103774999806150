import { extendTheme } from "@chakra-ui/react";

const styles = {
	global: {
		body: {
			bgColor: "#1F1F22",
			color: "#F2F2F2",
		},
	},
};

const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
};

const colors = {
	ppal_bg: "#1A1A1A",
	second_bg: "#413D53",
	primary: "white",
	secundary: "#FF7A00",
	darkGray: "#3A3B3B",
	mediumGray: "#2F2D2D",
	lightGray: "#9B9B9B",
	darkOscure: "#302D2B",
};

const components = {
	Input: {
		variants: {
			bitbet: {
				field: {
					_focus: {
						border: "1px solid #FF7A00",
						boxShadow: "0 0 0 1px #FF7A00",
					},
					background: "#413D53",
					color: "white",
				},
			},
		},
	},
	Textarea: {
		variants: {
			bitbet: {
				_focus: {
					border: "1px solid #FF7A00",
					boxShadow: "0 0 0 1px #FF7A00",
				},
				background: "#413D53",
				color: "white",
				resize: "none",
			},
		},
	},
	NumberInput: {
		variants: {
			bitbet: {
				field: {
					_focus: {
						border: "1px solid #FF7A00",
						boxShadow: "0 0 0 1px #FF7A00",
					},
					background: "#413D53",
					color: "white",
				},
			},
		},
	},
	Button: {
		variants: {
			bitbet: {
				_active: {
					background: "#FF7A00",
				},
				background: "#413D53",
				color: "white",
			},
			featuredExperience: {
				w: 8,
				h: 9,
				background: "#transparent",
				border: "1px solid darkGray",
				rounded: "full",
				_hover: {
					background: "#FF7A00",
					color: "white",
				},
			},
			experience: {
				_active: {
					background: "#FF7A00",
				},
				background: "#3A3B3B",
				px: 10,
				color: "secundary",
			},
			tags: {
				_active: {
					background: "#FF7A00",
				},
				_hover: {
					background: "#484848",
				},
				background: "#262524",
				color: "white",
				size: "md",
			},
			outlineOrange: {
				background: "transparent",
				boxShadow: "0px 0px 5px inset",
				border: "1px solid #FF7A00",
				color: "#FF7A00",
				_hover: {
					color: "white",
				},
			},
			outlineGreen: {
				border: "1px solid #00FF00",
				color: "#00FF00",
				_hover: {
					background: "#00FF00",
					color: "white",
				},
			},
			solidGreen: {
				background: "#00FF00",
				color: "#302D2B",
				_hover: {
					background: "#00FF00",
					color: "white",
				},
			},
			solidOrange: {
				background: "#FF7A00",
				color: "#302D2B",
				_hover: {
					background: "#FF7A00",
					color: "white",
				},
			},
			moreLength: {
				background: "#FF7A00",
				rounded: "full",
				color: "white",
				_hover: {
					background: "#FF7A00",
					color: "white",
				},
			},
			finishLength: {
				border: "1px solid white",
				rounded: "full",
				size: "20px",
				_hover: {
					background: "#FF7A00",
					color: "white",
				},
			},
			outlineFuchsia: {
				border: "1px solid #FF00FF",
				color: "#FF00FF",
				_hover: {
					background: "#FF00FF",
					color: "white",
				},
			},
			solidFuchsia: {
				background: "#FF00FF",
				color: "#302D2B",
				_hover: {
					background: "#FF00FF",
					color: "white",
				},
			},
		},
	},
	Select: {
		variants: {
			bitbet: {
				field: {
					_focus: {
						border: "1px solid #FF7A00",
						boxShadow: "0 0 0 1px #FF7A00",
					},
					background: "#413D53",
					color: "white",
				},
				option: {
					background: "#413D53",
					color: "white",
				},
			},
		},
	},
	Heading: {
		baseStyle: {
			color: "#FF7A00",
		},
		variants: {
			gradient: {
				bgGradient: "linear(to-l,#FF0000,#F80000, #9B9B9B)",
				bgClip: "text",
				rounded: "lg",
				animation: "gradient 3s ease infinite",
			},
			t1: {
				fontSize: "3xl",
				fontWeight: "bold",
			},
			"t1-gradient": {
				fontSize: "3xl",
				fontWeight: "bold",
				bgGradient: "linear(to-l,#FF0000,#F80000, #9B9B9B)",
				bgClip: "text",
				rounded: "lg",
				animation: "gradient 3s ease infinite",
				textTransform: "uppercase",
			},
		},
	},
	Link: {
		variants: {
			footer: {
				color: "white",
				_hover: {
					color: "secundary",
				},
			},
		},
	},
};

export const theme = extendTheme({ config, styles, components, colors });
