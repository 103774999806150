import { Button, Flex, IconButton, Image, Spacer, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import CurrencyMenu from "./CurrencyMenu";
import { LoginMenuNav } from "./Subcomponents/LoginMenuNav";
import { useSelector } from "react-redux";

/**
 *
 * @name TopNav
 * @description The top nav component
 * @param {Object} props The props for the TopNav component
 * @param {Function} props.onOpen The onOpen function
 * @param {Boolean} props.isConnected The isConnected boolean
 * @returns {JSX.Element} The JSX Code for the TopNav component
 * @version 1.0.0
 *
 */
export const TopNav = ({ onOpen, onOpenWallet, isConnected, setUrlSportbook, setUrlNavigate, ...rest }) => {
	const { user } = useSelector((state) => state.auth);
	const [isLargerThanSm] = useMediaQuery("(max-width: 480px)");
	const bgColor = useColorModeValue("#1F1F22", "#1F1F22");
	const navigate = useNavigate();

	return (
		<>
			<Flex
				ml={{ base: 0, md: 0 }}
				px={{ base: 4, md: 4 }}
				height="20"
				width={"100vw"}
				pos={"fixed"}
				top={"0"}
				zIndex={120}
				alignItems="center"
				justifyContent={{ base: "space-between", md: "flex-end" }}
				bg={bgColor}
				{...rest}
			>
				<IconButton
					display={{ base: "flex", md: "none" }}
					onClick={onOpen}
					bgColor={"transparent"}
					aria-label="open menu"
					icon={<FiMenu color="white" size={"50px"} />}
					_hover={{
						transform: "scale(1.2)",
					}}
				/>

				<Image
					src="/images/logo.png"
					alt="Logo"
					maxW="150px"
					onClick={() => {
						navigate("/");
						setUrlSportbook(false);
						setUrlNavigate(false);
					}}
					cursor="pointer"
				/>
				{!isLargerThanSm && (
					<>
						<Spacer />
						{isConnected ? <CurrencyMenu onOpenWallet={onOpenWallet} /> : <LoginMenuNav />}
						{user?.isAdmin && (
							<Button
								size="md"
								me={9}
								px={10}
								variant="solid"
								onClick={() => {
									navigate("/admin-panel");
									setUrlNavigate(true);
								}}
								bgGradient={"linear(to-br, #FF7A00, #FF5B27)"}
								_hover={{ bgGradient: "linear(to-br, #CC6709, #CC6709)" }}
							>
								ADMIN PANEL
							</Button>
						)}
					</>
				)}
			</Flex>
		</>
	);
};
