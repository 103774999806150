import { Modal, ModalOverlay, ModalContent, Center, Box, Text, Button } from "@chakra-ui/react";
import React from "react";

/**
 *
 * @name ImportantNotice
 * @description The ImportantNotice modal component.
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @returns {JSX.Element} The JSX Code for the ImportantNotice component
 * @version 1.0.0
 *
 */

export const ImportantNotice = ({ isOpen = false, onClose }) => {
	return (
		<Modal isOpen={isOpen} size={"md"} isCentered>
			<ModalOverlay />
			<ModalContent bgColor={"#2F2D2D"}>
				<Center display={"flex"} alignItems={"center"} justifyContent={"center"}>
					<Box bgColor="#2F2D2D" maxW={"md"} p={8} rounded="md">
						<Text color="white" fontSize={"sm"}>
							<span style={{ color: "#FF7A00", fontWeight: "bold" }}>Important Notice:</span> Deposit and
							withdrawal function currently disabled, sorry for the inconvenience.{" "}
							{/* Please carefully review and confirm the accuracy 
                        of the information provided, as transactions once initiated cannot be undone.
                        Ensure all details are correct before proceeding to avoid any unintended consequences.
                        Your attention to this matter is crucial for a smooth and error-free process. Thank you 
                        for your understanding and cooperation. */}
						</Text>
						<Box display={"flex"} justifyContent={"center"}>
							<Button
								mt={5}
								size={"lg"}
								variant={"t1-gradient"}
								_hover={{
									bgColor: "#FF7A00",
								}}
								color="white"
								bg={"#FF7A00"}
								onClick={() => {
									onClose();
								}}
								fontSize={"sm"}
							>
								Confirm
							</Button>
						</Box>
					</Box>
				</Center>
			</ModalContent>
		</Modal>
	);
};
