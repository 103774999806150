import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GameCardCasino } from "../../components/CardGames/GameCardCasino";
import { GameCardVideoGame } from "../../components/CardGames/GameCardVideoGame";
import { errorToast } from "../../utils/toast";
import { useToast } from "@chakra-ui/react";
import { GalleySliderImage } from "../../components/Dashboard/GalleySliderImage";
import { FiltersOptions } from "../../components/Dashboard/FiltersOptions";
import { LiveBettingCard } from "../../components/Dashboard/LiveBettingCard";
import { getGameMocks } from "../../services/blueOceanGame/getGamesMocks";
import { CardDashboard } from "../../components/CardGames/CardDashboard";

/**
 *
 * @name Dashboard
 * @description The dashboard page component
 * @returns {JSX.Element} The JSX Code for the Dashboard component
 * @version 1.0.0
 *
 */
const Dashboard = ({ setUrlSportbook }) => {
	const toast = useToast();
	const [gameList, setGameList] = useState([]);
	const [filter, setFilter] = useState("dashboard");
	const navigate = useNavigate();

	const navigateToGame = (gameName) => {
		navigate(`/${gameName}`);
	};

	useEffect(() => {
		getGameMocks()
			.then((response) => {
				setGameList(response);
			})
			.catch((error) => {
				errorToast(toast, "Connection error", error.message);
			});
	}, [toast]);

	return (
		<>
			<LiveBettingCard gameList={gameList} />
			<GalleySliderImage type={"dashboard"} isHidden={true} />
			<FiltersOptions setFilter={setFilter} filter={filter} />
			{filter === "dashboard" && <CardDashboard gameList={gameList} setUrlSportbook={setUrlSportbook} />}
			{filter === "all" && (
				<>
					<GameCardVideoGame onClick={() => navigateToGame("chess")} />
					<GameCardCasino gameList={gameList} filter={filter} />
				</>
			)}
			{!filter.includes("games") && filter !== "all" && filter !== "dashboard" && (
				<>
					<GameCardCasino gameList={gameList} filter={filter} />
				</>
			)}
			{filter.includes("games") && (
				<>
					<GameCardVideoGame onClick={() => navigateToGame("chess")} />
				</>
			)}
		</>
	);
};

export default Dashboard;
