import { Box, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { claimingChallengeExperience } from "../../services/users";
import { getMe } from "../../redux/reducers/AuthReducer";
import { errorToast, okToast } from "../../utils/toast";
import { iconMap } from "../../utils/utils";

export const CardChallengesFeatured = ({ challenge, allChallengesClaimToUser }) => {
	const IconComponent = iconMap[challenge?.icon];
	const dispatch = useDispatch();
	const toast = useToast();
	const isClaimed = allChallengesClaimToUser.includes(challenge?.challengeExperienceId);
	const claimingTheChallenge = async () => {
		try {
			await claimingChallengeExperience(challenge?.challengeExperienceId);
			dispatch(getMe());
			okToast(toast, "Challenge claimed successfully");
		} catch (error) {
			errorToast(toast, "Error when claiming challenge experience", error);
		}
	};

	return (
		<Flex
			flexDirection={"column"}
			justify={"center"}
			alignItems={"center"}
			gap={5}
			border={"2px solid darkGray"}
			borderRadius={10}
			p={5}
			w={"33%"}
		>
			<Icon as={IconComponent} w={"40px"} h={"40px"} />
			<Text>{challenge.name}</Text>
			<Box px={2} border={"2px solid orange"} borderRadius={10}>
				<Text fontWeight={"bold"}>{challenge.exp} XP</Text>
			</Box>
			<Text
				cursor={isClaimed ? "not-allowed" : "pointer"}
				color={isClaimed ? "lightGray" : "secundary"}
				decoration={isClaimed ? "none" : "underline"}
				_hover={{ color: !isClaimed && "white" }}
				onClick={() => claimingTheChallenge()}
			>
				{isClaimed ? "Claimed" : "Claim"}
			</Text>
		</Flex>
	);
};
