const { Text } = require("@chakra-ui/react");

const ChainWarning = ({ texto }) => {
    return (
        <Text
            my={2}
            border="1px"
            p={1}
            color="white"
            rounded="lg"
            bgColor={"blackAlpha.200"}
            fontSize="sm"
            textAlign="center"
            fontWeight="bold">
            {texto}
        </Text>
    );
};

export default ChainWarning;