import { Center, Flex, Image, Stack, Text } from "@chakra-ui/react";

/**
 * 
 * @name CurrencyBox
 * @description The currency box component
 * @param {Object} props The props for the CurrencyBox component
 * @param {String} props.currency The currency string
 * @param {Number} props.cryptoAmount The crypto amount number
 * @param {Number} props.usdtAmount The usdt amount number
 * @returns {JSX.Element} The JSX Code for the CurrencyBox component
 * @version 1.0.0
 * 
 */
const CurrencyOption = ({currencyValue, currencyName, currencyImage, setSelectedCurrency = false , isDisabled = false }) => {
    if (!setSelectedCurrency)
        return (
            <option
                value={currencyValue}
                style={{
                    background: "#413D53",
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                }}>
                <Flex gap={2}>
                    <Image src={currencyImage} alt={currencyName} boxSize="15px" />
                    {currencyName}
                </Flex>
            </option>
        );

    return (
        <Stack
            bgColor={isDisabled ? "rgba(7,7,7,0.15)" : "rgba(7,7,7,0.3)"}
            p={8}
            rounded={"lg"}
            onClick={() => !isDisabled ? setSelectedCurrency(currencyValue) : null}
            _hover={{ bgColor: "rgba(7,7,7,0.45)", cursor: isDisabled ? "not-allowed" : "pointer" }}>
            <Center>
                <Image src={currencyImage} maxW={"100px"} />
            </Center>
            <Text color="white" textAlign="center">
                {currencyName}
            </Text>
        </Stack>
    );
};

export default CurrencyOption;
