import axios from "axios";

/**
 *
 * @name getLatestWinGames
 * @description Request the list of wins in diferent games.
 * @returns {Array} list of all wins in diferent games.
 *
 */
export const getLatestWinGames = async (numberCard) => {
	try {
		const number = numberCard > 14 ? 14 : numberCard;
		const response = await axios.get(`/game/latestWinners/${number}`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
