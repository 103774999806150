/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Heading,
	Icon,
	Image,
	Spacer,
	Stack,
	Text,
	Tooltip,
	VStack,
	useSteps,
	useToast,
} from "@chakra-ui/react";
import "../../theme/customScrollTheme.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useEffect, useState } from "react";
import {
	getAllChallengesExperience,
	getAllLevels,
	getAllReferredUsers,
} from "../../services/adminPanel/AdminPanelServices";
import { CardChallenges } from "../../components/UserEarnXp/CardChallenges";
import { CardChallengesFeatured } from "../../components/UserEarnXp/CardChallengesFeatured";
import { useDispatch, useSelector } from "react-redux";
import { ProgressLvlNav } from "../../components/UserEarnXp/subcomponents/ProgressLvlNav";
import { getAllUserOrderByExp, getChallengesExperienceClaimToUser } from "../../services/users";
import { CardUserOrderByExp } from "../../components/UserEarnXp/CardUserOrderByExp";
import { getMe } from "../../redux/reducers/AuthReducer";
import { errorToast, okToast } from "../../utils/toast";
import { IoIosLink } from "react-icons/io";

export const UserEarnXp = () => {
	const { user } = useSelector((state) => state.auth);
	const URL_REFERRAL_CODE = `https://app.bitbet.gg/?referredTo=${user?.referralCode}`;
	const toast = useToast();
	const dispatch = useDispatch();
	const [challenges, setChallenges] = useState([]);
	const [usersOrderByExp, setUsersOrderByExp] = useState([]);
	const [myPositionIsRanked, setMyPositionIsRanked] = useState(0);
	const [allChallengesClaimToUser, setAllChallengesClaimToUser] = useState([]);
	const [startingPositionFeatured, setStartingPositionFeatured] = useState(0);
	const [countReferredUSers, setCountReferredUSers] = useState(0);
	const [totalWageredUSD, setTotalWageredUSD] = useState(0);
	const { activeStep, setActiveStep } = useSteps(0);
	const [steps, setSteps] = useState([]);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [numberCard, setNumberCard] = useState(parseInt((screenWidth * 70) / 100 / 300));

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			setNumberCard(parseInt((window.innerWidth * 70) / 100) / 300);
		};

		window.addEventListener("resize", handleResize);

		// Limpieza del event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [screenWidth]);

	const allChallengesExperience = async (isReload = false) => {
		const [allChallenges, orderUserByExp, countReferredUSers] = await Promise.all([
			getAllChallengesExperience(),
			getAllUserOrderByExp(),
			getAllReferredUsers(),
		]);
		const featuredChallenges = allChallenges.filter((challenge) => challenge.isFeatured);
		const notFeaturedChallenges = allChallenges.filter((challenge) => !challenge.isFeatured);

		setChallenges({
			featured: featuredChallenges,
			notFeatured: notFeaturedChallenges,
		});
		setUsersOrderByExp(orderUserByExp?.users);
		setMyPositionIsRanked(orderUserByExp?.rank);
		setCountReferredUSers(countReferredUSers.referred);
		setTotalWageredUSD(countReferredUSers.totalWagered);
		isReload && dispatch(getMe());
	};

	const copyClipboardReferralCode = async () => {
		try {
			await navigator.clipboard.writeText(URL_REFERRAL_CODE);
			okToast(toast, "The link has been copied to the clipboard.");
		} catch (error) {
			errorToast(toast, " The link could not be copied to the clipboard.", error.message);
		}
	};

	const calculatelevel = (levels, exp) => {
		let lvl = 0;
		levels?.forEach((level) => {
			if (exp >= level?.exp) {
				lvl = level?.level;
			}
		});

		return lvl;
	};

	const generateSteps = async (levels, userLevel) => {
		let finallySteps = [];
		const lastSteps = levels.length;

		switch (userLevel) {
			case 0:
				finallySteps = [{ index: 0, level: 0, exp: 0 }, ...levels.slice(userLevel, 6)];
				setActiveStep(1);
				break;
			case 1:
				finallySteps = [{ index: 0, level: 0, exp: 0 }, ...levels.slice(userLevel - 1, 6)];
				setActiveStep(2);
				break;
			case lastSteps:
				finallySteps = levels.slice(userLevel - 7, userLevel);
				setActiveStep(7);
				break;
			case lastSteps - 1:
				finallySteps = levels.slice(userLevel - 6, userLevel + 1);
				setActiveStep(6);
				break;
			case lastSteps - 2:
				finallySteps = levels.slice(userLevel - 5, userLevel + 2);
				setActiveStep(5);
				break;
			case lastSteps - 3:
				finallySteps = levels.slice(userLevel - 4, userLevel + 3);
				setActiveStep(4);
				break;
			default:
				finallySteps = levels.slice(userLevel - 3, userLevel + 4);
				setActiveStep(3);
				break;
		}

		return finallySteps;
	};

	const allLevels = async () => {
		const levels = await getAllLevels();
		const level = calculatelevel(levels, user?.exp);
		const steps = await generateSteps(levels, level);

		setSteps(steps);
	};

	useEffect(() => {
		allChallengesExperience();
	}, []);

	useEffect(() => {
		const challengesExperienceClaim = async () => {
			const challengesExperienceClaim = await getChallengesExperienceClaimToUser();
			setAllChallengesClaimToUser(challengesExperienceClaim);
		};

		challengesExperienceClaim();
		allLevels();
	}, [user]);

	return (
		<>
			<Flex gap={5}>
				<VStack w={"70%"}>
					<VStack
						w={"100%"}
						p={5}
						gap={10}
						bgColor={"darkOscure"}
						borderRadius={{ base: "0px", md: "10px" }}
						justify={"space-between"}
					>
						<HStack w={"100%"} justify={"space-between"}>
							<Heading w={"100%"} color={"white"} fontSize={"2xl"} letterSpacing={3}>
								My XP Dashboard
							</Heading>
							<Tooltip
								label={
									<div>
										<span style={{ textDecoration: "underline" }}>
											Referrals will be added when:
										</span>{" "}
										<br />
										- Register with your referral code. <br />
										- Make 1 claim. <br />- Win 1500 EXP.
									</div>
								}
								aria-label="A tooltip"
								bgColor={"secundary"}
								borderRadius={5}
								p={5}
							>
								<Button
									variant={"solidOrange"}
									color={"white"}
									_hover={{ color: "darkGray" }}
									leftIcon={<IoIosLink />}
									px={10}
									onClick={copyClipboardReferralCode}
								>
									Invite Friends
								</Button>
							</Tooltip>
						</HStack>

						<HStack w={"100%"} gap={5} justify={"space-between"}>
							<Box w={"60%"} h={"100%"} bg={"secundary"} borderRadius={10}>
								<VStack p={3} h={"100%"}>
									<Box w={"full"}>
										<Image src="/images/bitbetSidebar.png" rounded={"full"} boxSize={10} />
									</Box>
									<Spacer />
									<VStack w={"full"} gap={0} justify={"end"} alignItems={"end"}>
										<Heading color={"white"}>{user?.exp} XP</Heading>
										<Text fontSize={"xs"}>The more you play the more you earn</Text>
									</VStack>
								</VStack>
							</Box>
							<VStack w={"35%"} gap={5} alignItems={"start"}>
								<VStack gap={0} alignItems={"start"}>
									<Heading color={"white"} fontSize={"xl"}>
										$ {totalWageredUSD}
									</Heading>
									<Text>Total wagered USD</Text>
								</VStack>
								<VStack gap={0} alignItems={"start"}>
									<Heading color={"white"} fontSize={"xl"}>
										{countReferredUSers}
									</Heading>
									<Text>Total referrals</Text>
								</VStack>
								<VStack gap={0} alignItems={"start"}>
									<Heading color={"white"} fontSize={"xl"}>
										Coming soon
									</Heading>
									<Text>First transaction date</Text>
								</VStack>
							</VStack>
						</HStack>
						<ProgressLvlNav steps={steps} activeStep={activeStep} />
					</VStack>
					<VStack w={"100%"} gap={5} bgColor={"darkOscure"} borderRadius={{ base: "0px", md: "10px" }}>
						<HStack w={"100%"} p={5} justify={"space-between"}>
							<Heading color={"white"} fontSize={"2xl"} letterSpacing={3}>
								Activities
							</Heading>

							<Button
								p={6}
								variant={"solidOrange"}
								color={"white"}
								_hover={{ color: "darkGray" }}
								onClick={() => {
									allChallengesExperience(true);
									allLevels();
								}}
							>
								Refresh
							</Button>
							{/* <Button
								bg={"transparent"}
								color={"white"}
								_hover={{ bg: "transparent", color: "secundary" }}
							>
								View All
							</Button> */}
						</HStack>

						<VStack px={5} gap={5} w={"100%"} alignItems={"start"}>
							<Heading fontSize={"xl"} letterSpacing={3}>
								Featured
							</Heading>
							<HStack gap={5} w={"100%"}>
								{challenges?.featured
									?.slice(startingPositionFeatured, startingPositionFeatured + numberCard)
									.map((challenge) => (
										<CardChallengesFeatured
											key={challenge?.challengeExperienceId}
											challenge={challenge}
											allChallengesClaimToUser={allChallengesClaimToUser}
										/>
									))}
							</HStack>
						</VStack>

						<HStack w={"100%"} justify={"end"} px={5}>
							<Button
								variant={"featuredExperience"}
								isDisabled={startingPositionFeatured === 0}
								onClick={() => setStartingPositionFeatured(startingPositionFeatured - 1)}
							>
								<Icon as={BsChevronLeft} />
							</Button>
							<Button
								variant={"featuredExperience"}
								onClick={() => setStartingPositionFeatured(startingPositionFeatured + 1)}
								isDisabled={startingPositionFeatured + numberCard >= challenges?.featured?.length}
							>
								<Icon as={BsChevronRight} />
							</Button>
						</HStack>

						<VStack
							bg={"darkGray"}
							gap={5}
							p={5}
							alignItems={"center"}
							w={"100%"}
							h={"450px"}
							borderRadius={10}
							overflow="auto"
							className="custom-scrollbar"
						>
							{challenges?.notFeatured?.map((challenge) => (
								<CardChallenges
									key={challenge?.challengeExperienceId}
									challenge={challenge}
									allChallengesClaimToUser={allChallengesClaimToUser}
								/>
							))}
						</VStack>
					</VStack>
				</VStack>
				<Flex w={"30%"} flexDirection={"column"} gap={5}>
					<Stack
						gap={5}
						w={"100%"}
						h={"1282px"}
						p={5}
						bgColor={"darkOscure"}
						borderRadius={{ base: "0px", md: "10px" }}
					>
						<Heading p={"0 0 0 20px"} color={"white"} fontSize={"2xl"} letterSpacing={3}>
							Leaderboard
						</Heading>
						<VStack p={5} overflow="auto" className="custom-scrollbar">
							{usersOrderByExp?.map((user, index) => (
								<CardUserOrderByExp key={index} user={user} index={index + 1} />
							))}
						</VStack>
						<VStack py={0} px={5}>
							<Divider mb={3} borderColor={"secundary"} border={"3px solid secundary"} />
							<CardUserOrderByExp user={user} index={myPositionIsRanked} />
						</VStack>
					</Stack>
					{/* <Box w={"100%"} p={10} bgColor={"darkOscure"} borderRadius={{ base: "0px", md: "10px" }}>
						<HStack justify={"space-between"}>
							<VStack>
								<Heading color={"white"} fontSize={"2xl"} letterSpacing={3}>
									Invite Friends
								</Heading>
								<Text fontSize={"xs"}>Get 1000 XP for each friend!</Text>
							</VStack>
						</HStack>
					</Box> */}
				</Flex>
			</Flex>
		</>
	);
};
