import { Center, Stack, Text } from "@chakra-ui/react";
import React from "react";

export const InfoItem = ({ label, value, chessUser }) => {
	return (
		<Center w="100%" p={4} rounded="lg" border="1px solid #FF7A00" shadow={"2xl"}>
			<Stack direction={"column"} textAlign={"center"} w="100%">
				<Text fontSize="2xl" fontWeight="bold" color="white">
					{label}
				</Text>
				<Text fontSize="xl" fontWeight="bold" color="white" textTransform={"uppercase"}>
					{value}
				</Text>
				{chessUser && (
					<>
						<Text fontSize="xl" fontWeight="bold" color="white">
							Chess username: {chessUser}
						</Text>
					</>
				)}
			</Stack>
		</Center>
	);
};
