import { Avatar, Box, Divider, Spacer, Stack } from "@chakra-ui/react";
import LoginModal from "../../Modals/Login/Login";
import { MenuItemWithIcon } from "./MenuItemWithIcon";

// import { FaChartBar } from "react-icons/fa";
import { FiDribbble, FiLogIn, FiSettings, FiUser, FiTrendingUp } from "react-icons/fi";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineCasino } from "react-icons/md";
import { TbTable } from "react-icons/tb";
// import { TfiWallet } from "react-icons/tfi";
import { BsAward } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

/**
 *
 * @name Sidebar
 * @description The sidebar component
 * @param {Object} props The props for the Sidebar component
 * @param {Function} props.onClose The onClose function
 * @param {Boolean} props.isConnected The isConnected boolean
 * @returns {JSX.Element} The JSX Code for the Sidebar component
 * @version 1.0.0
 *
 */
export const Sidebar = ({
	onClose,
	onOpenWallet,
	isConnected,
	setOnSidebarExtend,
	onOpenProfileModal,
	setUrlSportbook,
	setFilter,
	urlSportbook,
	urlUserXp,
	setUrlUserXp,
	...rest
}) => {
	const navigate = useNavigate();

	return (
		<Box transition="3s ease" w={{ base: "full", md: "auto" }} pos="fixed" h="full" {...rest}>
			<Stack
				height={"calc(100dvh - 100px)"}
				padding={"10px"}
				spacing={0}
				align="center"
				top={"80px"}
				pos="fixed"
				mx={2}
				bgColor={"#FF7A00"}
				borderRadius={urlSportbook || urlUserXp ? "10px 0px 10px 10px" : "10px"}
			>
				<Avatar
					borderRadius={"10px"}
					mb={1}
					name="BitBet"
					src="/images/bitbetSidebar.png"
					cursor={"pointer"}
					onClick={() => navigate("/")}
				/>
				<MenuItemWithIcon icon={<FiLogIn />} onClick={() => setOnSidebarExtend(true)} />
				<Divider width={"50%"} my={1} />

				<MenuItemWithIcon
					icon={<BsAward />}
					onClick={() => {
						navigate("/earn-xp");
						setUrlSportbook(false);
						setUrlUserXp(true);
					}}
				>
					Earn XP
				</MenuItemWithIcon>

				<Divider width={"50%"} my={1} />

				<MenuItemWithIcon
					icon={<IoGameControllerOutline />}
					onClick={() => {
						navigate("/video-games");
						setUrlUserXp(false);
						setUrlSportbook(false);
					}}
				>
					Games
				</MenuItemWithIcon>
				<MenuItemWithIcon
					icon={<MdOutlineCasino />}
					onClick={() => {
						navigate("/casino-games");
						setFilter("all");
						setUrlSportbook(false);
						setUrlUserXp(false);
					}}
				>
					Casino
				</MenuItemWithIcon>
				<MenuItemWithIcon
					icon={<FiDribbble />}
					onClick={() => {
						navigate("/sport-games");
						setUrlSportbook(true);
						setUrlUserXp(false);
					}}
				>
					Sports
				</MenuItemWithIcon>
				<MenuItemWithIcon icon={<FiTrendingUp />} isDisabled>
					Trading
				</MenuItemWithIcon>

				<Spacer />
				{isConnected && (
					<>
						<MenuItemWithIcon
							icon={<TbTable />}
							onClick={() => {
								navigate("/profile");
							}}
						>
							Dashboard
						</MenuItemWithIcon>

						{/* <MenuItemWithIcon icon={<TfiWallet />} onClick={onOpenWallet}>
							Wallet
						</MenuItemWithIcon> */}
						{/* <MenuItemWithIcon
							icon={<FaChartBar />}
							onClick={() => {
								navigate("/profile");
							}}
						>
							Stats
						</MenuItemWithIcon> */}
						<MenuItemWithIcon icon={<FiSettings />} onClick={onOpenProfileModal}>
							Setting
						</MenuItemWithIcon>
					</>
				)}

				<Stack align={"center"}>
					<Divider mb={2} width={"50%"} />

					{isConnected && <Avatar name="BitBet" src="/images/profile_image.png" />}

					{!isConnected && (
						<MenuItemWithIcon icon={<FiUser />} Modal={LoginModal}>
							Login / Register
						</MenuItemWithIcon>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};
