import axios from "axios";

/**
 * @name allUsers
 * @description Fetches all users
 * @returns {object} The users data
 *
 */
export const allUsers = async (offset) => {
	try {
		const response = await axios.get(`/users/all/${offset}`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name updateProfile
 * @description Updates the user profile
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const updateProfile = async (data) => {
	try {
		const response = await axios.post("/users/updateProfile", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name updateUserEmail
 * @description Updates the user email
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const updateUserEmail = async (data) => {
	try {
		const response = await axios.post("/users/update/email", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name updateUserPublicName
 * @description Updates the user public name
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const updateUserPublicName = async (data) => {
	try {
		const response = await axios.post("/users/update/publicname", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name updateUserReferralCode
 * @description Updates the user referral code
 * @param {object} data The data object
 * @returns {object} The user data
 */
export const updateUserReferralCode = async (data) => {
	try {
		const response = await axios.post("/users/update/referralCode", data);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name updateUserPassword
 * @description Updates the user password
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const updateUserPassword = async (data) => {
	try {
		const response = await axios.post("/users/update/password", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name createUser
 * @description Creates a new user
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const createUser = async (data) => {
	try {
		const response = await axios.post("/users/create", data);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name verifyCode
 * @description Verifies the user
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const verifyCode = async (data) => {
	try {
		const response = await axios.post("/users/code/verify", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

let isWithdrawing = false;

/**
 *
 * @name withdraw
 * @description Withdraws the user
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const withdraw = async ({ amount, wallet, currency }) => {
	if (isWithdrawing) return;
	isWithdrawing = true;
	try {
		const response = await axios.post("/users/withdraw", { amount, wallet, currency });
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	} finally {
		isWithdrawing = false;
	}
};

/**
 *
 * @name checkUserCodeTime
 * @description Checks the user code time
 * @returns {object} The user data
 *
 */
export const checkUserCodeTime = async (data) => {
	try {
		const response = await axios.post("/users/code/check", data);
		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name sendUserCode
 * @description Sends the user code
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const sendUserCode = async (data) => {
	try {
		const response = await axios.post("/users/code", data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name addAirdropBalance
 * @description Adds the airdrop balance
 * @param {string} username The username
 * @returns response
 *
 */
export const addAirdropBalance = async (username) => {
	try {
		const response = await axios.patch(`/user/airdrop/${username}`);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAirdropBalance
 * @description Get all users order by experience
 * @retunrs {object} The all users order by experience
 */
export const getAllUserOrderByExp = async () => {
	try {
		const response = await axios.get("/user/allOrderByExp");

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name claimingChallengeExperience
 * @description Claiming the challenge experience
 * @returns {boolean}
 */
export const claimingChallengeExperience = async (challengeExperienceId) => {
	try {
		const response = await axios.post("/challengeExperience/assingToUser", { challengeExperienceId });
		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getChallengesExperienceClaimToUser
 * @description Get the challenges experience claim to user
 * @returns {object} The challenges experience claim to user
 */
export const getChallengesExperienceClaimToUser = async () => {
	try {
		const response = await axios.get("/challengeExperience/claimToUser");

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
