import { Spinner } from '@chakra-ui/react'

/**
 *
 * @name LoadingSpinner
 * @description The component that jumps while loading API calls arrive
 * @returns {JSX.Element} The JSX Code for the CurrencyMenu component
 * @author saguado | All-in-one Blockchain Company
 * @version 1.0.0
 *
 */
export const LoadingSpinner = () => {
  return (
    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#FF7A00" size="xl" />
  )
}
