import { Avatar, Box, Divider, Stack } from "@chakra-ui/react";
import React from "react";
import { MenuItemWithIcon } from "./MenuItemWithIcon";
import { BiBarChart } from "react-icons/bi";
import { SiLevelsdotfyi } from "react-icons/si";
import { FiFile, FiGrid, FiLogIn, FiUsers } from "react-icons/fi";

import { useNavigate } from "react-router-dom";

export const SidebarAdminPanel = ({ isConnected, setOnSidebarExtend, ...rest }) => {
	const navigate = useNavigate();

	return (
		<Box transition="3s ease" w={{ base: "full", md: "auto" }} pos="fixed" h="full" {...rest}>
			<Stack
				height={"calc(100dvh - 100px)"}
				padding={"10px"}
				spacing={0}
				align="center"
				top={"80px"}
				pos="fixed"
				mx={2}
				bgColor={"#FF7A00"}
				borderRadius={"10px"}
			>
				<Avatar borderRadius={"10px"} mb={1} name="BitBet" src="/images/bitbetSidebar.png" />
				<MenuItemWithIcon icon={<FiLogIn />} onClick={() => setOnSidebarExtend(true)} />
				<Divider width={"50%"} my={1} />

				<MenuItemWithIcon
					icon={<FiGrid />}
					onClick={() => {
						navigate("/admin-panel");
					}}
				>
					Dashboard
				</MenuItemWithIcon>
				<MenuItemWithIcon
					icon={<BiBarChart />}
					onClick={() => {
						navigate("/admin-panel/challenges");
					}}
				>
					Challenges
				</MenuItemWithIcon>
				<MenuItemWithIcon
					icon={<SiLevelsdotfyi />}
					onClick={() => {
						navigate("/admin-panel/levels");
					}}
				>
					Levels
				</MenuItemWithIcon>
				<MenuItemWithIcon
					icon={<FiUsers />}
					onClick={() => {
						navigate("/admin-panel/users");
					}}
				>
					Users
				</MenuItemWithIcon>
				<MenuItemWithIcon
					icon={<FiFile />}
					onClick={() => {
						navigate("/admin-panel/analytics");
					}}
				>
					Analytics
				</MenuItemWithIcon>
			</Stack>
		</Box>
	);
};
