import { useEffect, useState } from "react";
import "../../theme/customScrollTheme.css";
import { HeaderAllPage } from "../../components/AdminPanel/HeaderAllPage";
import {
	Button,
	FormLabel,
	HStack,
	Heading,
	Input,
	InputGroup,
	Radio,
	RadioGroup,
	Stack,
	Text,
	VStack,
	useToast,
} from "@chakra-ui/react";
import { errorToast, okToast } from "../../utils/toast";
import { createLevels, deleteLevels, getAllLevels } from "../../services/adminPanel/AdminPanelServices";

const initializeCreateLevels = {
	quantity: 0,
	option: "porcent",
	numberToCreate: 0,
};

const initializeDeleteLevels = {
	numberToDelete: 0,
};

export const LevelsAdminPanel = () => {
	const [value, setValue] = useState("1");
	const [inputCreateLevels, setInputCreateLevels] = useState(initializeCreateLevels);
	const [inputDeleteLevels, setInputDeleteLevels] = useState(initializeDeleteLevels);
	const [allLevels, setAllLevels] = useState([]);
	const [isReload, setIsReload] = useState(false);
	const toast = useToast();

	useEffect(() => {
		const allLevels = async () => {
			const levels = await getAllLevels();
			setAllLevels(levels);
		};

		allLevels();
	}, [isReload]);

	const handlerChangeLevels = (e) => {
		if (e.target.name === "numberToDelete") {
			setInputDeleteLevels({ ...inputDeleteLevels, [e.target.name]: e.target.value });
		}

		setInputCreateLevels({
			...inputCreateLevels,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = async () => {
		try {
			if (inputCreateLevels?.numberToCreate <= 0) {
				return errorToast(toast, "Error when creating levels", "The quantity must be greater than 0");
			}

			if (inputCreateLevels?.quantity <= 0) {
				if (inputCreateLevels?.option === "1") {
					return errorToast(toast, "Error when creating levels", "The porcent must be greater than 0");
				} else {
					return errorToast(toast, "Error when creating levels", "The amount exp must be greater than 0");
				}
			}

			await createLevels(inputCreateLevels);
			setIsReload(!isReload);
			return okToast(toast, "Levels created", "Levels have been created correctly");
		} catch (error) {
			return errorToast(toast, "Error when creating levels");
		}
	};

	const onDelete = async () => {
		try {
			if (inputDeleteLevels?.numberToDelete <= 0) {
				errorToast(toast, "Error when deleting levels", "The quantity must be greater than 0");
				return;
			}

			await deleteLevels(inputDeleteLevels);
			setIsReload(!isReload);
			return okToast(toast, "Levels eliminated", "Levels have been removed correctly");
		} catch (error) {
			return errorToast(toast, "Error when deleting levels", error);
		}
	};

	return (
		<>
			<HeaderAllPage title={"Levels"} />
			<Stack flexDirection={{ base: "column", md: "row" }}>
				<Stack
					flexDirection={"column"}
					bgColor={"darkGray"}
					borderRadius={5}
					w={"full"}
					p={5}
					gap={5}
					justify={"space-between"}
					alignItems={"center"}
				>
					<Heading>Create levels</Heading>
					<VStack gap={5}>
						<InputGroup display={"flex"} flexDirection={"column"}>
							<FormLabel textAlign={"center"}>
								How many levels do you want to <span style={{ color: "#FF7A00" }}>create</span>?
							</FormLabel>
							<Input
								variant={"bitbet"}
								type="number"
								name="numberToCreate"
								value={inputCreateLevels?.quantityCreate}
								onChange={handlerChangeLevels}
							/>
						</InputGroup>

						<RadioGroup
							colorScheme="orange"
							onChange={(e) => {
								setInputCreateLevels({
									...inputCreateLevels,
									option: e === "1" ? "porcent" : "exp",
								});
								setValue(e);
							}}
							value={value}
							defaultValue="1"
							w={"100%"}
						>
							<Stack direction="row" justify={"space-around"}>
								<Radio value="1">Porcent</Radio>
								<Radio value="2">Experience</Radio>
							</Stack>
						</RadioGroup>
						<InputGroup display={"flex"} flexDirection={"column"}>
							<Input
								variant={"bitbet"}
								type="number"
								name="quantity"
								value={inputCreateLevels?.experience}
								onChange={handlerChangeLevels}
							/>
						</InputGroup>
					</VStack>
					<Button variant={"solidOrange"} onClick={onSubmit}>
						Create
					</Button>
				</Stack>
				<Stack
					flexDirection={"column"}
					bgColor={"darkGray"}
					borderRadius={5}
					w={"full"}
					p={5}
					gap={5}
					justify={"space-between"}
					alignItems={"center"}
				>
					<Heading>Delete levels</Heading>

					<InputGroup display={"flex"} flexDirection={"column"}>
						<FormLabel textAlign={"center"}>
							How many levels you want to <span style={{ color: "#FF7A00" }}>eliminate</span>?
						</FormLabel>
						<Input
							variant={"bitbet"}
							type="number"
							name="numberToDelete"
							value={inputDeleteLevels?.quantityDelete}
							onChange={handlerChangeLevels}
						/>
					</InputGroup>

					<Button variant={"solidOrange"} onClick={onDelete}>
						Delete
					</Button>
				</Stack>
				<Stack
					flexDirection={"column"}
					bgColor={"darkGray"}
					borderRadius={5}
					w={"full"}
					p={5}
					gap={5}
					alignItems={"center"}
				>
					<VStack>
						<Heading>Levels list</Heading>
						<Text m={0}>
							Total current levels <span style={{ color: "#FF7A00" }}>{allLevels?.length}</span>
						</Text>
					</VStack>
					<VStack w={"full"} h={"225px"} gap={3} overflow="auto" className="custom-scrollbar">
						{allLevels?.map((level) => (
							<HStack w={"100%"} key={level?.id}>
								<Text>lvl {level?.level}</Text>
								<Text>-</Text>
								<Text>{level?.exp} exp</Text>
							</HStack>
						))}
					</VStack>
				</Stack>
			</Stack>
		</>
	);
};
