import { IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

/**
 *
 * @name MenuItemWithIcon
 * @description The menu item with icon component
 * @param {Object} props The props for the MenuItemWithIcon component
 * @param {Object} props.icon The icon object
 * @param {Object} props.children The children object
 * @param {Object} props.Modal The Modal object
 * @returns {JSX.Element} The JSX Code for the MenuItemWithIcon component
 * @version 1.0.0
 *
 */
export const MenuItemWithIcon = ({ icon, children, Modal, ...rest }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [isPushed, setIsPushed] = useState(false);

	const normalHover = {
		bgGradient: "linear(to-br, #CC6709, #CC6709)",
	};

	const pushedHover = {
		bgGradient: "linear(to-br, #794515, #794515)",
	};

	if (Modal) {
		return (
			<>
				<Tooltip label={children} aria-label={children} placement="right" bgColor="orange" color="black">
					<IconButton
						size="md"
						aria-label={children}
						icon={icon}
						{...rest}
						onClick={onOpen}
						bgColor={"#FF7A00"}
						_hover={isPushed ? pushedHover : normalHover}
						onMouseDown={() => setIsPushed(true)}
						onMouseUp={() => setIsPushed(false)}
					/>
				</Tooltip>
				<Modal isOpen={isOpen} onClose={onClose} />
			</>
		);
	} else {
		return (
			<Tooltip label={children} aria-label={children} placement="right" bgColor="orange" color="black">
				<IconButton
					size="md"
					aria-label={children}
					icon={icon}
					{...rest}
					bgColor={"#FF7A00"}
					_hover={{ bgGradient: "linear(to-br, #CC6709, #CC6709)" }}
				/>
			</Tooltip>
		);
	}
};
