import { useEffect, useState } from "react"
import { getALLMyChallenges } from "../../services/challenges/challenges"
import { Box, Heading, IconButton, Spacer, Stack, Tooltip, useToast } from "@chakra-ui/react"
import { errorToast } from "../../utils/toast"
import ChallengeTable from "../../components/Challenges/ChallengeTable"
import { FiChevronsLeft } from "react-icons/fi"
import { useNavigate } from "react-router-dom"

const MyChallenges = () => {

    const toast = useToast()

    const [challenges, setChallenges] = useState([])
    const [needReload, setNeedReload] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        const handleGetMyChallenges = async () => {
            try {
                const response = await getALLMyChallenges()
                setChallenges(response)
            } catch (error) {
                errorToast(toast, 'Error getting challenges', error.message)
            } finally {
                setNeedReload(false)
            }
        }

        needReload && handleGetMyChallenges()
    }, [needReload, toast])

    return (
        <>
            <Stack
                direction="column"
                width="100%"
                padding={4}
                spacing={4}
                justifyContent="space-between"
                
            >
                <Box display={'flex'} alignItems={'center'}>
                    <Tooltip label='Go back' placement="bottom" bgColor="orange" color="black">          
                        <IconButton
                            icon={<FiChevronsLeft style={{width:'50px', height:'50px'}}/>}
                            onClick={() => {navigate('/chess')}}
                            bgColor={'transparent'}
                            color={'secundary'}
                            transition={'0.5s'}
                            _hover={{
                                transform: 'scale(1.2)',
                            }}
                        />
                    </Tooltip>
                    <Heading textColor={'white'} textAlign={'left'}>
                        My challenges
                    </Heading>
                </Box>

                <Spacer />

                <ChallengeTable
                    challenges={challenges}
                    setNeedReload={setNeedReload}
                />
            </Stack>

        </>
    )
}

export default MyChallenges