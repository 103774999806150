import { 
    Box,
    Button,
    FormControl, 
    Heading, 
    Input, 
    Stack, 
    Text, 
    useToast, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
} from "@chakra-ui/react";

import { useState } from "react";
import { okToast } from "../../../utils/toast";
import { forgotPassword } from "../../../utils/passwords";
import TwoStepVerification from "../TwoStepVerification/TwoStepVerification";
import { useDisclosure } from "@chakra-ui/react";

/**
 * 
 * @name ForgotPassword
 * @description The forgot password modal component
 * @returns {JSX.Element} The JSX Code for the ForgotPassword component
 * @author saguado | All-in-one Blockchain Company
 * @version 1.0.0
 * 
 */

export const ForgotPasswordModal = ({isOpen = false, onCloseForgot}) => {

    const toast = useToast()
    const [email, setEmail] = useState("")

    const { isOpen: isOpenVerification, onOpen: onOpenVerification, onClose: onCloseVerification } = useDisclosure();

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleForgotPassword = async (email) => {
        try{
            const response = await forgotPassword(email);
            okToast(toast, 'Password reset', response.message)
            onOpenVerification();

        } catch(error) {
            okToast(toast, 'Enviado un nuevo codigo de verificación', error.message)
            onOpenVerification();
        }
    }

    const handleClose = () => {
        setEmail("");
        onCloseForgot();
    };

  return (
    <>
        <Modal isOpen={isOpen} size={"lg"} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={"#2F2D2D"} >
                <Heading mt={4} px={8}>
                    Forgot your password?
                </Heading>
                <Text fontSize={{ base: "sm", sm: "md" }} px={8} pt={8} color="white">
                    You&apos;ll get an email with a reset link
                </Text>
                <ModalCloseButton onClick={onCloseForgot} color="white" />
                <Stack spacing={8} mx={"auto"} maxW={"lg"}>
                    <Box rounded={"lg"} boxShadow={"lg"} minW={"lg"} p={8}>
                        <Stack spacing={2}>
                            <FormControl id="email">
                                <Input 
                                    placeholder="your-email@example.com" 
                                    _placeholder={{ color: "gray.500" }} 
                                    type="email" 
                                    value={email}
                                    onChange={handleEmailChange}
                                    color={'white'}
                                />
                            </FormControl>
                            <Stack spacing={10}>
                                <Stack spacing={2}>
                                    <Button
                                        bg={"blue.400"}
                                        color={"white"}
                                        _hover={{
                                            bg: "blue.500",
                                        }}
                                        onClick={() => handleForgotPassword(email)}
                                    >
                                        Request reset password
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </ModalContent>
        </Modal>
        <TwoStepVerification 
        isOpen = {isOpenVerification} 
        email = {email} 
        onClose = {onCloseVerification} 
        onCloseForgot = {onCloseForgot}
        />
    </>
  )
}
