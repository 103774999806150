import { Button, Flex, HStack, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const FiltersOptions = ({ setFilter, filter = [] }) => {
	const [statusFilter, setStatusFilter] = useState(false);
	const [isLargerThanMd] = useMediaQuery("(max-width: 767px)");
	useEffect(() => {
		const currentURL = window.location.href;
		if (currentURL.includes("casino-games")) {
			setStatusFilter(true);
		} else {
			setStatusFilter(false);
		}
	}, []);

	return (
		<Flex flexDirection={"column"} justifyContent={isLargerThanMd ? "center" : "left"} my={10} gap={2}>
			{!statusFilter && (
				<HStack>
					<>
						<Button variant={"tags"} onClick={() => setFilter("dashboard")}>
							All
						</Button>
						<Button variant={"tags"} onClick={() => setFilter(["games"])}>
							Games
						</Button>
						<Button variant={"tags"} onClick={() => setFilter("casino")}>
							Casino
						</Button>
					</>
				</HStack>
			)}
			{(filter === "casino" ||
				filter === "all" ||
				filter.includes("live-casino-table") ||
				filter.includes("livecasino") ||
				filter.includes("scratch-cards") ||
				filter.includes("video-slots") ||
				filter.includes("crash-games") ||
				filter.includes("table-games") ||
				filter.includes("virtual-games") ||
				statusFilter) && (
				<HStack>
					<Button variant={"tags"} onClick={() => setFilter(statusFilter ? "all" : "casino")}>
						All
					</Button>
					<Button variant={"tags"} onClick={() => setFilter(["live-casino-table", "livecasino"])}>
						Live Casino
					</Button>
					<Button variant={"tags"} onClick={() => setFilter(["scratch-cards"])}>
						Scrath Cards
					</Button>
					<Button variant={"tags"} onClick={() => setFilter(["video-slots"])}>
						Slots
					</Button>
					<Button variant={"tags"} onClick={() => setFilter(["crash-games"])}>
						Crash
					</Button>
					<Button variant={"tags"} onClick={() => setFilter(["table-games"])}>
						Table games
					</Button>
					<Button variant={"tags"} onClick={() => setFilter(["virtual-games"])}>
						Virtual games
					</Button>
				</HStack>
			)}
		</Flex>
	);
};
