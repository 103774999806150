import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const LatestWinGames = ({ latestWinners = [], gameList = [] }) => {
	const navigate = useNavigate();
	const navigateToPlayOneGame = (gameId) => {
		const findGame = async () => {
			const game = await gameList.find((game) => {
				return game.gameId === gameId.toString();
			});
			navigate(`/game/${game.category}/${game.gameName}/${game.gameId}`);
		};

		findGame();
	};

	return (
		<Flex gap={2} px={5} width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
			{latestWinners &&
				gameList &&
				latestWinners.length > 0 &&
				gameList.length > 0 &&
				latestWinners.map((win, index) => {
					const imagen = gameList.find((game) => game.gameId === win.gameId.toString())?.gameImage;

					return (
						<Box
							key={`latestWinner-${index}`}
							w={"90px"}
							h={"130px"}
							color="white"
							cursor="pointer"
							display="flex"
							flexDirection={"column"}
							alignItems="center"
							justifyContent="center"
							onClick={() => navigateToPlayOneGame(`${win.gameId}`)}
						>
							<Image
								src={`/images/games/casinoGames/${imagen}`}
								objectFit={"container"}
								w={"70px"}
								h={"90px"}
								borderRadius={"5px"}
							/>
							<Text fontSize="sm" mb={0}>
								{win.username}
							</Text>
							<Text fontSize="sm" mt={0} color={"secundary"}>
								$ {win.amount}
							</Text>
						</Box>
					);
				})}
		</Flex>
	);
};
