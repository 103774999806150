import { Box, Flex, HStack, Image, Stack, Text, VStack, Link, Divider } from "@chakra-ui/react";

export const Footer = () => {
	return (
		<Box bg="#3A3B3B" borderTopRadius={"10px"}>
			<Stack
				direction={{
					base: "column",
					lg: "row",
				}}
				w="full"
				justify="space-between"
				p={10}
			>
				<HStack mb={10} flexDirection={"column"} alignItems="start" flex={6} justify="space-around">
					<Image
						src="/images/logo.png"
						alt="Company Logo"
						rounded="lg"
						width={{
							base: "150px",
							lg: "200px",
						}}
						my={{
							base: 2,
							lg: 0,
						}}
					/>
					<Text textAlign={"justify"}>
						Welcome to BitBet, where we are working together to redefine crypto wagering experiences. At the
						heart of our mission lies the transformation of online betting through the seamless integration
						of blockchain technology, ensuring a platform that’s not just exciting but also secure,
						transparent, and without restrictions. Catering to enthusiasts over the age of 18, BitBet opens
						the door to unlimited betting possibilities, setting a new standard in the industry for ease,
						speed, and accessibility. Whether you’re here to place bets, compete, or even become a part of
						our growing ecosystem, BitBet is where your journey begins.
					</Text>
				</HStack>
				<HStack
					alignItems="start"
					flex={7}
					justify="space-around"
					fontSize={{
						base: "12px",
						md: "16px",
					}}
					color="gray.800"
					_dark={{
						color: "white",
					}}
					textAlign={{
						base: "center",
						md: "left",
					}}
				>
					<Flex gap={5} alignItems={"center"} direction="column">
						<VStack>
							<Text color={"white"} fontSize={"sm"} textTransform="uppercase">
								Community Links
							</Text>
							<Divider borderColor={"secundary"} />
						</VStack>
						<VStack gap={2}>
							<Link variant={"footer"} href="https://discord.com/invite/BccwaCxRSJ" target="_blank">
								Discord
							</Link>
							<Link variant={"footer"} href="https://t.me/BitBetgg" target="_blank">
								Telegram
							</Link>
							<Link variant={"footer"} href="https://twitter.com/bitbetgg" target="_blank">
								Twitter
							</Link>
							<Link variant={"footer"} href=" https://medium.com/@bitbet.gg" target="_blank">
								Medium
							</Link>
							<Link variant={"footer"} href="https://bitbet.gitbook.io/bitbet" target="_blank">
								Whitepaper
							</Link>
						</VStack>
					</Flex>
					<Flex gap={5} alignItems={"center"} direction="column">
						<VStack>
							<Text color={"white"} fontSize={"sm"} textTransform="uppercase">
								XP Rewards Partners:
							</Text>
							<Divider borderColor={"secundary"} />
						</VStack>
						<VStack gap={2}>
							<Link
								variant={"footer"}
								href="https://app.galxe.com/quest/BitBet?hideFooter&id=56753"
								target="_blank"
							>
								Galxe
							</Link>
							<Link variant={"footer"} href="https://taskon.xyz/space/2417370" target="_blank">
								TaskOn
							</Link>
						</VStack>
					</Flex>
					<Flex gap={5} alignItems={"center"} direction="column">
						<VStack>
							<Text color={"white"} fontSize={"sm"} textTransform="uppercase">
								Contact & Support
							</Text>
							<Divider borderColor={"secundary"} />
						</VStack>
						<VStack gap={2}>
							<Link variant={"footer"} href="mailto:info@bitbet.gg" target="_blank">
								Info@bitbet.gg
							</Link>
							<Text color={"white"} href="" target="_blank">
								Discord Customer Service
							</Text>
						</VStack>
					</Flex>
				</HStack>
			</Stack>
		</Box>
	);
};
