import axios from "axios";

/**
 * @name getLastAirdropHistory
 * @description Gets the last airdrop history
 * @param {string} username The username
 * @returns {object} The last airdrop history
 */
export const getLastAirdropHistory = async (username) => {
	try {
		const response = await axios.get(`/history/last/${username}`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
