import { Avatar, AvatarBadge, IconButton, WrapItem } from "@chakra-ui/react"
import { FiEdit } from "react-icons/fi"

/**
 * 
 * @name AvatarMobile
 * @description Mobile user profile image
 * @returns {JSX.Element} The JSX Code for the component
 * @version 1.0.0
 * 
 */
export const AvatarMobile = () => {
  return (
    <WrapItem>
                <Avatar
                    mt={5}
                    size="xl"
                    borderRadius={"50%"}
                    name={`image profile default`}
                    border={"3px solid #FF7A00"}
                    src="/images/bitbet.png"
                >
                    <AvatarBadge border={'0'}>
                        <IconButton
                            icon={<FiEdit />}
                            size={'xs'}
                            border={'2px solid #FF7A00'}
                            bgColor={'#353538'}
                            color={'white'}
                            transition={'0.5s'}
                            _hover={{
                                transform: 'scale(1.2)',
                            }}
                        />
                    </AvatarBadge>	
                </Avatar>
            </WrapItem>
  )
}
