import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Select, useToast } from '@chakra-ui/react';
import { Switch, Box, Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { createChallenge } from '../../../../services/challenges/challenges';
import { errorToast, okToast } from '../../../../utils/toast';

/**
 * 
 * @name CreateChallenge
 * @description The create challenge modal component
 * @returns {JSX.Element} The JSX Code for the CreateChallenge component
 * @version 1.0.0
 * 
 */
const CreateChallenge = () => {

    const toast = useToast();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [challengeData, setChallengeData] = useState({
        gameId: '',
        gameModeId: '',
        currency: '',
        bet: '',
        startTime: '',
        fee: ''
    });

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSwitchChange = () => {
        setIsPrivate((prevState) => !prevState);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'startTime') {
            const formattedStartTime = new Date(value).toISOString();
            setChallengeData((prevData) => ({
                ...prevData,
                [name]: formattedStartTime 
            }));
        } else {
            setChallengeData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleCreateChallenge = async () => {
        try {
            // TODO: Send POST request with challengeData
            const response = await createChallenge(challengeData);
            if (response) {
                okToast(toast, 'Challenge created successfully!');
            } else {
                errorToast(toast, 'Challenge creation failed.', 'Please try again.');
            }
            handleCloseModal();
        } catch (error) {
            errorToast(toast, 'Challenge creation failed.');
        }
    };

    return (
        <div>
            <Button onClick={handleOpenModal}>New challenge</Button>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>+ NEW CHALLENGE</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="privacySwitch" mb="0">
                                    Tipo:
                                </FormLabel>
                                <Switch
                                    id="privacySwitch"
                                    isChecked={isPrivate}
                                    onChange={handleSwitchChange}
                                    colorScheme="teal"
                                />
                                <Box ml="2">{isPrivate ? 'Private' : 'Public'}</Box>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Game</FormLabel>
                                <Select 
                                    name="gameId" 
                                    value={challengeData.gameId} 
                                    onChange={handleInputChange} 
                                    placeholder="Select game"
                                >
                                    <option value="1">Chess</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Game mode</FormLabel>
                                <Input
                                    type="text"
                                    name="gameModeId"
                                    value={challengeData.gameModeId}
                                    onChange={handleInputChange}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Currency</FormLabel>
                                <Select
                                    name="currency"
                                    value={challengeData.currency}
                                    onChange={handleInputChange}
                                    placeholder="Select currency"
                                >
                                    <option value="USDT">USDT</option>
                                    <option value="BNB">BNB</option>
                                    <option value="BTC">BTC</option>
                                    <option value="ETH">ETH</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Bet</FormLabel>
                                <Input 
                                    type="number" 
                                    name="bet" 
                                    value={challengeData.bet} 
                                    onChange={handleInputChange} 
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Start time</FormLabel>
                                <Input
                                    type="datetime-local"
                                    name="startTime"
                                    value={challengeData.startTime}
                                    onChange={handleInputChange}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Fee</FormLabel>
                                <Input type="number" name="fee" value={challengeData.fee} onChange={handleInputChange} />
                            </FormControl>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="teal" onClick={handleCreateChallenge} width="100%">
                            New challenge
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default CreateChallenge;
