import { Flex, HStack, Button, useMediaQuery, useDisclosure } from "@chakra-ui/react";
import SignupModal from "../../../Modals/SignUp/SignUp";
import LoginModal from "../../../Modals/Login/Login";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const LoginMenuNav = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenSignup, onOpen: onOpenSignup, onClose: onCloseSignup } = useDisclosure();
	const [isLargerThanSm] = useMediaQuery("(max-width: 480px)");
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const referredTo = queryParams.get("referredTo");

		if (referredTo) {
			onOpenSignup(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Flex display={isLargerThanSm ? "none" : "flex"} me={9} alignItems={"center"}>
				<HStack>
					<Button size="md" px={10} variant="outlineOrange" onClick={onOpen}>
						SIGN IN
					</Button>
					<Button
						size="md"
						px={10}
						variant="solidOrange"
						boxShadow="0px 0px 5px black inset"
						onClick={onOpenSignup}
					>
						REGISTER
					</Button>
				</HStack>
			</Flex>
			<SignupModal isOpen={isOpenSignup} onClose={onCloseSignup} />
			<LoginModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};
