import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	AbsoluteCenter,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Image,
	Spacer,
	Switch,
	Text,
	useToast,
} from "@chakra-ui/react";
import { errorToast } from "../../utils/toast";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { FiArrowLeftCircle, FiExternalLink } from "react-icons/fi";
import { getMe } from "../../redux/reducers/AuthReducer";
import { getUrlOneGame } from "../../services/blueOceanGame/getUrlOneGame";

/**
 *
 * @name PlayOneGame
 * @description The playing one game page general
 * @returns {JSX.Element} The JSX Code for the Chess component
 * @author saguado | All-in-one Blockchain Company
 * @version 1.0.0
 *
 * TODO: add filters and sort by
 */
export const PlayOneGame = () => {
	const { user } = useSelector((state) => state.auth);
	const isConnected = user !== undefined && user !== null;
	const [needReload, setNeedReload] = useState(true);
	const toast = useToast();
	const [urlGame, setUrlGame] = useState("charging");
	const [demoActive, setDemoActive] = useState(isConnected ? "0" : "1");
	const { gameName, gameId, type } = useParams();
	const iframeRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (needReload) {
			getUrlOneGame(gameId, type, user, demoActive)
				.then((url) => {
					setUrlGame(url);
				})
				.catch((error) => {
					errorToast(toast, "Error, Juego no disponible.");
				})
				.finally(() => {
					setNeedReload(false);
				});
		}
	}, [gameId, toast, type, user, needReload, demoActive]);

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getMe());
		}, 3000); // Fetch data every 3 seconds

		return () => clearInterval(interval); // Cleanup
	}, [dispatch]);

	const FullScreen = () => {
		const iframe = iframeRef.current;
		iframe.requestFullscreen();
	};

	const onChangeDemoActive = () => {
		demoActive === "1" ? setDemoActive("0") : setDemoActive("1");
		setNeedReload(true);
	};

	return (
		<Box pb={20} bgColor={"#302D2B"} borderRadius={"10px"}>
			<Flex
				p={5}
				gap={2}
				alignItems={"center"}
				cursor={"pointer"}
				onClick={() => {
					navigate("/casino-games");
				}}
			>
				<FiArrowLeftCircle color="#969998" />
				<Text color={"#969998"}>Exit the game</Text>
			</Flex>
			<Flex m={5} gap={2} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
				<Flex alignItems={"center"} bgColor={"#3A3B3B"} borderRadius={"10px"} w={"65vw"}>
					<Box>
						<Text
							ms={5}
							p={2}
							color={"#969998"}
							fontSize={"md"}
							_hover={{
								color: "secondary",
							}}
						>
							{gameName}
						</Text>
					</Box>
					<Spacer />
					<Box gap={2} me={5} display={"flex"} alignItems={"center"}>
						<FormControl display="flex" alignItems="center" gap={2}>
							<FormLabel htmlFor="mode-demo" mb="0" color={demoActive === "1" ? "#FF7A00" : "#969998"}>
								Demo mode
							</FormLabel>
							<Switch
								onChange={onChangeDemoActive}
								id="mode-demo"
								colorScheme="orange"
								isChecked={demoActive === "1"}
								isDisabled={!isConnected}
							/>
						</FormControl>
						<FiExternalLink
							color="#969998"
							size={"25px"}
							cursor={"pointer"}
							_hover={{
								color: "secondary",
							}}
							onClick={() => FullScreen()}
						/>
					</Box>
				</Flex>
				{demoActive === "1" && (
					<Flex justify={"center"} alignItems={"center"} bgColor={"#FF7A00"} borderRadius={"10px"} w={"65vw"}>
						<Text fontSize={"1.5rem"}>
							Attention! You are playing in demo mode, you are not betting real money.
						</Text>
					</Flex>
				)}
				{urlGame === "charging" ? (
					<Box
						w={"65vw"}
						h={"80vh"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<LoadingSpinner />
					</Box>
				) : !urlGame ? (
					<Box
						position={"relative"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<AbsoluteCenter textAlign={"center"}>
							<Text
								fontSize={"2rem"}
								fontWeight={"bold"}
								backgroundColor={"black"}
								borderRadius={"10px"}
								color={"white"}
							>
								NOT AVAILABLE IN YOUR REGION.
							</Text>
						</AbsoluteCenter>
						<Image src="/images/noDisponible.gif" w={"65vw"} h={"80vh"} borderRadius={"10px"} />
					</Box>
				) : (
					<Box>
						<iframe
							ref={iframeRef}
							src={`${urlGame}`}
							title={`Nombre del juego`}
							style={{
								border: "0",
								borderRadius: "10px",
								width: "65vw",
								height: "80vh",
							}}
							allowFullScreen
						/>
					</Box>
				)}
			</Flex>
		</Box>
	);
};
