import { Flex, HStack, Button, useMediaQuery, Box, Text } from "@chakra-ui/react";
import { CurrencyItem } from "./Subcomponents/CurrencyItem";
import { FcRating } from "react-icons/fc";
import { useSelector } from "react-redux";

/**
 *
 * @name CurrencyMenu
 * @description The currency menu component
 * @param {Object} props The props for the CurrencyMenu component
 * @param {Function} props.onOpenLogin The function to open the login modal
 * @returns {JSX.Element} The JSX Code for the CurrencyMenu component
 * @version 1.0.0
 *
 */
const CurrencyMenu = ({ onOpenWallet }) => {
	const { user } = useSelector((state) => state.auth);
	const [isLargerThanSm] = useMediaQuery("(max-width: 480px)");

	return (
		<Flex display={isLargerThanSm ? "none" : "flex"} me={user.isAdmin ? 2 : 9} alignItems={"center"}>
			<HStack>
				<Box borderRadius={"5px"} py={2} px={2} background={"#3A3B3B"}>
					<Flex h={"100%"} gap={2} flexDirection={"row"} w="100%" alignItems={"center"}>
						<Text>{user?.exp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EXP</Text>
						<FcRating />
					</Flex>
				</Box>
				<Box borderRadius={"5px"} py={2} px={2} background={"#3A3B3B"}>
					<CurrencyItem />
				</Box>
				<Button
					size="md"
					px={10}
					variant="solid"
					onClick={onOpenWallet}
					bgGradient={"linear(to-br, #FF7A00, #FF5B27)"}
					_hover={{ bgGradient: "linear(to-br, #CC6709, #CC6709)" }}
				>
					WALLET
				</Button>
			</HStack>
		</Flex>
	);
};

export default CurrencyMenu;
