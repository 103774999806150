import { Box, Flex, Heading, Image, Stack, useMediaQuery } from "@chakra-ui/react";
import { IoGameControllerOutline } from "react-icons/io5";
import { LoadingSpinner } from "../LoadingSpinner";
import { videoGame } from "../../data/dataGameImages";

/**
 *
 * @name GameCardVideoGame
 * @description Component that renders the different video game cards.
 * @param {Function} props.onClick The function redirects to the specific game url
 * @returns {JSX.Element} The JSX Code for the CurrencyMenu component
 * @author saguado | All-in-one Blockchain Company
 * @version 1.0.0
 *
 */
export const GameCardVideoGame = ({ onClick }) => {
	const [isLargerThanMd] = useMediaQuery("(max-width: 767px)");

	return (
		<>
			<Stack
				flexDirection={"row"}
				justifyContent={isLargerThanMd ? "center" : "left"}
				alignItems={"center"}
				mb={10}
			>
				<IoGameControllerOutline size={"40px"} color="white" />
				<Heading color="white" textAlign={"left"}>
					Gaming
				</Heading>
			</Stack>
			{videoGame.length === 0 ? (
				<Box display={"flex"} justifyContent={"left"}>
					<LoadingSpinner />
				</Box>
			) : (
				<Flex flexWrap={"wrap"} justifyContent={isLargerThanMd ? "center" : "left"} gap={5}>
					{videoGame.map((game) => (
						<Box key={game.gameId} rounded="lg" minW={"150px"} h="210px">
							<Image
								src={game.image}
								borderRadius="10px"
								w={"150px"}
								h={"210px"}
								color="white"
								borderColor={"transparent"}
								textShadow="0px 0px 10px black"
								cursor="pointer"
								onClick={game.name === "Chess" ? onClick : null}
								_hover={{
									border: "2px",
									borderRadius: "10px",
									borderColor: "#FF7A00",
								}}
							/>
						</Box>
					))}
				</Flex>
			)}
		</>
	);
};
