import axios from "axios";

/**
 * 
 * @name getCurrenciesDetails
 * @description Request the list detail currency.
 * @returns {Array} list currencies.
 * 
 */
export const getCurrenciesDetails = async () => {
    try {
        const response = await axios.get("services/minCurrencies")

        return response.data.data
    } catch (error) {
        throw error
    }
}