import axios from "axios";

/**
 *
 * @name getGameMocks
 * @description Request the list of all games.
 * @returns {Array} list of all game.
 *
 */
export const getGameMocks = async () => {
	try {
		const response = await axios.get(`/game/imagesListGames`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
