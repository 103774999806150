import React, { useEffect, useState } from "react";
import { HeaderAllPage } from "../../components/AdminPanel/HeaderAllPage";
import { CardUsersAdminPanel } from "../../components/AdminPanel/CardUsersAdminPanel";
import { allUsers } from "../../services/users";
import { Center, HStack, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
const LIMIT = 4;

const styleSelect = {
	backgroundColor: "#FF7A00",
	color: "black",
	width: "30px",
	height: "30px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "5px",
	borderRadius: "50%",
};

export const UsersAdminPanel = () => {
	const [users, setUsers] = useState([]);
	const [offset, setOffset] = useState(0);
	const [pages, setPages] = useState(0);
	const [page, setPage] = useState(0);
	const [needReload, setNeedReload] = useState(false);

	useEffect(() => {
		const getAllUsers = async () => {
			const { users, countTotalUsers } = await allUsers(offset);
			setUsers(users);
			setPages(countTotalUsers / LIMIT);
			setNeedReload(true);
		};

		getAllUsers();
	}, [offset]);

	const nextPage = () => {
		if (offset + LIMIT === pages * LIMIT) return;

		setOffset(offset + LIMIT);
		setPage(page + 1);
	};

	const previousPage = () => {
		if (offset === 0) return;

		setOffset(offset - LIMIT);
		setPage(page - 1);
	};

	const calculateOffsetToPage = (page) => {
		setOffset(page * LIMIT);
		setPage(page);
	};

	return (
		<>
			<HeaderAllPage title={"Users"} />
			<VStack gap={5}>
				{!needReload ? (
					<Spinner size={"xl"} color="secundary" />
				) : users.length === 0 ? (
					<Heading>Not user</Heading>
				) : (
					users?.map((user) => (
						<React.Fragment key={user.username}>
							<CardUsersAdminPanel user={user} />
						</React.Fragment>
					))
				)}
			</VStack>
			{users.length > 0 && (
				<Center>
					<HStack mt={5} gap={5}>
						<HStack
							cursor={offset > 0 ? "pointer" : "not-allowed"}
							color={offset > 0 ? "white" : "darkGray"}
							_hover={offset > 0 ? { color: "secundary" } : null}
							onClick={() => previousPage()}
						>
							<BsChevronLeft />
							<Text>Previous</Text>
						</HStack>

						{Array.from({ length: pages }, (_, i) => (
							<Text
								key={i + 1}
								style={page === i ? styleSelect : null}
								cursor={"pointer"}
								_hover={{ color: "secundary" }}
								onClick={() => calculateOffsetToPage(i)}
							>
								{i + 1}
							</Text>
						))}
						<HStack
							cursor={offset + LIMIT !== pages * LIMIT ? "pointer" : "not-allowed"}
							color={offset + LIMIT !== pages * LIMIT ? "white" : "darkGray"}
							_hover={offset + LIMIT !== pages * LIMIT ? { color: "secundary" } : null}
							onClick={() => nextPage()}
						>
							<Text>Next</Text>
							<BsChevronRight />
						</HStack>
					</HStack>
				</Center>
			)}
		</>
	);
};
