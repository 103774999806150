import {
	FcAdvertising,
	FcBusinessman,
	FcCurrencyExchange,
	FcDonate,
	FcPlus,
	FcSalesPerformance,
	FcSmartphoneTablet,
} from "react-icons/fc";
import { claimHours } from "./constants";

export const convertMilisecondsToHoursAndMinutes = (miliseconds) => {
	// Calcula la cantidad de horas
	const horas = Math.floor(miliseconds / (1000 * 60 * 60));

	// Calcula los milisegundos restantes después de calcular las horas
	const milisegundosRestantes = miliseconds % (1000 * 60 * 60);

	// Calcula la cantidad de minutos
	const minutos = Math.floor(milisegundosRestantes / (1000 * 60));

	// Calcula los segundos
	const segundos = Math.floor((milisegundosRestantes % (1000 * 60)) / 1000);

	// Formatea la salida en hh:mm:ss
	const formato = (valor) => (valor < 10 ? `0${valor}` : valor);
	const tiempoFormateado = `${formato(horas)}:${formato(minutos)}:${formato(segundos)}`;

	return tiempoFormateado;
};

export const calculateTimeRest = (createdAt) => {
	// Calcula la cantidad de milisegundos restantes para que se pueda reclamar el airdrop
	const milisecondsTo12Hours = claimHours * 60 * 60 * 1000;
	// Obtiene la fecha de creación del último historial
	const lastDateHistory = new Date(createdAt);
	// Obtiene la fecha actual
	const currentDate = new Date();
	// Calcula la diferencia de tiempo entre la fecha actual y la fecha del último historial
	const diffTime = currentDate - lastDateHistory;
	// Calcula el tiempo restante para que se pueda reclamar el airdrop
	const restTime = milisecondsTo12Hours - diffTime;

	return restTime;
};

export const iconMap = {
	bet: FcCurrencyExchange,
	invite: FcBusinessman,
	play: FcSmartphoneTablet,
	follow: FcAdvertising,
	win: FcSalesPerformance,
	created: FcPlus,
	claim: FcDonate,
};
