import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FormAddChallengeExperience } from "./subcomponentsForm/FormAddChallengeExperience";
import { createChallengeExperience } from "../../../services/adminPanel/AdminPanelServices";
import { errorToast, okToast } from "../../../utils/toast";

const inicializeChallengeExperience = {
	icon: "",
	name: "",
	game: "",
	exp: "",
	timeIniciation: "",
	timeFinish: "",
	description: "",
	action: "",
	amount: 0,
	isActive: true,
	isFeatured: false,
};

export const AddChallengeExperienceModal = ({ onClose, isOpen, setNeedReload }) => {
	const toast = useToast();
	const [challengeExperienceData, setChallengeExperienceData] = useState(inicializeChallengeExperience);

	const onSubmit = async () => {
		try {
			const check = await createChallengeExperience(challengeExperienceData);
			if (check) {
				okToast(toast, "Challenge added successfully");
				setNeedReload(false);
				onCloseModal();
			}
		} catch (error) {
			errorToast(toast, "Error when creating challenge experience", error);
		}
	};
	const onCloseModal = () => {
		setChallengeExperienceData(inicializeChallengeExperience);
		onClose();
	};

	return (
		<Modal onClose={onClose} isOpen={isOpen} isCentered size={"4xl"}>
			<ModalOverlay />
			<ModalContent bgColor={"mediumGray"} w={"100%"}>
				<ModalHeader color={"secundary"}>Add Challenges</ModalHeader>
				<ModalBody>
					<FormAddChallengeExperience
						inicializeChallengeExperience={inicializeChallengeExperience}
						challengeExperienceData={challengeExperienceData}
						setChallengeExperienceData={setChallengeExperienceData}
					/>
				</ModalBody>
				<ModalFooter>
					<HStack gap={5}>
						<Button variant={"bitbet"} onClick={() => onCloseModal()}>
							Cancel{" "}
						</Button>
						<Button variant={"solidOrange"} onClick={() => onSubmit()}>
							Add challenge +
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
