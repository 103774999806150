import {  
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerHeader, 
    DrawerOverlay, 
} from "@chakra-ui/react"
import { AvatarMobile } from "./SubcomponentProfile/AvatarMobile";
import { TextHeaderMobile } from "./SubcomponentProfile/TextHeaderMobile";
import { BodyProfileMobile } from "./SubcomponentProfile/BodyProfileMobile";

/**
 * 
 * @name UserProfileMobile
 * @description Mobile user profile view
 * @returns {JSX.Element} The JSX Code for the component
 * @version 1.0.0
 * 
 */
export const UserProfileMobile = ({ isOpen = false, onClose }) => {
    
  return (
    <Drawer 
        size="xs"
        placement={'left'} 
        onClose={onClose} 
        isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent height="100vh" bgColor={"#1F1F22"}>
            <DrawerCloseButton color={'primary'}/>
            <DrawerHeader gap={5} display={"flex"} alignItems={"center"} flexDirection={"row"}>
                <AvatarMobile/>
                <TextHeaderMobile/>
            </DrawerHeader>
            <DrawerBody mt={5} display={"flex"} flexDirection={"column"} gap={3}>
                <BodyProfileMobile onClose={onClose}/>
            </DrawerBody>
        </DrawerContent>
    </Drawer>
  )
}
