import { useEffect, useState } from "react";
import {
	Heading,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Stack,
	useToast,
	useDisclosure,
} from "@chakra-ui/react";
import Deposit from "./Deposits/Deposit";
import OptionPicker from "./Pickers/OptionPicker";
import CurrencyPicker from "./Pickers/CurrencyPicker";
import { getFees } from "../../../services/fees";
import Withdraw from "./Withdraw/Withdraw";
import { useSelector } from "react-redux";
import { errorToast } from "../../../utils/toast";
import { ImportantNotice } from "./ImportantNotice";

const WalletModal = ({ isOpen = false, onClose }) => {
	const toast = useToast();

	const [selectedCurrency, setSelectedCurrency] = useState("");

	const [selectedOption, setSelectedOption] = useState("deposit");
	const [fees, setFees] = useState({});

	const { user } = useSelector((state) => state.auth);

	const { isOpen: isOpenNotice, onOpen: onOpenNotice, onClose: onCloseNotice } = useDisclosure();

	const handleClose = () => {
		setSelectedOption("deposit");
		setSelectedCurrency("");
		onClose();
	};

	useEffect(() => {
		const fetchFees = async () => {
			try {
				const response = await getFees();
				setFees(response.data);
			} catch (error) {
				errorToast(toast, "Error getting fees", error.message);
			}
		};

		fetchFees();
	}, [toast]);

	const handleSelectCurrency = (currency) => {
		setSelectedCurrency(currency);
		if (selectedCurrency === "") {
			onOpenNotice();
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} size={"xl"} onClose={handleClose} isCentered>
				<ModalOverlay bgColor={"blackAlpha.800"} />
				<ModalContent bgColor={"#2F2D2D"} p={4}>
					<Heading mt={4} textAlign="center" color="white">
						WALLET
					</Heading>
					<ModalCloseButton color="white" />

					<Stack direction={"column"} spacing={2} w={"full"} px={6}>
						<OptionPicker
							selectedOption={selectedOption}
							setOption={setSelectedOption}
							setSelectedCurrency={setSelectedCurrency}
						/>

						{selectedOption !== "balance" && (
							<CurrencyPicker
								selectedCurrency={selectedCurrency}
								setSelectedCurrency={handleSelectCurrency}
								balances={selectedOption === "withdraw" ? user.balances : []}
							/>
						)}

						{selectedOption === "deposit" && <Deposit selectedCurrency={selectedCurrency} fees={fees} />}

						{selectedOption === "withdraw" && (
							<Withdraw
								selectedCurrency={selectedCurrency}
								fees={fees}
								setSelectedCurrency={setSelectedCurrency}
							/>
						)}

						{/* {selectedOption === "balance" && <Balances balances={user.balances} usdBalances={user.usd_balances} />} */}
					</Stack>
				</ModalContent>
			</Modal>
			<ImportantNotice isOpen={isOpenNotice} onClose={onCloseNotice} />
		</>
	);
};

export default WalletModal;
