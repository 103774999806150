import { useEffect, useState } from "react";

import {
	Heading,
	Input,
	Stack,
	Collapse,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	IconButton,
	Text,
} from "@chakra-ui/react";
import { FiCheck, FiEdit, FiX } from "react-icons/fi";

import { useSelector } from "react-redux";

import {
	updateUserEmail,
	updateUserPassword,
	updateUserPublicName,
	updateUserReferralCode,
} from "../../../services/users";
import { errorToast, okToast } from "../../../utils/toast";
import { validatePassword } from "../../../utils/passwords";

/**
 *
 * @name UserProfileModal
 * @description The user profile modal component
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @returns {JSX.Element} The JSX Code for the UserProfileModal component
 * @version 1.0.0
 *
 */
const UserProfileModal = ({ isOpen = false, onClose }) => {
	const { user, isLoading } = useSelector((state) => state.auth);
	const [needReload, setNeedReload] = useState(true);
	const toast = useToast();

	const [inputUser, setInputUser] = useState(user?.username || "");
	const [inputPublicName, setInputPublicName] = useState(user?.public_name || "");
	const [inputEmail, setInputEmail] = useState(user?.email || "");
	const [inputPassword, setInputPassword] = useState("");
	const [inputConfirmPassword, setInputConfirmPassword] = useState("");
	const [inputReferralCode, setInputReferralCode] = useState("");

	const [isDisabledEmail, setIsDisabledEmail] = useState(true);
	const [isDisabledPassword, setIsDisabledPassword] = useState(true);
	const [isDisabledPublicName, setIsDisabledPublicName] = useState(true);
	const [isDisabledReferralCode, setIsDisabledReferralCode] = useState(true);
	const samePassword = inputPassword === inputConfirmPassword;

	const checkPassword = validatePassword(inputPassword) && samePassword;

	useEffect(() => {
		const check = () => {
			setInputUser(user?.username);
			setInputPublicName(user.public_name);
			setInputEmail(user.email);
			setInputReferralCode(user?.referralCode);

			setNeedReload(false);
		};
		user && !isLoading && needReload && check();
	}, [user, isLoading, needReload]);

	const handleChangeEmail = async () => {
		if (inputEmail === user.email) {
			setIsDisabledEmail(true);
			return;
		}

		try {
			const update = await updateUserEmail({
				email: inputEmail,
			});

			okToast(toast, update.message);
		} catch (error) {
			errorToast(toast, error);
		} finally {
			setIsDisabledEmail(true);
		}
	};

	const handleChangePublicName = async () => {
		if (inputPublicName === user.public_name) {
			setIsDisabledPublicName(true);
			return;
		}

		try {
			const update = await updateUserPublicName({
				public_name: inputPublicName,
			});
			okToast(toast, update.message);
		} catch (error) {
			errorToast(toast, error);
		} finally {
			setIsDisabledPublicName(true);
		}
	};

	const handleChangeReferralCode = async () => {
		if (inputReferralCode === user.referralCode) {
			setIsDisabledReferralCode(true);
			return;
		}

		try {
			const update = await updateUserReferralCode({
				referralCode: inputReferralCode,
			});
			okToast(toast, update.message);
		} catch (error) {
			errorToast(toast, error);
		} finally {
			setIsDisabledReferralCode(true);
		}
	};

	const handleChangePassword = async () => {
		try {
			const update = await updateUserPassword({
				password: inputPassword,
				confirm_password: inputConfirmPassword,
			});
			okToast(toast, update.message);
			setInputPassword("");
			setInputConfirmPassword("");
			setIsDisabledPassword(true);
		} catch (error) {
			errorToast(toast, error);
		} finally {
			setIsDisabledPassword(true);
		}
	};

	const hanndleCancelChangePassword = () => {
		setInputPassword("");
		setInputConfirmPassword("");
		setIsDisabledPassword(true);
		setNeedReload(true);
	};

	const handleClose = () => {
		setInputPassword("");
		setInputConfirmPassword("");
		setInputPublicName("");
		setInputEmail("");
		setInputUser("");
		setIsDisabledEmail(true);
		setIsDisabledPassword(true);
		setIsDisabledPublicName(true);
		setNeedReload(true);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose} isCentered>
			<ModalOverlay />
			<ModalContent bgColor={"#2F2D2D"} color="white">
				<Heading textAlign="center" pt={5} color="white">
					ACCOUNT
				</Heading>
				<ModalCloseButton color="white" />
				<Stack spacing={4} w={"full"} maxW={"md"} rounded={"xl"} p={6}>
					<Input
						placeholder="Username"
						variant={"bitbet"}
						value={inputUser}
						_placeholder={{ color: "gray.500" }}
						type="text"
						isDisabled
					/>

					<Stack direction="row" spacing={1}>
						<Input
							placeholder="Your public name"
							variant={"bitbet"}
							value={inputPublicName}
							isDisabled={isDisabledPublicName}
							onChange={(e) => setInputPublicName(e.target.value)}
							_placeholder={{ color: "gray.500" }}
							type="text"
						/>
						{isDisabledPublicName ? (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change public name"}
								icon={<FiEdit />}
								color={"white"}
								onClick={() => setIsDisabledPublicName(!isDisabledPublicName)}
							/>
						) : (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change public name"}
								icon={<FiCheck />}
								color={"white"}
								onClick={handleChangePublicName}
							/>
						)}
					</Stack>

					<Stack direction="row" spacing={1}>
						<Input
							placeholder="your-email@example.com"
							variant={"bitbet"}
							value={inputEmail}
							isDisabled={isDisabledEmail}
							onChange={(e) => setInputEmail(e.target.value)}
							_placeholder={{ color: "gray.500" }}
							type="email"
						/>
						{isDisabledEmail ? (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change email"}
								icon={<FiEdit />}
								color={"white"}
								onClick={() => setIsDisabledEmail(!isDisabledEmail)}
							/>
						) : (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change email"}
								icon={<FiCheck />}
								color={"white"}
								onClick={handleChangeEmail}
							/>
						)}
					</Stack>
					<Stack direction="row" spacing={1}>
						<Input
							placeholder="New password"
							variant={"bitbet"}
							value={inputPassword}
							isDisabled={isDisabledPassword}
							onChange={(e) => setInputPassword(e.target.value)}
							_placeholder={{ color: "gray.500" }}
							type="password"
						/>
						{isDisabledPassword ? (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change password"}
								icon={<FiEdit />}
								color={"white"}
								onClick={() => setIsDisabledPassword(!isDisabledPassword)}
							/>
						) : checkPassword ? (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change password"}
								icon={<FiCheck />}
								color={"white"}
								onClick={handleChangePassword}
							/>
						) : (
							<IconButton
								variant={"bitbet"}
								aria-label={"Change password"}
								icon={<FiX />}
								color={"white"}
								onClick={hanndleCancelChangePassword}
							/>
						)}
					</Stack>

					<Collapse in={inputPassword.length > 0}>
						<Stack direction="row" spacing={1}>
							<Input
								placeholder="Confirm password"
								value={inputConfirmPassword}
								onChange={(e) => setInputConfirmPassword(e.target.value)}
								_placeholder={{ color: "gray.500" }}
								type="password"
							/>
							{isDisabledPassword ? (
								<IconButton
									variant={"ghost"}
									aria-label={"Change password"}
									icon={<FiEdit />}
									color={"#FF7A00"}
									onClick={() => setIsDisabledPassword(!isDisabledPassword)}
								/>
							) : checkPassword ? (
								<IconButton
									variant={"ghost"}
									aria-label={"Change password"}
									icon={<FiCheck />}
									color={"white"}
									onClick={handleChangePassword}
								/>
							) : (
								<IconButton
									variant={"ghost"}
									aria-label={"Change password"}
									icon={<FiX />}
									color={"#FF7A00"}
									onClick={hanndleCancelChangePassword}
								/>
							)}
						</Stack>
					</Collapse>

					<Collapse in={inputPassword.length > 0 && inputPassword.length < 8}>
						<Text color="red.500" fontWeight={"bold"}>
							Password must be at least 8 characters.
						</Text>
					</Collapse>

					<Collapse in={!samePassword && inputPassword.length >= 8}>
						<Text color="red.500" fontWeight={"bold"}>
							Passwords don't match.
						</Text>
					</Collapse>

					<Collapse in={!validatePassword(inputPassword) && inputPassword.length >= 8}>
						<Text color="red.500" fontWeight={"bold"}>
							Password need one uppercase, lowercase and number.
						</Text>
					</Collapse>

					<Stack direction={"column"}>
						<Text>Referral code:</Text>
						<Stack direction="row" spacing={1}>
							<Input
								placeholder="Referral code"
								variant={"bitbet"}
								value={inputReferralCode}
								isDisabled={isDisabledReferralCode}
								onChange={(e) => setInputReferralCode(e.target.value)}
								_placeholder={{ color: "gray.500" }}
								type="email"
							/>
							{isDisabledReferralCode ? (
								<IconButton
									variant={"bitbet"}
									aria-label={"Change referral code"}
									icon={<FiEdit />}
									color={"white"}
									onClick={() => setIsDisabledReferralCode(!isDisabledReferralCode)}
								/>
							) : (
								<IconButton
									variant={"bitbet"}
									aria-label={"Change referral code"}
									icon={<FiCheck />}
									color={"white"}
									onClick={handleChangeReferralCode}
								/>
							)}
						</Stack>
					</Stack>
				</Stack>
			</ModalContent>
		</Modal>
	);
};

export default UserProfileModal;
