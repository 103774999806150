import axios from "axios";

export const validatePassword = (password) => {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
    // Can be all symbols and special characters
    // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return re.test(password);
};

export const forgotPassword = async (email) => {
    try {
        const response = await axios.post("/users/forgotpassword", { email });
        return response.data;
    } catch (error) {
        throw error.response.data.message;
    }
}