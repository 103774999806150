import {
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react'
import { acceptChallenge, cancelChallenge } from '../../services/challenges/challenges'
import { errorToast, okToast } from '../../utils/toast'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMe } from '../../redux/reducers/AuthReducer'

/**
 * 
 * @name ChallengeTable
 * @description Table with all the challenges
 * @author LMejia - Unknown Gravity
 * @param {object} challenges The challenges
 * @param {function} setNeedReload The function to set the need reload state
 * @returns {JSX.Element} The JSX Code for the ChallengeTable component
 * @version 1.0.0
 */
const ChallengeTable = ({ challenges = [], setNeedReload }) => {
    const toast = useToast()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)

    const handleAcceptChallenge = async (challengeId) => {
        try {
            const response = await acceptChallenge({ id: challengeId })
            okToast(toast, 'Challenge accepted', response.message)
        } catch (error) {
            errorToast(toast, 'Error accepting challenge', error)
        } finally {
            setNeedReload(true)
            dispatch(getMe())
        }
    }

    const handleCancelChallenge = async (challengeId) => {
        try {
            const response = await cancelChallenge(challengeId)
            okToast(toast, 'Challenge cancelled', response.message)
        } catch (error) {
            errorToast(toast, 'Error cancelling challenge', error)
        } finally {
            setNeedReload(true)
            dispatch(getMe())
        }
    }


    const handleNavigateToPage = (uuid) => {
        navigate(`/challenge/${uuid}`)
    }

    const canJoinAnyChallenge = user && challenges.some(challenge => challenge.creatorName !== user.username && challenge.status === 'open')
    const canCancelAnyChallenge = user && challenges.some(challenge => challenge.creatorName === user.username && challenge.status === 'open')

    return (
        <Table w="100%">
            <Thead>
                <Tr>
                    <Th color="white">Bet</Th>
                    <Th color="white">Creator</Th>
                    <Th color="white">Opponent</Th>
                    <Th color="white">Start time</Th>
                    <Th color="white">Status</Th>
                    {user?.games?.chesscom && canJoinAnyChallenge && <Th color="white">Join</Th>}
                    {user?.games?.chesscom && canCancelAnyChallenge && <Th color="white">Actions</Th>}
                </Tr>
            </Thead>
            <Tbody>
                {challenges &&
                    challenges.length > 0 &&
                    challenges.map((challenge) => (
                        <Tr key={challenge.uuid} color="white">
                            <Td>
                                {parseFloat(challenge.bet)}{' '}
                                {challenge.currency}
                            </Td>
                            <Td>{challenge.creatorName}</Td>
                            {challenge.opponentName ? (
                                <Td>{challenge.opponentName}</Td>
                            ) : challenge.status === 'cancelled' ? (
                                <Td>-</Td>
                            ) : (
                                <Td>Waiting...</Td>
                            )}
                            <Td>
                                {new Date(challenge.startTime).toLocaleString()}
                            </Td>

                            <Td>
                                {challenge.status === 'open' && (
                                    <Button
                                        variant="outlineGreen"
                                        w="100%"
                                        onClick={() =>
                                            handleNavigateToPage(challenge.uuid)
                                        }>
                                        {challenge.status}
                                    </Button>
                                )}
                                {challenge.status === 'live' && (
                                    <Button
                                        variant="solidFuchsia"
                                        w="100%"
                                        onClick={() =>
                                            handleNavigateToPage(challenge.uuid)
                                        }>
                                        {challenge.status}
                                    </Button>
                                )}
                                {(challenge.status === 'ended' ||
                                    challenge.status === 'cancelled') && (
                                        <Button
                                            variant="solidOrange"
                                            w="100%"
                                            onClick={() =>
                                                handleNavigateToPage(challenge.uuid)
                                            }>
                                            {challenge.status}
                                        </Button>
                                    )}
                                {challenge.status === 'waiting' && (
                                    <Button
                                        variant="solidGreen"
                                        w="100%"
                                        onClick={() =>
                                            handleNavigateToPage(challenge.uuid)
                                        }>
                                        {challenge.status}
                                    </Button>
                                )}
                            </Td>
                            {user?.games?.chesscom &&
                                challenge.creatorName !== user.username &&
                                challenge.status === 'open' && (
                                    <Td>

                                        <Button
                                            variant="solidOrange"
                                            onClick={() =>
                                                handleAcceptChallenge(
                                                    challenge.uuid
                                                )
                                            }
                                            w="100%">
                                            Join
                                        </Button>

                                    </Td>
                                )}
                            {user?.games?.chesscom &&
                                challenge.creatorName === user.username &&
                                challenge.status === 'open' && (
                                    <Td>

                                        <Button
                                            onClick={() =>
                                                handleCancelChallenge(
                                                    challenge.uuid
                                                )
                                            }
                                            w="100%"
                                        >
                                            Cancel
                                        </Button>

                                    </Td>
                                )}
                        </Tr>
                    ))}
                {challenges && challenges.length === 0 && (
                    <Tr>
                        <Td colSpan={6} color="white" textAlign={"center"}>
                            There are no challenges available
                        </Td>
                    </Tr>
                )}
            </Tbody>
        </Table>
    )
}

export default ChallengeTable
