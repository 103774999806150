import { useEffect } from "react";
import { useToast, useClipboard } from "@chakra-ui/react";

import { useSelector } from "react-redux";

import BitcoinDeposit from "./BitcoinDeposit";
import EthDeposit from "./EthDeposit";
import BnbDeposit from "./BnbDeposit";
import UsdtDeposit from "./UsdtDeposit";
import { okToast } from "../../../../utils/toast";

/**
 * 
 * @name Deposit
 * @description The deposit component
 * @param {Object} props The props for the component
 * @param {String} props.selectedCurrency The selected currency string
 * @param {Object} props.fees The fees object
 * @returns {JSX.Element} The JSX Code for the Deposit component
 * @version 1.0.0
 * 
 */
const Deposit = ({ selectedCurrency, fees }) => {
    const toast = useToast();
    const { user } = useSelector((state) => state.auth);
    const { onCopy, value, setValue, hasCopied } = useClipboard("");

    useEffect(() => {
        if (hasCopied) {
            okToast(toast, "Copied to clipboard!");
        }
    }, [hasCopied, toast]);

    useEffect(() => {
        if (user?.wallets) {
            if (selectedCurrency === "eth") {
                setValue(user.wallets.evm);
            } else if (selectedCurrency === "btc") {
                setValue(user.wallets.btc);
            } else if (selectedCurrency === "bnb") {
                setValue(user.wallets.evm);
            } else if (selectedCurrency === "usdt") {
                setValue(user.wallets.evm);
            }
        }
    }, [user, setValue, selectedCurrency]);

    return (
        <>
            <BitcoinDeposit selectedCurrency={selectedCurrency} value={value} onCopy={onCopy} fee={fees.btc} />
            <EthDeposit selectedCurrency={selectedCurrency} value={value} onCopy={onCopy} fee={fees.eth} />
            <BnbDeposit selectedCurrency={selectedCurrency} value={value} onCopy={onCopy} fee={fees.bnb} />
            <UsdtDeposit selectedCurrency={selectedCurrency} value={value} onCopy={onCopy} fee={fees.usdt} />
        </>
    );
};

export default Deposit;
