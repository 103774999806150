import { Flex, Text, Button, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, okToast } from "../../../../utils/toast";
import { addAirdropBalance } from "../../../../services/users";
import { getLastAirdropHistory } from "../../../../services/history/historyService";
import { getMe } from "../../../../redux/reducers/AuthReducer";
import { calculateTimeRest, convertMilisecondsToHoursAndMinutes } from "../../../../utils/utils";

export const CurrencyItem = () => {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const toast = useToast();
	const [airdropStatus, setAirdropStatus] = useState(false);
	const [timeNewAirdrop, setTimeNewAirdrop] = useState(0);
	const balance = user?.balances?.balance || 0;

	useEffect(() => {
		const getLastHistory = async () => {
			const lastHistory = await getLastAirdropHistory(user?.username);

			if (lastHistory !== null) {
				const restTime = calculateTimeRest(lastHistory.createdAt);

				setTimeNewAirdrop(restTime);
				restTime <= 0 && setAirdropStatus(true);
			} else {
				setAirdropStatus(true);
			}
		};

		getLastHistory();
	}, [user]);

	useEffect(() => {
		let interval;

		if (!airdropStatus) {
			interval = setInterval(() => {
				if (timeNewAirdrop <= 0) {
					setAirdropStatus(true);
					clearInterval(interval);
				}
				setTimeNewAirdrop(timeNewAirdrop - 1000);
			}, 1000); // Fetch data every 1 seconds
		}

		return () => clearInterval(interval); // Cleanup
	}, [timeNewAirdrop, airdropStatus]);

	const onSubmit = async () => {
		try {
			const checkAddAirdrop = await addAirdropBalance(user.username);
			setAirdropStatus(false);
			if (checkAddAirdrop) {
				dispatch(getMe());
				return okToast(toast, "Airdrop claimed successfully");
			}
		} catch (error) {
			return errorToast(toast, "You can only receive airdrop every 12 hours");
		}
	};

	return (
		<Flex gap={5} w="100%" alignItems={"center"}>
			<Text>$ {balance}</Text>

			<Button
				variant={"outlineOrange"}
				w={"90px"}
				h={"25px"}
				isDisabled={airdropStatus ? false : true}
				onClick={() => onSubmit()}
			>
				{airdropStatus ? (
					"Claim USD"
				) : (
					<Text fontSize={"10px"}>{convertMilisecondsToHoursAndMinutes(timeNewAirdrop)}</Text>
				)}
			</Button>
		</Flex>
	);
};
