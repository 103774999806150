import { Collapse, Flex, Text } from "@chakra-ui/react";
// import { Center,Image } from "@chakra-ui/react";
// import WalletPicker from "../WalletPicker";
// import FeeCalculator from "../FeeCalculator/FeeCalculator";
import ChainWarning from "../ChainWarning";

/**
 *
 * @name EthDeposit
 * @description The eth deposit component
 * @param {Object} props The props for the EthDeposit component
 * @param {String} props.selectedCurrency The selected currency string
 * @param {String} props.value The value string
 * @param {Function} props.onCopy The function to copy the value
 * @param {Number} props.fee The fee number
 * @returns {JSX.Element} The JSX Code for the EthDeposit component
 *
 */
const EthDeposit = ({ value, onCopy, selectedCurrency, fee }) => {
	return (
		<Collapse in={selectedCurrency === "eth"}>
			{/* <Flex gap={1} mb={4}>
				<Center>
					<Image src="/images/currencies/eth.png" alt="Ethereum" w={4} h={4} />
				</Center>
				<Text color={"white"} fontSize={"sm"}>
					Your ethereum deposit address:
				</Text>
			</Flex>

			<WalletPicker wallet={value} onCopy={onCopy} chain={selectedCurrency} /> */}
			<Flex gap={1} mb={4} justify={"center"} bg={"secundary"} p={"10px 5px"} rounded={"md"}>
				<Text color={"white"} fontSize={"lg"} fontWeight={"bold"}>
					Deposit function currently disabled.
				</Text>
			</Flex>

			<ChainWarning texto={"ONLY SEND ETHEREUM TO THIS ADDRESS"} />
		</Collapse>
	);
};

export default EthDeposit;
