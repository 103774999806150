import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Avatar,
	Box,
	Divider,
	HStack,
	Icon,
	Spacer,
	Stack,
	Text,
} from "@chakra-ui/react";
import { CiLogout } from "react-icons/ci";
import { FaPowerOff } from "react-icons/fa";
// import {FaChartBar} from "react-icons/fa";
import { FiDribbble, FiSettings, FiUser, FiTrendingUp } from "react-icons/fi";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineCasino } from "react-icons/md";
import { TbTable } from "react-icons/tb";
// import { TfiWallet } from "react-icons/tfi";
import { BsAward } from "react-icons/bs";
import { MenuItemWithIcon } from "./MenuItemWithIcon";
import LoginModal from "../../Modals/Login/Login";
import { OptionSidebarExtend } from "./Subcomponents/OptionSidebarExtend";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/reducers/AuthReducer";
import { useNavigate } from "react-router-dom";

/**
 *
 * @name SidebarExtend
 * @description The sidebar component
 * @param {Object} props The props for the Sidebar component
 * @param {Function} props.onClose The onClose function
 * @param {Boolean} props.isConnected The isConnected boolean
 * @returns {JSX.Element} The JSX Code for the Sidebar component
 * @version 1.0.0
 *
 */
export const SidebarExtend = ({
	onClose,
	onOpenWallet,
	isConnected,
	setOnSidebarExtend,
	onOpenProfileModal,
	setUrlSportbook,
	setFilter,
	urlSportbook,
	urlUserXp,
	setUrlUserXp,
	...rest
}) => {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onClickFilterCasinoGame = (filter) => {
		navigate("/casino-games");
		setFilter(filter);
	};

	const handleLogout = () => {
		dispatch(logout()).then(() => {
			onClose();
			window.location.reload();
		});
	};

	return (
		<Box color={"#969998"} pos="relative" h="full" {...rest}>
			<Stack
				height={"calc(100dvh - 100px)"}
				width={"200px"}
				spacing={0}
				top={"80px"}
				pos="fixed"
				bgColor={"#302D2B"}
				borderRadius={urlSportbook || urlUserXp ? "10px 0px 10px 10px" : "10px"}
			>
				<Stack display={"flex"} mt={2} padding={"10px"}>
					<OptionSidebarExtend title={"Hide"} icono={<CiLogout />} onOpen={() => setOnSidebarExtend(false)} />
					<Divider my={2} mx={"auto"} width={"100%"} />
					<OptionSidebarExtend
						title={"Earn XP"}
						icono={<BsAward />}
						url={"/earn-xp"}
						setUrlSportbook={setUrlSportbook}
						setUrlUserXp={setUrlUserXp}
					/>
					<Divider my={2} mx={"auto"} width={"100%"} />
					<Accordion allowToggle border={"0px solid transparent"}>
						<AccordionItem>
							<HStack w={"100%"} justify={"space-between"}>
								<OptionSidebarExtend
									title={"Gaming"}
									icono={<IoGameControllerOutline />}
									url={"/video-games"}
									setUrlSportbook={setUrlSportbook}
									setUrlUserXp={setUrlUserXp}
								/>
								<Spacer />
								<AccordionButton p={"5px 0"} w={"10%"}>
									<AccordionIcon />
								</AccordionButton>
							</HStack>
							<AccordionPanel>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => navigate("/chess")}
								>
									Chess
								</Text>
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<HStack w={"100%"} justify={"space-between"}>
								<OptionSidebarExtend
									title={"Casino"}
									icono={<MdOutlineCasino />}
									url={"/casino-games"}
									setUrlSportbook={setUrlSportbook}
									setUrlUserXp={setUrlUserXp}
									setFilter={setFilter}
								/>
								<Spacer />
								<AccordionButton p={"5px 0"} w={"10%"}>
									<AccordionIcon />
								</AccordionButton>
							</HStack>
							<AccordionPanel>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => onClickFilterCasinoGame("video-slots")}
								>
									Slots
								</Text>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => onClickFilterCasinoGame("live-casino-table")}
								>
									Live casino
								</Text>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => onClickFilterCasinoGame("table-games")}
								>
									Table games
								</Text>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => onClickFilterCasinoGame("crash-games")}
								>
									Crash games
								</Text>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => onClickFilterCasinoGame("scratch-cards")}
								>
									Scrath Cards
								</Text>
								<Text
									cursor={"pointer"}
									_hover={{ color: "secundary" }}
									onClick={() => onClickFilterCasinoGame("virtual-games")}
								>
									Virtual games
								</Text>
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<HStack w={"100%"} justify={"space-between"}>
								<OptionSidebarExtend
									title={"Sports"}
									icono={<FiDribbble />}
									url={"/sport-games"}
									setUrlSportbook={setUrlSportbook}
									setUrlUserXp={setUrlUserXp}
								/>
							</HStack>
						</AccordionItem>
						<AccordionItem>
							<HStack w={"100%"} justify={"space-between"}>
								<OptionSidebarExtend
									title={"Trading"}
									icono={<FiTrendingUp />}
									setUrlSportbook={setUrlSportbook}
									setUrlUserXp={setUrlUserXp}
									isDisabled={true}
								/>
								<Spacer />
								<AccordionButton p={"5px 0"} w={"10%"}>
									<AccordionIcon />
								</AccordionButton>
							</HStack>
							<AccordionPanel>
								<Text cursor={"not-allowed"}>Futures</Text>
								<Text cursor={"not-allowed"}>Exchange</Text>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</Stack>

				<Spacer />
				{isConnected && (
					<>
						<Stack display={"flex"} justifyContent={"between"} padding={"10px"}>
							<OptionSidebarExtend
								title={"Dashboard"}
								icono={<TbTable />}
								url={"/profile"}
								setUrlSportbook={setUrlSportbook}
								setUrlUserXp={setUrlUserXp}
							/>

							{/* <OptionSidebarExtend
								title={"Wallet"}
								icono={<TfiWallet />}
								onOpen={onOpenWallet}
								setUrlSportbook={setUrlSportbook}
							/>
							<OptionSidebarExtend
								title={"Stats"}
								icono={<FaChartBar />}
								url={"/profile"}
								setUrlSportbook={setUrlSportbook}
							/> */}
							<OptionSidebarExtend title={"Account"} icono={<FiSettings />} onOpen={onOpenProfileModal} />
						</Stack>

						<Divider my={2} mx={"auto"} width={"80%"} />

						<Stack display={"flex"} flexDirection={"row"} alignItems={"center"} padding={"10px"}>
							<Avatar name="BitBet" src="/images/profile_image.png" />
							<Stack mx={2} fontSize={"xs"} spacing={0}>
								<Text>{user.username}</Text>
								<Text>{`@${user.public_name}`}</Text>
							</Stack>
							<Icon
								as={FaPowerOff}
								cursor={"pointer"}
								transition={"1s"}
								_hover={{
									color: "secundary",
								}}
								onClick={handleLogout}
							/>
						</Stack>
					</>
				)}
				{!isConnected && (
					<MenuItemWithIcon icon={<FiUser />} Modal={LoginModal}>
						Login / Register
					</MenuItemWithIcon>
				)}
			</Stack>
		</Box>
	);
};
