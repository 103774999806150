import {
    Button,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { verifyChessNickname } from "../../../../services/games/chess";
import { useDispatch } from "react-redux";
import { getMe } from "../../../../redux/reducers/AuthReducer"
import { errorToast, okToast } from "../../../../utils/toast";

/**
 * 
 * @name VerifyAccountChess
 * @description The verify account modal component
 * @param {Object} props The props for the component
 * @param {Boolean} props.isOpen The state of the modal
 * @param {Function} props.onClose The function to close the modal
 * @returns {JSX.Element} The JSX Code for the VerifyAccount component
 * @version 1.0.0
 * 
 */
const VerifyAccountChess = ({ isOpen, onClose }) => {
    
    const toast = useToast();
    const [nickname, setNickname] = useState("");

    const dispatch = useDispatch();

    const handleVerifyNickname = async () => {
        try {
            const response = await verifyChessNickname(nickname, "1");
            if (response) {
                okToast(toast, "Nickname verified successfully!", "You can now create challenges");
                dispatch(getMe());
                onClose();
            } else {
                errorToast(toast, "Nickname verification failed", "Please try again");
            }
        } catch (error) {
            errorToast(toast, "Nickname verification failed", `${error} - Please try again`);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
            <ModalOverlay bgColor={"blackAlpha.800"} />
            <ModalContent bgColor={"#2F2D2D"} color="white">
                <ModalHeader>Verify Chess.com user</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={4} fontSize={"xs"}>
                        To create a challenge you need to verify your Chess.com user.
                    </Text>
                    <Text my={4}>Start a game on Chess.com and make this movements:</Text>
                    <Image src="/images/games/chess/moves.jpeg" mb={4} />

                    <Input
                        variant="bitbet"
                        placeholder="Enter your Chess.com user"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                    />
                    <Button my={4} onClick={handleVerifyNickname} w="100%" variant="outlineOrange">
                        Verify
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default VerifyAccountChess;
