import {
	Button,
	Center,
	Collapse,
	Flex,
	Image,
	Input,
	NumberInput,
	NumberInputField,
	Text,
	useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useDispatch} from "react-redux";
import { validate as isValidBitcoinAddress, getAddressInfo } from "bitcoin-address-validation";
import { isAddress as isValidEthereumAddress } from "web3-validator";
// import { withdraw } from "../../../../services/users";
// import { okToast } from "../../../../utils/toast";
import { errorToast } from "../../../../utils/toast";
// import { getMe } from "../../../../redux/reducers/AuthReducer";
import { getCurrenciesDetails } from "../../../../services/exchange/getCurrenciesDetails";

const Withdraw = ({ selectedCurrency, fees, setSelectedCurrency }) => {
	const { user } = useSelector((state) => state.auth);
	// const dispatch = useDispatch();
	const balance = user?.balances?.balance || 0;

	const toast = useToast();

	const [chain, setChain] = useState("");
	const [wallet, setWallet] = useState("");
	const [amount, setAmount] = useState(parseFloat(0));
	const [isValidWallet, setIsValidWallet] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [minWdUsd, setMinWdUsd] = useState("");

	useEffect(() => {
		getCurrenciesDetails()
			.then((response) => {
				setCurrencies(response);
			})
			.catch((error) => {});
	}, []);

	useEffect(() => {
		const checkWallet = (address) => {
			switch (chain) {
				case "BTC":
					if (address.length < 26 || address.length > 35) return false;
					const isValid = isValidBitcoinAddress(address);
					const type = getAddressInfo(address).type;
					return isValid && type === "p2wpkh";
				case "ETH":
					if (address.length !== 42) return false;
					return isValidEthereumAddress(address);
				case "BSC / BEP20":
					if (address.length !== 42) return false;
					return isValidEthereumAddress(address);
				default:
					return false;
			}
		};

		setIsValidWallet(checkWallet(wallet));
	}, [wallet, chain]);

	useEffect(() => {
		switch (selectedCurrency) {
			case "btc":
				setMinWdUsd(currencies.BTC);
				setChain("BTC");
				break;
			case "eth":
				setMinWdUsd(currencies.ETH);
				setChain("ETH");
				break;
			case "bnb":
				setMinWdUsd(currencies.BNB);
				setChain("BSC / BEP20");
				break;
			case "usdt":
				setChain("BSC / BEP20");
				break;
			default:
				setChain("");
		}
	}, [selectedCurrency, currencies]);

	const handleWithdraw = async () => {
		errorToast(toast, "Withdrawal function currently disabled, sorry for the inconvenience.");
		/* try {
			const response = await withdraw({
				amount,
				wallet,
				currency: selectedCurrency,
			});
			okToast(toast, response.message);
			dispatch(getMe());
			setSelectedCurrency("");
		} catch (error) {
			errorToast(toast, error);
		} */
	};

	return (
		<Collapse in={selectedCurrency !== ""}>
			<Text my={2} color={"white"} fontSize={"sm"}>
				Minimum withdrawal
			</Text>
			<Input isDisabled variant={"bitbet"} isReadOnly value={`$ ${minWdUsd}`} />

			<Flex mt={4}>
				<Center>
					<Image src={`/images/currencies/${selectedCurrency}.png`} w={4} h={4} mr={2} />
				</Center>
				<Text my={2} color={"white"} fontSize={"sm"}>
					Address {chain}
				</Text>
			</Flex>
			<Input
				variant={"bitbet"}
				value={wallet}
				onChange={(e) => setWallet(e.target.value)}
				isInvalid={!isValidWallet}
			/>
			<Collapse in={!isValidWallet && wallet.length > 0}>
				<Text color={"red.500"}>Invalid wallet address</Text>
			</Collapse>

			<Text my={2} color={"white"} fontSize={"sm"} mt={4}>
				Quantity
			</Text>
			<Flex bgColor={"#413D53"} rounded="md" w="100%">
				<NumberInput
					variant={"bitbet"}
					w="100%"
					value={`$ ${amount}`}
					onChange={(e) => setAmount(e)}
					isInvalid={amount > balance}
					max={balance}
					precision={2}
				>
					<NumberInputField />
				</NumberInput>
				<Button
					bgColor={"#292731"}
					color={"white"}
					_hover={{ color: "gray" }}
					onClick={() => setAmount(balance)}
				>
					MAX
				</Button>
			</Flex>

			<Button
				my={4}
				w="100%"
				loadingText="Submitting"
				_hover={{
					color: "white",
				}}
				size="lg"
				bg={"#FF7A00"}
				color={"white"}
				onClick={handleWithdraw}
				// isDisabled={!isValidWallet || balance <= 0}
				isDisabled
			>
				WITHDRAW NOW
			</Button>

			<Collapse in={balance <= 0}>
				<Text my={2} color={"white"} textAlign={"center"}>
					You don't have enough balance to withdraw
				</Text>
			</Collapse>
		</Collapse>
	);
};

export default Withdraw;
