import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react";

import {
	FiDribbble,
	FiInfo,
	FiLayers,
	FiLogOut,
	FiMessageSquare,
	FiSettings,
	FiShoppingBag,
	FiShoppingCart,
} from "react-icons/fi";

import WalletModal from "../../Modals/WalletModal/WalletModal";
import UserProfileModal from "../../Modals/UserProfileEdit/UserProfileEdit";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/reducers/AuthReducer";
import { useNavigate } from "react-router-dom";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineCasino } from "react-icons/md";

/**
 *
 * @name BodyProfileMobile
 * @description Mobile user profile buttons
 * @returns {JSX.Element} The JSX Code for the component
 * @version 1.0.0
 *
 */
export const BodyProfileMobile = ({ onClose }) => {
	const { isOpen: isOpenLogin, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure();
	const { isOpen: isOpenProfileModal, onOpen: onOpenProfileModal, onClose: onCloseProfileModal } = useDisclosure();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		dispatch(logout()).then(() => {
			onClose();
			window.location.reload();
		});
	};
	return (
		<>
			<Stack
				cursor={"pointer"}
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				gap={3}
				onClick={() => {
					navigate("/video-games");
					onClose();
				}}
			>
				<IoGameControllerOutline style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Gaming
				</Text>
			</Stack>
			<Stack
				cursor={"pointer"}
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				gap={3}
				onClick={() => {
					navigate("/casino-games");
					onClose();
				}}
			>
				<MdOutlineCasino style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Casino
				</Text>
			</Stack>
			<Stack
				cursor={"pointer"}
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				gap={3}
				onClick={() => {
					navigate("/sport-games");
					onClose();
				}}
			>
				<FiDribbble style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Sports
				</Text>
			</Stack>
			<Stack
				cursor={"pointer"}
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				gap={3}
				onClick={() => {
					navigate("/profile");
					onClose();
				}}
			>
				<FiLayers style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Profile
				</Text>
			</Stack>
			<Stack
				cursor={"pointer"}
				onClick={onOpenLogin}
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				gap={3}
			>
				<FiShoppingBag style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Wallet
				</Text>
				<WalletModal isOpen={isOpenLogin} onClose={onCloseLogin} />
			</Stack>
			<Stack cursor={"pointer"} display={"flex"} alignItems={"center"} flexDirection={"row"} gap={3}>
				<FiMessageSquare style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Notifications
				</Text>
			</Stack>
			<Stack
				cursor={"pointer"}
				onClick={onOpenProfileModal}
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				gap={3}
			>
				<FiSettings style={{ width: "25px", height: "25px" }} color="primary" />
				<Text fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Account
				</Text>
				<UserProfileModal isOpen={isOpenProfileModal} onClose={onCloseProfileModal} />
			</Stack>
			<Stack cursor={"pointer"} display={"flex"} alignItems={"center"} flexDirection={"row"} gap={3}>
				<FiLogOut style={{ width: "25px", height: "25px" }} color="primary" />
				<Text onClick={handleLogout} fontSize={"xl"} color={"primary"} _hover={{ color: "secundary" }}>
					Log Out
				</Text>
			</Stack>
			<Stack display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} gap={10}>
				<Button leftIcon={<FiShoppingCart />} variant={"solidGreen"} mt={10} width={"80%"}>
					BUY CRYPTO
				</Button>
				<FiInfo style={{ width: "25px", height: "25px" }} color="primary" />
			</Stack>
		</>
	);
};
