import axios from "axios";

/**
 * 
 * @name verifyChessNickname
 * @description Verifies a user's Chess.com nickname and saves it to the database
 * @param {string} nickname The user's Chess.com nickname
 * @returns {boolean} Whether the nickname was successfully verified and saved
 * 
 */
export const verifyChessNickname = async (nickname, gameId) => {
    try {
        const response = await axios.post(`/chess/verify/${nickname}/${gameId}`);
        return response.data;
    } catch (error) {
        throw error.response.data.message;
    }
}

